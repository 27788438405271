import React, { useContext, useEffect, useState } from 'react'
import NavAtHome from '../../Components/Navigators/NavAtHome'
import styles from '../Membership/AddNewMember.module.css'
import { TbDiscount2 } from 'react-icons/tb'
import { BiArrowBack, BiRupee } from 'react-icons/bi'
import { FaRupeeSign } from 'react-icons/fa'
import EachCardDescriptionBuy from './EachCardDescriptionBuy'
import AddNewClient from '../../Components/Users/AddNewClient'
import { BASE_URL_API } from '../../References/URLs'
import AuthContext from '../../store/auth-context'
import { ShimmerThumbnail } from 'react-shimmer-effects'
import ActiveStoreContext from '../../store/ActiveStoreContext'

const AddNewMember = () => {
    const [selectedCard, setSelectedCard] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const authCtx = useContext(AuthContext);
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();

    const [cardDetails, setCardDetails] = useState([]);

    const getAllCards = async () => {
        setIsLoading(true)
        const getAllCardsResponse = await fetch(BASE_URL_API + "getAllCards",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore?.storeIdentifier
                })
            });

        if (!getAllCardsResponse.ok) {
            console.log("Something went wrong : Server Error!");
        } else {
            const getAllCardsRespo = await getAllCardsResponse.json();
            if (getAllCardsRespo.status === "success") {
                setCardDetails(getAllCardsRespo.response);
            } else {
                if (getAllCardsRespo.message === "tokenExpired") {
                    authCtx.logout();
                } else {
                    setCardDetails([]);
                }
            }
            console.log(getAllCardsRespo)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        getAllCards()
    }, [])


    const selectCardHandler = (k) => {
        setSelectedCard(k)
    }
    return (
        <div className={styles.mainWrapper}>
            {/* <NavAtHome /> */}
            <div className={styles.mainContainer}>
                <div className={styles.headerWrapper}>
                    Add a new member
                </div>
                <div className={styles.bodyWrapper}>
                    {selectedCard === null ?
                        <>
                            {isLoading === true ?
                                <div className={styles.cardWrapper}>
                                    <div className={styles.eachCardMainWrapper}>
                                        <div className={styles.eachCardWrapper}>
                                            <ShimmerThumbnail rounded />
                                        </div>
                                    </div>
                                    <div className={styles.eachCardMainWrapper}>
                                        <div className={styles.eachCardWrapper}>
                                            <ShimmerThumbnail rounded />
                                        </div>
                                    </div>

                                    <div className={styles.eachCardMainWrapper}>
                                        <div className={styles.eachCardWrapper}>
                                            <ShimmerThumbnail rounded />
                                        </div>
                                    </div>

                                    <div className={styles.eachCardMainWrapper}>
                                        <div className={styles.eachCardWrapper}>
                                            <ShimmerThumbnail rounded />
                                        </div>
                                    </div>

                                    <div className={styles.eachCardMainWrapper}>
                                        <div className={styles.eachCardWrapper}>
                                            <ShimmerThumbnail rounded />
                                        </div>
                                    </div>

                                    <div className={styles.eachCardMainWrapper}>
                                        <div className={styles.eachCardWrapper}>
                                            <ShimmerThumbnail rounded />
                                        </div>
                                    </div>

                                    <div className={styles.eachCardMainWrapper}>
                                        <div className={styles.eachCardWrapper}>
                                            <ShimmerThumbnail rounded />
                                        </div>
                                    </div>

                                    <div className={styles.eachCardMainWrapper}>
                                        <div className={styles.eachCardWrapper}>
                                            <ShimmerThumbnail rounded />
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className={styles.cardWrapper}>
                                    {cardDetails.map((item, index) => (
                                        <div className={styles.eachCardMainWrapper} onClick={() => selectCardHandler(item)}
                                            key={index}
                                        >
                                            <div
                                                className={styles.eachCardWrapper}
                                                style={{
                                                    backgroundImage: `url(${item.coverImage ? item.coverImage : 'https://sm.lokaci.com/assets/gift-card-knot.png'})`,
                                                    color: item.textColor,
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: 'center',
                                                }}
                                            >
                                                <div className={styles.cardTitleWrapper} style={{ color: item.textColor }}>
                                                    <div className={styles.pleaseEnter}>
                                                        {/* Add any additional content inside */}
                                                    </div>
                                                    <div className={styles.cardName} style={{ color: item.textColor }}>
                                                        {item.cardLabel}
                                                    </div>
                                                    <div className={styles.cardDescription}>
                                                        {item?.cardDescription}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    ))}
                                </div>
                            }

                        </>
                        :
                        <div className={styles.cardDetailsBigWrapper}>
                            <div className={styles.cdbwHeader}>
                                <div className={styles.backBtnWrapper} onClick={() => setSelectedCard(null)} >
                                    <BiArrowBack size={24} color="white" />
                                </div>
                                <div className={styles.allCardsWrapper}>
                                    {cardDetails.map((item, index) => (
                                        <div className={selectedCard === item ? styles.eachSmallCardTitleSelected : styles.eachSmallCardTitle} key={"SMALL" + index}
                                            onClick={() => selectCardHandler(item)}
                                        >
                                            {item.label}
                                        </div>
                                    ))}
                                </div>

                            </div>
                            <EachCardDescriptionBuy
                                selectedCard={selectedCard}
                            />
                        </div>
                    }
                </div>

            </div>
        </div>
    )
}

export default AddNewMember