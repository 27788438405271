import React from 'react';
import styles from './ComHome.module.css';
import { motion } from 'framer-motion';
import { FaBullhorn, FaChartBar, FaUserFriends } from 'react-icons/fa';
import { Link } from 'react-router-dom'

const ComHome = () => {
    return (
        <motion.div
            className={styles.container}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
        >
            <header className={styles.header}>
                <motion.h1 
                    initial={{ y: -50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.5 }}
                >
                    Welcome to Communications Hub
                </motion.h1>
                <motion.p
                    initial={{ y: -30, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.6, delay: 0.2 }}
                >
                    Engage your audience effectively with tailored WhatsApp and SMS campaigns.
                    Launch campaigns in minutes and track performance effortlessly.
                </motion.p>
            </header>
            <motion.div
                className={styles.buttonsContainer}
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.4 }}
            >
                <Link to={`/communications/create-campaign/a`} className={styles.buttonPrimary} >
                    Create New Campaign
                </Link>
                <Link to={`/communications/all-campaigns`} className={styles.buttonSecondary}>
                    View Past Campaigns
                </Link>
            </motion.div>
            <div className={styles.featuresSection}>
                <motion.div
                    className={styles.feature}
                    whileHover={{ scale: 1.05 }}
                >
                    <FaBullhorn className={styles.featureIcon} />
                    <h3>Personalized Messaging</h3>
                    <p>Customize messages with placeholders to make every message feel unique.</p>
                </motion.div>
                <motion.div
                    className={styles.feature}
                    whileHover={{ scale: 1.05 }}
                >
                    <FaUserFriends className={styles.featureIcon} />
                    <h3>Audience Segmentation</h3>
                    <p>Target specific groups based on demographics, preferences, and more.</p>
                </motion.div>
                <motion.div
                    className={styles.feature}
                    whileHover={{ scale: 1.05 }}
                >
                    <FaChartBar className={styles.featureIcon} />
                    <h3>Detailed Analytics</h3>
                    <p>Track campaign performance with real-time delivery and engagement data.</p>
                </motion.div>
            </div>
            <motion.div
                className={styles.motivationSection}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8 }}
            >
                <h2>Why Launch Your Campaign Today?</h2>
                <p>
                    Unlock the potential of instant communication. Boost customer engagement and drive conversions with the power of WhatsApp and SMS.
                </p>
                <Link to={`/communications/create-new-campaign`} className={styles.buttonPrimary} >
                    Get Started Now
                </Link>
            </motion.div>
        </motion.div>
    );
};

export default ComHome;
