import React, { useState } from 'react'
import { FaCalendar, FaChevronDown } from 'react-icons/fa'
import DateRangeSelector from '../UI/DateRangeSelector'
import styles from './StaffManagementMainHeader.module.css'
import StaffSelectorInManageStaff from './StaffSelectorInManageStaff'

const StaffManagementMainHeader = (props) => {
    const { workers, dates, changeDateHandler } = props
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)
    const closeModal = () => { setIsDatePickerOpen(false) }
    
    return (
        <div className={styles.mainHeader}>
            <DateRangeSelector
                isOpen={isDatePickerOpen}
                closeModal={closeModal}
                changeDateHandler={changeDateHandler}
            />
            <div className={styles.headerCaption}>
                <div>
                    <StaffSelectorInManageStaff workers={workers} />
                </div>
            </div>
            <div className={styles.actionHeader}>
                <div className={styles.dateHeader}>
                    <div className={styles.dateBtn}>
                        <button onClick={() => setIsDatePickerOpen(true)}>
                            From {dates?.startDate} to {dates?.endDate} &nbsp;
                            <FaCalendar size={14} />
                        </button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default StaffManagementMainHeader