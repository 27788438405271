import React, { useContext, useEffect, useState } from 'react'
import ChooseClient from '../../Components/Sessions/ChooseClient'
import AddNewClient from '../../Components/Users/AddNewClient'
import { BASE_URL_API } from '../../References/URLs'
import ActiveStoreContext from '../../store/ActiveStoreContext'
import AuthContext from '../../store/auth-context'
import { IoCloseCircleOutline, IoPersonAdd } from 'react-icons/io5'; // React Icons for buttons
import { motion } from 'framer-motion'; // Framer Motion for animations
import styles from './NewFutureBooking.module.css'
import StaffSearchableDropDownInline from './StaffSearchableDropDownInline'

const NewFutureBooking = (props) => {
    const { isOpen, onClose, onSuccess, slotInfo, workers } = props
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();
    const [isCreatingEvent, setIsCreatingEvent] = useState(false)
    const [title, setTitle] = useState('')
    const [start, setStart] = useState('')
    const [servicesNotes, setServicesNotes] = useState('')
    const [remarks, setRemarks] = useState('')
    const [selectedWorker, setSelectedWorker] = useState(null)

    const [chooseClientOpen, setChooseClientOpen] = useState(false)
    const [isUserFormOpen, setIsUserFormOpen] = useState(false)
    const [userDetailsFetched, setUserDetailsFetched] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState({
        otp: ''
    })
    const [clientStoreRelationIdentifier, setClientStoreRelationIdentifier] = useState('')
    const [selectedUser, setSelectedUser] = useState(null)

    const userFormToggle = (k) => setIsUserFormOpen(k)
    const closeModal = () => setIsUserFormOpen(false)
    const abortClientSelection = () => setChooseClientOpen(false)
    const assignClient = (k) => {
        selectUserForBookingHandler(k)
        setClientStoreRelationIdentifier(k)
        abortClientSelection()
    }

    const selectUserForBookingHandler = async (k) => {
        setSelectedUser(k)
        console.log("printing k array : ", k)
    }

    window.onclick = event => event.target.id === "ipshidSuper" ? onClose() : null
    document.addEventListener('keydown', function (event) {
        if (event.key === 'Escape') {
            onClose()
        }
    });

    // const [timeSlot, setTimeSlot] = useState(() => {
    //     if (!slotInfo?.start) return "";

    //     const localStart = new Date(slotInfo.start);
    //     // Convert to the format 'YYYY-MM-DDTHH:MM' expected by datetime-local input
    //     localStart.setDate(localStart.getDate() + 1);

    //     return localStart.toISOString().slice(0, 16);
    // });

    const [timeSlot, setTimeSlot] = useState(() => {
        if (!slotInfo?.start) return "";
    
        const localStart = new Date(slotInfo.start);
    
        // Convert to local time string expected by datetime-local input
        const offset = localStart.getTimezoneOffset();
        localStart.setMinutes(localStart.getMinutes() - offset);
    
        return localStart.toISOString().slice(0, 16); // 'YYYY-MM-DDTHH:MM'
    });

    

    const makeReservations = async () => {
        setIsCreatingEvent(true)
        const makeReservationsResponse = await fetch(BASE_URL_API + "makeReservations",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx?.token,
                    storeIdentifier: activeStore?.storeIdentifier,
                    clientStoreRelationIdentifier: selectedUser?.clientStoreRelationIdentifier,
                    clientName: selectedUser?.client_name,
                    title: title,
                    start: timeSlot,
                    servicesNotes: servicesNotes,
                    remarks: remarks,
                    worker_id : selectedWorker?.id,
                    worker_name : selectedWorker?.name
                })
            });

        if (!makeReservationsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const makeReservationsRespo = await makeReservationsResponse.json()
            if (makeReservationsRespo.status === "success") {
                onSuccess()
            } else {
                if (makeReservationsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                }
            }
            console.log(makeReservationsRespo)
        }
        setIsCreatingEvent(false)
    }


    const selectWorkerHandler = (k) => {
        setSelectedWorker(k)
    }

    useEffect(() => {
        console.log("Workers List:", workers);
        console.log("Slot Info:", slotInfo);

        if (
            slotInfo &&
            slotInfo.resourceId &&
            typeof slotInfo.resourceId === "string" &&
            Array.isArray(workers) &&
            workers.length > 0
        ) {
            const matchedWorker = workers.find(worker => worker.id === slotInfo.resourceId) || null;

            console.log("Matched Worker:", matchedWorker);
            setSelectedWorker(matchedWorker);
        } else {
            setSelectedWorker(null);
        }
    }, [slotInfo, workers]);




    if (!isOpen) {
        return null
    } else {
        return (
            <motion.div
                className={styles.mainWrapper}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
            >
                <AddNewClient
                    isOpen={isUserFormOpen}
                    closeModal={closeModal}
                    phone={phoneNumber.otp}
                    assignClient={assignClient}
                />
                <ChooseClient
                    isOpen={chooseClientOpen}
                    abortClientSelection={abortClientSelection}
                    assignClient={assignClient}
                    userFormToggle={userFormToggle}
                />
                <div className={styles.mainContainer} id="ipshidSuper">
                    <motion.div
                        className={styles.mainContent}
                        initial={{ y: "-100vh" }}
                        animate={{ y: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        <div className={styles.headerContent}>
                            <h3>Make a Reservation</h3>
                        </div>
                        <div className={styles.bodyContent}>
                            <div className={styles.fieldWrapper}>
                                <motion.div
                                    className={styles.eachInputWrapper}
                                    whileHover={{ scale: 1.01 }}
                                    whileTap={{ scale: 0.98 }}
                                >
                                    {selectedUser !== null ?
                                        <div onClick={() => setChooseClientOpen(true)}
                                            className={styles.selectedUser}
                                            title="Click to change"
                                        >
                                            <div className={styles.clientName}>
                                                {selectedUser?.client_name}
                                            </div>
                                            <div className={styles.clientMobile}>
                                                {selectedUser?.mobile}
                                            </div>
                                        </div>
                                        :
                                        <input
                                            placeholder="Enter client phone number..."
                                            onFocus={() => setChooseClientOpen(true)}
                                        />
                                    }
                                </motion.div>
                                <motion.div
                                    className={styles.eachInputWrapper}
                                    whileHover={{ scale: 1.01 }}
                                    whileTap={{ scale: 0.98 }}
                                >
                                    <textarea
                                        placeholder="Add services"
                                        value={servicesNotes}
                                        onChange={(e) => setServicesNotes(e.target.value)}
                                        autoFocus={true}
                                    />
                                </motion.div>
                                <div>
                                    {/* {workers?.map((item, index) => (
                                        <div key={index}>
                                            {item?.name}
                                        </div>
                                    ))} */}
                                    <StaffSearchableDropDownInline workers={workers} selectedWorker={selectedWorker} selectWorkerHandler={selectWorkerHandler} />
                                </div>

                                <motion.div
                                    className={styles.eachInputWrapper}
                                    whileHover={{ scale: 1.01 }}
                                    whileTap={{ scale: 0.98 }}
                                >
                                    <textarea
                                        placeholder="Add remarks.."
                                        value={remarks}
                                        onChange={(e) => setRemarks(e.target.value)}
                                    />
                                </motion.div>
                                <motion.div
                                    className={styles.eachInputWrapper}
                                    whileHover={{ scale: 1.01 }}
                                    whileTap={{ scale: 0.98 }}
                                >
                                    <input
                                        type="datetime-local"
                                        value={timeSlot}
                                        onChange={(e) => setTimeSlot(e.target.value)}
                                    />
                                </motion.div>
                            </div>
                        </div>
                        <div className={styles.footerContent}>
                            {isCreatingEvent ? (
                                <motion.button
                                    disabled
                                    whileHover={{ scale: 1.1 }}
                                    className={styles.creatingButton}
                                >
                                    <IoPersonAdd className={styles.icon} /> Creating Now...
                                </motion.button>
                            ) : (
                                <motion.button
                                    onClick={() => makeReservations()}
                                    whileHover={{ scale: 1.1 }}
                                >
                                    Create Now
                                </motion.button>
                            )}
                            <motion.div
                                onClick={() => onClose()}
                                className={styles.closeBtn}
                                whileHover={{ scale: 1.1 }}
                            >
                                <IoCloseCircleOutline className={styles.icon} size={20} />
                                Close
                            </motion.div>
                        </div>
                    </motion.div>
                </div>
            </motion.div>
        )
    }
}

export default NewFutureBooking
