import React, { useContext, useEffect, useState, useRef } from "react";
import { BASE_URL_API } from "../../../References/URLs";
import ActiveStoreContext from "../../../store/ActiveStoreContext";
import AuthContext from "../../../store/auth-context";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { MdCardMembership, MdClose, MdDelete } from "react-icons/md";
import { FaChevronLeft, FaChevronRight, FaMinus, FaPlus, FaRegCreditCard, FaSpinner } from "react-icons/fa";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import styles from "./GIInterfaceAlpha.module.css";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import GIAlphaTabWrapper from "../../../Components/Invoices/GenerateInvoice/GIAlpha/GIAlphaTabWrapper";
import ClientSearchDropdown from "../../../Components/Invoices/GenerateInvoice/GIAlpha/ClientSearchDropdown";
import { motion } from "framer-motion";
import { v4 as uuidv4 } from "uuid"; // Use UUID to generate unique keys
import ProductSearch from "../../../Components/Invoices/GenerateInvoice/GIAlpha/ProductSearch";
import { statesInIndia } from "../../../Helper/statesInIndia";
import StaffSearch from "../../../Components/Invoices/GenerateInvoice/GIAlpha/StaffSearch";
import CollectionUpdater from "../../../Components/Invoices/GenerateInvoice/GIAlpha/CollectionUpdater";
import { timeLeftInFuture } from "../../../Helper/calculations";

const GIInterfaceAlpha = () => {
    const { sessionIdentifier } = useParams();
    const authCtx = useContext(AuthContext);
    const asCtx = useContext(ActiveStoreContext);
    const activeStore = (() => { try { return asCtx?.activeStore && asCtx.activeStore.trim() !== "" ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();
    let navigate = useNavigate()
    const [currentSessions, setCurrentSessions] = useState([]);
    const [clientGstState, setClientGstState] = useState('Delhi')
    const [gstSlabs, setGstSlabs] = useState([
        0, 0.1, 0.25, 0.5, 1, 1.5, 3, 5, 6, 7.5, 12, 18, 28
    ])
    const inputRefs = useRef([]);

    const [isSavingInvoice, setIsSavingInvoice] = useState(false)
    const [staffData, setStaffData] = useState([])
    const [allProducts, setAllProducts] = useState([]);
    const [clientMembershipData, setClientMembershipData] = useState([])
    const [isMembershipVisible, setIsMembershipVisible] = useState(false)
    const [invoiceDataMaster, setInvoiceDataMaster] = useState({
        sessionIdentifier: sessionIdentifier,
        invoiceNumber: '',
        invoiceDate: new Date().toISOString().split('T')[0],
        dueDate: new Date().toISOString().split('T')[0],
        clientData: {
            clientStoreRelationIdentifier: '',
            clientName: '',
            client_name: '',
            mobile: '',
            gender: '',
            dateOfBirth: '',
            billingAddress: '',
        },
        productData: [
            {
                productIdentifier: uuidv4(), ///
                identifier: "",
                qty: 1,
                price: 0,
                total: 0,
                consultedBy: [],
                servedBy: []
            }
        ],
        discountType: 'percentage',
        tncData: [
            {

            }
        ],
        customFields: [
            {}
        ],
        shipmentPlaceOfSupply: 'Delhi',
        collectionData: [
            { mode: 'collectionViaQR', amount: 0 },
            { mode: 'collectionViaCash', amount: 0 },
            { mode: 'collectionViaCard', amount: 0 }
        ]

    })




    const sectionVariants = {
        hidden: { opacity: 0, y: 50 }, // Start off-screen and transparent
        visible: { opacity: 1, y: 0 }, // Final state (fully visible and in-place)
    };

    const handleProductSelection = (id) => (product) => {
        handleSelectProduct(id, product);
        // Find the last product in the list
        const lastProduct = invoiceDataMaster?.productData?.slice(-1)[0];

        // Check if the selected product is the last one
        if (lastProduct?.productIdentifier === id) {
            addProductRow(); // Add a new row only when the last product is selected
        }
    };

    const handleSelectProduct = (id, product) => {
        console.log(id, product)
        // Validate input parameters
        if (!id || typeof id !== "string") {
            console.error("Invalid or missing 'id' parameter");
            return;
        }
        if (!product || typeof product !== "object") {
            console.error("Invalid or missing 'product' parameter");
            return;
        }

        setInvoiceDataMaster((prevData) => {
            if (!prevData || !Array.isArray(prevData.productData)) {
                console.error("Invalid 'productData' in state");
                return prevData; // Return unchanged state if it's invalid
            }

            const updatedProductData = prevData.productData.map((item) => {
                // Only update the matching item, retain existing fields if values are undefined
                if (item.productIdentifier === id) {
                    return {
                        ...item,
                        identifier: product.identifier ?? item.identifier,
                        productTitle: product.serviceName ?? item.serviceName,
                        productDescription: product.productDescription ?? item.productDescription,
                        pointsRequired: product.pointsRequired ?? item.pointsRequired,
                        hsn: product.hsn ?? item.hsn,
                        price: product.price ?? item.price,
                        total: Number(product.price) || Number(item?.price) || 0,
                        stock: product.stock ?? item.stock,
                        brandName: product.brandName ?? item.brandName,
                        category: product.category ?? item.category,
                        subCategory: product.subCategory ?? item.subCategory,
                        weight: product.weight ?? item.weight,
                        height: product.height ?? item.height,
                        width: product.width ?? item.width,
                        depth: product.depth ?? item.depth,
                        warranty: product.warranty ?? item.warranty,
                        guarantee: product.guarantee ?? item.guarantee,
                        coveredInWarranty: product.coveredInWarranty ?? item.coveredInWarranty,
                        notCoveredInWarranty: product.notCoveredInWarranty ?? item.notCoveredInWarranty,
                        images: product.images ?? item.images,
                    };
                }
                return item; // Return unchanged item if no match
            });

            return {
                ...prevData,
                productData: updatedProductData,
            };
        });
    };

    const removeProductRow = (id) => {
        setInvoiceDataMaster((prevState) => ({
            ...prevState,
            productData: prevState.productData.filter((row) => row.productIdentifier !== id),
        }));
    };

    const addProductRow = () => {
        setInvoiceDataMaster((prevState) => ({
            ...prevState,
            productData: [
                ...prevState.productData,
                { productIdentifier: uuidv4(), identifier: "", qty: 1, price: 0, total: 0, consultedBy: [], servedBy: [] },
            ],
        }));
    };

    const getProductItemsTotal = (key) => {
        // Validate the input parameters
        if (!key || typeof key !== "string") {
            console.error("Invalid key parameter. Please provide a valid string key.");
            return 0;
        }
        if (!Array.isArray(invoiceDataMaster?.productData)) {
            console.error("Invalid data parameter. Please provide an array of objects.");
            return 0;
        }

        // Calculate the total
        return invoiceDataMaster?.productData.reduce((total, item) => {
            const value = Number(item[key]);
            if (typeof value === "number" && !isNaN(value)) {
                return total + value;
            }
            return total; // Ignore non-numeric or missing values
        }, 0);
    }

    const onSelectStaff = (staff, productIdentifier, type) => {
        console.log(staff)
        setInvoiceDataMaster((prevState) => {
            const updatedProductData = prevState.productData.map((product) => {
                if (product.productIdentifier === productIdentifier) {
                    const updatedField = type === "consultedBy" ? product.consultedBy : product.servedBy;

                    // Check if staff already exists in the respective array
                    const isExisting = updatedField.some(
                        (item) => item.id === staff.id
                    );

                    // Add new staff only if not already present
                    const updatedStaff = isExisting
                        ? updatedField
                        : [
                            ...updatedField,
                            {
                                id: staff.id,
                                staffName: staff.name,
                                contribution: 0,
                            },
                        ];

                    return {
                        ...product,
                        [type]: updatedStaff, // Update the consultedBy or servedBy field
                    };
                }
                return product;
            });

            return {
                ...prevState,
                productData: updatedProductData,
            };
        });
    };

    useEffect(() => {
        updateInvoiceFields('sessionIdentifier', sessionIdentifier)
    }, [sessionIdentifier])

    useEffect(() => {
        if (invoiceDataMaster?.productData?.length < 1) {
            addProductRow()
        }
    }, [invoiceDataMaster?.productData])


    const updateInvoiceFields = (field, value) => {
        setInvoiceDataMaster((prevState) => {
            // Update the main field (e.g., discountType)
            const updatedState = {
                ...prevState,
                [field]: value,
            };

            if (field === 'isShippingAddressSame') {
                updateSameShipmentAddress()
            }

            // Trigger recalculation for all productData
            if (field === 'discountType') {
                updatedState.productData = prevState.productData.map((product) => {
                    const baseTotal = product.qty * product.price;

                    // Recalculate discount amount based on the new discountType
                    let discountAmount = 0;
                    if (value === 'percentage') {
                        discountAmount = (baseTotal * (product.discount || 0)) / 100;
                    } else if (value === 'value') {
                        discountAmount = product.discount || 0;
                    }

                    // Recalculate total after applying discount
                    let total = baseTotal - discountAmount;

                    // Recalculate GST if applicable
                    let gstValue = 0;
                    if (product.gst) {
                        gstValue = (total * product.gst) / 100;
                        total += gstValue;
                    }

                    return {
                        ...product,
                        total,
                        gstValue,
                    };
                });

                // Update totalAmount after recalculations
                updatedState.totalAmount = updatedState.productData.reduce(
                    (acc, product) => acc + (product.total || 0),
                    0
                );
            }

            return updatedState;
        });
    };

    const updateSameShipmentAddress = () => {
        setInvoiceDataMaster((prev) => ({
            ...prev,
            shipmentAddressData: {
                ...prev.shipmentAddressData,
                shipTo: invoiceDataMaster?.clientData?.firmName,
                shipmentName: invoiceDataMaster?.clientData?.clientName,
                shipmentAddress: invoiceDataMaster?.clientData?.address,
                shipmentPhone: invoiceDataMaster?.clientData?.phoneNumber,
                shipmentGST: invoiceDataMaster?.clientData?.gst
            }
        }))
    }

    const updateClientData = (field, value) => {
        setInvoiceDataMaster((prev) => ({
            ...prev,
            clientData: {
                ...prev.clientData,
                [field]: value
            }
        }))
    }

    const updateProductRow = (id, field, value) => {
        setInvoiceDataMaster((prevState) => {
            const updatedProducts = prevState.productData.map((product) => {
                if (product.productIdentifier === id) {
                    // Update the specific field
                    const updatedProduct = {
                        ...product,
                        [field]: value,
                    };

                    // Safely handle numeric fields with default values
                    const qty = Number(updatedProduct?.qty) || 0;
                    const price = Number(updatedProduct?.price) || 0;
                    const discount = Number(updatedProduct?.discount) || 0;
                    const gst = Number(updatedProduct?.gst) || 0;

                    // Calculate the base total
                    const baseTotal = qty * price;

                    // Handle discount based on discountType
                    let discountAmount = 0;
                    const discountType = prevState.discountType || 'value'; // Default to 'value'
                    if (discountType === 'percentage') {
                        discountAmount = (baseTotal * discount) / 100;
                    } else if (discountType === 'value') {
                        discountAmount = discount;
                    }

                    // Ensure discount does not exceed base total
                    discountAmount = Math.min(discountAmount, baseTotal);

                    // Update total after applying discount
                    const subtotal = baseTotal - discountAmount;

                    // Calculate GST if applicable
                    let gstAmount = 0;
                    if (gst > 0) {
                        gstAmount = (subtotal * gst) / 100;
                    }

                    // Update the product with calculated values
                    updatedProduct.total = subtotal + gstAmount;
                    updatedProduct.gstValue = gstAmount;

                    return updatedProduct;
                }
                return product; // Keep other products unchanged
            });

            // Calculate the total amount for all products
            const totalAmount = updatedProducts.reduce((acc, product) => acc + (product.total || 0), 0);

            return {
                ...prevState,
                productData: updatedProducts,
                totalAmount,
            };
        });
    };

    const getPriceList = async () => {
        // console.log("Getting price list")
        const getPriceListResponse = await fetch(BASE_URL_API + "getPriceList",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore?.storeIdentifier
                })
            });

        if (!getPriceListResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getPriceListRespo = await getPriceListResponse.json();
            if (getPriceListRespo.status === 'success') {
                // console.log(getPriceListRespo?.response);
                setAllProducts(extractServices(getPriceListRespo?.response))
            } else {
                if (getPriceListRespo.message === 'tokenExpired') {
                    authCtx.logout();
                } else {

                    console.log(getPriceListRespo);
                }
            }

        }
    }

    useEffect(() => {
        if (activeStore !== null) {
            getPriceList()
        }
    }, [activeStore?.storeIdentifier])

    const getWorkersData = async () => {
        const getWorkersDataResponse = await fetch(BASE_URL_API + "getWorkersData",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore?.storeIdentifier
                })
            });

        if (!getWorkersDataResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getWorkersDataRespo = await getWorkersDataResponse.json();
            if (getWorkersDataRespo.status === 'success') {
                setStaffData(getWorkersDataRespo.response);
            } else {
                if (getWorkersDataRespo.message === 'tokenExpired') {
                    authCtx.logout()
                } else {
                    console.log(getWorkersDataRespo)
                }
            }
        }
    }

    useEffect(() => {
        if (sessionIdentifier || typeof sessionIdentifier === 'string' || sessionIdentifier?.trim() !== '') {
            getWorkersData()
        }
    }, [])

    const extractServices = (data) => {
        if (!Array.isArray(data)) return []; // Ensure the input is an array

        return data.flatMap(item => {
            // Handle cases where services is missing, null, or not an array
            if (!item.services || !Array.isArray(item.services)) {
                return [];
            }

            // Map services if they exist and are valid
            return item.services.map(service => ({
                identifier: service.identifier || "",
                serviceName: service.serviceName || "",
                categoryIdentifier: service.categoryIdentifier || "",
                categoryName: service.categoryName || "",
                genderIdentifier: service.genderIdentifier || "",
                genderLabel: service.genderLabel || "",
                price: service.basePrice || "0", // Default price to "0"
                serviceTime: service.serviceTime || "0", // Default service time to "0"
                serviceDuration: service.serviceDuration || null,
                serviceDurationUnit: service.serviceDurationUnit || "",
                isOffer: service.isOffer || "0", // Default to "0" (no offer)
                offerPercentage: service.offerPercentage || "",
                description: service.description || "",
                hsnCode: service.hsnCode || "",
                batchNo: service.batchNo || "",
                addOns: Array.isArray(service.addOns) ? service.addOns : null, // Ensure addOns is valid
            }));
        });
    };

    const updateTncRow = (id, field, value) => {
        setInvoiceDataMaster((prevState) => {
            const updatedTncData = prevState.tncData.map((item) =>
                item.id === id ? { ...item, [field]: value } : item
            );

            return {
                ...prevState,
                tncData: updatedTncData,
            };
        });
    };

    const addNewTncRow = () => {
        setInvoiceDataMaster((prevState) => {
            const newTncRow = {
                id: crypto.randomUUID(), // Generate a unique ID
                title: "",
                detail: "",
            };

            return {
                ...prevState,
                tncData: [...prevState.tncData, newTncRow],
            };
        });
    };

    const deleteTncRow = (id) => {
        setInvoiceDataMaster((prevState) => {
            const updatedTncData = prevState.tncData.filter((item) => item.id !== id);

            return {
                ...prevState,
                tncData: updatedTncData,
            };
        });
    };

    ///// final calculations.. 
    const calculateTotalTaxable = () => {
        return invoiceDataMaster?.productData?.reduce((acc, product) => {
            const price = Number(product?.price) || 0; // Ensure price is a number
            const qty = Number(product?.qty) || 1; // Ensure quantity is a number
            const discount = Number(product?.discount) || 0; // Ensure discount is a number
            const discountType = invoiceDataMaster?.discountType || 'value'; // Default to 'value'

            let finalDiscount = 0;

            // Calculate discount based on type
            if (discountType === "percentage") {
                // Calculate percentage discount per unit
                finalDiscount = (price * discount) / 100;
            } else if (discountType === "value") {
                // Fixed discount per unit (not multiplied by quantity)
                finalDiscount = discount;
            } else {
                console.log('error in discount type')
            }

            // Calculate the price after discount for one unit
            const priceAfterDiscount = price - finalDiscount;

            // Ensure priceAfterDiscount is not negative (could be 0 or positive)
            const taxablePrice = priceAfterDiscount * qty;

            // Accumulate the total taxable price
            return acc + Math.max(0, taxablePrice);
        }, 0);
    };

    const calculateTotalTax = () => {
        if (!invoiceDataMaster?.productData?.length) return 0;

        return invoiceDataMaster.productData.reduce((acc, product) => {
            const price = product?.price || 0;
            const qty = product?.qty || 1;
            const discount = product?.discount || 0;
            const discountType = invoiceDataMaster?.discountType || 'value';
            const gstRate = product?.gst || 0;

            // Calculate discounted price based on type
            let discountedPrice = price;
            if (discountType === 'percentage') {
                discountedPrice = price - ((price * discount) / 100);
            } else {
                discountedPrice = price - discount;
            }

            // Ensure discounted price is not negative
            discountedPrice = Math.max(0, discountedPrice);

            // Calculate total taxable amount for this product
            const taxablePrice = discountedPrice * qty;

            // Calculate tax for the product
            const tax = (parseFloat(gstRate) / 100) * taxablePrice;

            return acc + tax;
        }, 0);
    };

    const addCustomField = () => {
        setInvoiceDataMaster((prevState) => ({
            ...prevState,
            customFields: [
                ...prevState.customFields,
                {
                    id: crypto.randomUUID(), // Generate unique ID
                    fieldTitle: "",
                    calculationType: "value", // Default to value
                    calculationLogic: "add", // Default to add
                    value: 0,
                },
            ],
        }));
    };

    const removeCustomField = (id) => {
        setInvoiceDataMaster((prevState) => ({
            ...prevState,
            customFields: prevState.customFields.filter((field) => field.id !== id),
        }));
    };

    const updateCustomField = (id, field, value) => {
        setInvoiceDataMaster((prevState) => ({
            ...prevState,
            customFields: prevState.customFields.map((fieldItem) =>
                fieldItem.id === id ? { ...fieldItem, [field]: value } : fieldItem
            ),
        }));
    };

    const toggleCalculationType = (id) => {
        setInvoiceDataMaster((prevState) => ({
            ...prevState,
            customFields: prevState.customFields.map((field) =>
                field.id === id
                    ? { ...field, calculationType: field.calculationType === "value" ? "percentage" : "value" }
                    : field
            ),
        }));
    };

    const toggleCalculationLogic = (id) => {
        setInvoiceDataMaster((prevState) => ({
            ...prevState,
            customFields: prevState.customFields.map((field) =>
                field.id === id
                    ? { ...field, calculationLogic: field.calculationLogic === "add" ? "subtract" : "add" }
                    : field
            ),
        }));
    };

    const roundOffHandler = () => {
        setInvoiceDataMaster((prevState) => {
            // Safely handle grandTotal
            const grandTotal = Number(prevState.grandTotal) || 0;

            // Calculate the round-off value
            const roundOffValue = Math.round(grandTotal) - grandTotal;

            return {
                ...prevState,
                isRoundingOff: !prevState.isRoundingOff,
                roundOffValue: !prevState.isRoundingOff ? roundOffValue : 0, // Toggle round-off value
            };
        });
    };

    const calculateGrandTotal = () => {
        let grandTotal = calculateTotalTaxable() + calculateTotalTax();

        invoiceDataMaster?.customFields?.forEach((field) => {
            const fieldValue =
                field.calculationType === "percentage"
                    ? (grandTotal * (field.value || 0)) / 100
                    : field.value || 0;
            grandTotal += field.calculationLogic === "add" ? fieldValue : -fieldValue;
        });

        if (invoiceDataMaster?.isRoundingOff) {
            grandTotal = Math.round(grandTotal);
        }

        updateInvoiceFields('grandTotal', grandTotal)
        return grandTotal;
    };

    const convertNumberToText = (amount) => {
        if (isNaN(amount) || amount < 0) return "Invalid amount";

        const units = [
            "", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"
        ];
        const teens = [
            "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen",
            "Seventeen", "Eighteen", "Nineteen"
        ];
        const tens = [
            "", "Ten", "Twenty", "Thirty", "Forty", "Fifty",
            "Sixty", "Seventy", "Eighty", "Ninety"
        ];
        const thousands = [
            "", "Thousand", "Lakh", "Crore"
        ];

        // Split rupees and paise
        const [rupeesPart, paisePart] = amount
            .toFixed(2) // Ensure two decimal places
            .split(".") // Split into rupees and paise
            .map(Number); // Convert strings to numbers

        const convertChunk = (num) => {
            let result = "";

            if (num > 99) {
                result += `${units[Math.floor(num / 100)]} Hundred `;
                num %= 100;
            }

            if (num > 10 && num < 20) {
                result += `${teens[num - 11]} `;
                num = 0;
            } else if (num >= 10) {
                result += `${tens[Math.floor(num / 10)]} `;
                num %= 10;
            }

            if (num > 0) {
                result += `${units[num]} `;
            }

            return result.trim();
        };

        const convertRupees = (num) => {
            let result = "";
            let level = 0;

            while (num > 0) {
                const chunk = num % 1000;

                if (chunk > 0) {
                    result = `${convertChunk(chunk)} ${thousands[level]} ${result}`.trim();
                }

                num = Math.floor(num / 1000);
                level++;
            }

            return result.trim();
        };

        const rupeesText = convertRupees(rupeesPart) || "Zero";
        const paiseText = convertChunk(paisePart);

        if (paisePart > 0) {
            return `${rupeesText} Rupees and ${paiseText} Paise Only`.trim();
        }

        return `${rupeesText} Rupees Only`.trim();
    };

    const updatePaymentMode = (selectedMode) => {
        // Update the paymentMode field in invoiceDataMaster
        setInvoiceDataMaster((prev) => ({
            ...prev,
            paymentMode: selectedMode,
        }));
    };

    const saveInvoiceHandler = async () => {
        setIsSavingInvoice(true)
        console.log(invoiceDataMaster)
        const updateInvoiceDataResponse = await fetch(BASE_URL_API + "updateInvoiceData",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore?.storeIdentifier,
                    invoiceDataMaster: JSON.stringify(invoiceDataMaster)
                })
            });

        if (!updateInvoiceDataResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const updateInvoiceDataRespo = await updateInvoiceDataResponse.json()
            if (updateInvoiceDataRespo.status === "success") {

            } else {
                if (updateInvoiceDataRespo?.message === "tokenExpired") {
                    authCtx.logout()
                }
            }
            console.log(updateInvoiceDataRespo)
        }
        setIsSavingInvoice(false)
    }

    const saveAndPreviewInvoiceHandler = async () => {
        setIsSavingInvoice(true)
        console.log(invoiceDataMaster)
        const updateInvoiceDataResponse = await fetch(BASE_URL_API + "updateInvoiceData",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore?.storeIdentifier,
                    invoiceDataMaster: JSON.stringify(invoiceDataMaster)
                })
            });

        if (!updateInvoiceDataResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const updateInvoiceDataRespo = await updateInvoiceDataResponse.json()
            if (updateInvoiceDataRespo.status === "success") {
                navigate(`/invoice/preview/${sessionIdentifier}`)
            } else {
                if (updateInvoiceDataRespo?.message === "tokenExpired") {
                    authCtx.logout()
                }
            }
            console.log(updateInvoiceDataRespo)
        }
        setIsSavingInvoice(false)
    }

    const getInvoiceData = async () => {
        console.log(invoiceDataMaster)
        const getInvoiceDataResponse = await fetch(BASE_URL_API + "getInvoiceData",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    sessionIdentifier: sessionIdentifier
                })
            });

        if (!getInvoiceDataResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getInvoiceDataRespo = await getInvoiceDataResponse.json()
            if (getInvoiceDataRespo.status === "success") {
                setInvoiceDataMaster(getInvoiceDataRespo?.response)
            } else {
                if (getInvoiceDataRespo?.message === "tokenExpired") {
                    authCtx.logout()
                }
            }
        }
    }

    useEffect(() => {
        const fetchDataIfNeeded = () => {
            const isSessionValid = sessionIdentifier &&
                typeof sessionIdentifier === 'string' &&
                sessionIdentifier?.trim() !== '';
            if (!isSessionValid) {
                console.log('Fetching data as sessionIdentifier is invalid or not set.');
            } else {
                console.log(`Session identifier is valid: ${sessionIdentifier}`);
                getInvoiceData();
            }
        };

        fetchDataIfNeeded();
    }, [sessionIdentifier]);



    const handleContributionChange = (productIdentifier, staffIdentifier, value, type) => {
        const updatedContribution = Math.max(0, Math.min(100, parseInt(value, 10) || 0)); // Ensure value is within 0–100

        setInvoiceDataMaster((prevState) => {
            // Find the product to update
            const updatedProductData = prevState.productData.map((product) => {
                if (product.productIdentifier !== productIdentifier) {
                    return product; // Return unchanged product
                }

                const targetList = type === "consultedBy" ? product.consultedBy : product.servedBy;

                // Calculate current total contribution excluding the current staff
                const currentTotalContribution = targetList.reduce((sum, staff) =>
                    staff.id === staffIdentifier ? sum : sum + staff.contribution, 0);

                // Ensure the new contribution does not exceed 100 when added to current total
                const maxAllowedContribution = Math.max(0, 100 - currentTotalContribution);
                const finalContribution = Math.min(updatedContribution, maxAllowedContribution);

                // Update the target list with validated contribution
                const updatedTargetList = targetList.map((staff) =>
                    staff.id === staffIdentifier
                        ? { ...staff, contribution: finalContribution }
                        : staff
                );

                return {
                    ...product,
                    [type]: updatedTargetList, // Update the specific type for the matched product
                };
            });

            return {
                ...prevState,
                productData: updatedProductData,
            };
        });
    };

    const deleteStaffContribution = (productIdentifier, staffIdentifier, type) => {
        setInvoiceDataMaster((prevState) => {
            // Find the product to update
            const updatedProductData = prevState.productData.map((product) => {
                if (product.productIdentifier !== productIdentifier) {
                    return product; // Return unchanged product if it's not the target
                }

                const targetList = type === "consultedBy" ? product.consultedBy : product.servedBy;

                // Filter out the staff with the matching staffIdentifier
                const updatedTargetList = targetList.filter((staff) => staff.id !== staffIdentifier);

                return {
                    ...product,
                    [type]: updatedTargetList, // Update the specific type with the filtered list
                };
            });

            return {
                ...prevState,
                productData: updatedProductData,
            };
        });
    };


    const updateCollectionDataHandler = (field, value) => {
        setInvoiceDataMaster((prev) => ({
            ...prev,
            clientData: {
                ...prev.clientData,
                [field]: value
            }
        }))
    }

    const calculatePayments = () => {
        try {
            // Ensure collectionData and grandTotal exist and are valid
            console.log(`let's calculate payments `)
            if (!Array.isArray(invoiceDataMaster.collectionData)) {
                console.error("collectionData is not an array. Defaulting to an empty array.");
                invoiceDataMaster.collectionData = [];
            }

            if (typeof invoiceDataMaster.grandTotal !== "number" || isNaN(invoiceDataMaster.grandTotal)) {
                console.log("grandTotal is not a valid number. Defaulting to 0.");
                invoiceDataMaster.grandTotal = 0;
            }

            // Calculate the sum of all modes of payments
            const totalPayments = invoiceDataMaster.collectionData.reduce((sum, collection) => {
                // Validate each collection entry
                if (
                    typeof collection !== "object" ||
                    !collection.hasOwnProperty("amount") ||
                    typeof collection.amount !== "number" ||
                    isNaN(collection.amount)
                ) {
                    console.warn("Invalid collection entry detected. Skipping:", collection);
                    return sum;
                }
                return sum + Math.max(collection.amount, 0); // Ensure non-negative amounts
            }, 0);

            // Calculate the remaining payment
            // const remainingAmount = Math.max(invoiceDataMaster.grandTotal - totalPayments, 0);

            // Calculate the remaining payment
            const remainingAmount = invoiceDataMaster.grandTotal - totalPayments;
            // console.log("this is remaining amount calculation : ", invoiceDataMaster?.grandTotal, totalPayments)
            // console.log("this is remaining amount : ", remainingAmount)

            // Update invoiceDataMaster with the new values
            setInvoiceDataMaster((prevState) => ({
                ...prevState,
                remainingAmount,
            }));

            // console.log(totalPayments, remainingAmount) 

            return {
                totalPayments,
                remainingAmount,
            };

        } catch (error) {
            console.error("Error calculating payments:", error);
            return {
                totalPayments: 0,
                remainingAmount: invoiceDataMaster.grandTotal || 0,
            };
        }
    };

    useEffect(() => {
        if (sessionIdentifier !== undefined) {
            console.log("need to update payment")
            calculateGrandTotal()
            calculatePayments()
        }
    }, [invoiceDataMaster?.collectionData, invoiceDataMaster?.productData, invoiceDataMaster?.customFields, invoiceDataMaster.roundOffValue])

    useEffect(() => {
        if (sessionIdentifier !== undefined) {
            calculatePayments()
        }
    }, [invoiceDataMaster?.grandTotal])


    // console.log(invoiceDataMaster)


    useEffect(() => {
        const adjustHeights = () => {
            const rows = document.querySelectorAll(`.${styles.productRow}`);
            rows.forEach(row => {
                const children = Array.from(row.children);
                let maxHeight = 0;

                // Reset heights to auto to recalculate correctly
                children.forEach(child => {
                    child.style.height = 'auto';
                });

                // Calculate the tallest height
                children.forEach(child => {
                    maxHeight = Math.max(maxHeight, child.offsetHeight);
                });

                // Apply the tallest height to all children
                children.forEach(child => {
                    child.style.height = `${maxHeight}px`;
                });
            });
        };

        // Adjust heights on load and window resize
        adjustHeights();
        window.addEventListener('resize', adjustHeights);

        return () => {
            window.removeEventListener('resize', adjustHeights);
        };
    }, [invoiceDataMaster]);

    // useEffect(() => {
    //     saveInvoiceHandler()
    // }, [invoiceDataMaster?.clientData?.clientStoreRelationIdentifier])

    const prevIdentifierRef = useRef(null);
    useEffect(() => {

        if (
            invoiceDataMaster?.clientData?.clientStoreRelationIdentifier &&
            invoiceDataMaster?.clientData?.clientStoreRelationIdentifier !== prevIdentifierRef.current
        ) {
            console.log(
                "Identifier changed:",
                prevIdentifierRef.current,
                "->",
                invoiceDataMaster?.clientData?.clientStoreRelationIdentifier
            );
            saveInvoiceHandler();
        } else {
            console.log("Identifier not changed");
        }
        getClientsMembershipData()
        prevIdentifierRef.current = invoiceDataMaster?.clientData?.clientStoreRelationIdentifier;
    }, [invoiceDataMaster?.clientData?.clientStoreRelationIdentifier]);


    const getClientsMembershipData = async () => {
        if (sessionIdentifier !== undefined) {
            if (invoiceDataMaster?.clientData?.clientStoreRelationIdentifier !== undefined) {
                const getClientsMembershipDataResponse = await fetch(BASE_URL_API + "getClientsMembershipData",
                    {
                        method: "POST",
                        body: JSON.stringify({
                            token: authCtx.token,
                            storeIdentifier: activeStore.storeIdentifier,
                            clientStoreRelationIdentifier: invoiceDataMaster?.clientData?.clientStoreRelationIdentifier
                        })
                    });

                if (!getClientsMembershipDataResponse.ok) {
                    console.log(`Something went wrong : Server Error`)
                } else {
                    const getClientsMembershipDataRespo = await getClientsMembershipDataResponse.json()
                    if (getClientsMembershipDataRespo.status === "success") {
                        setClientMembershipData(getClientsMembershipDataRespo?.response)
                    } else {
                        if (getClientsMembershipDataRespo?.message === "tokenExpired") {
                            authCtx.logout()
                        } else {
                            setClientMembershipData([])
                        }
                    }
                    console.log(getClientsMembershipDataRespo)
                }
            }
        }
    }

    // useEffect(() => {
    //     if(invoiceDataMaster?.productData?.length>0) {
    //         console.log(invoiceDataMaster?.productData?.length)
    //         // const inputs = document.querySelectorAll(`.${styles.searchInput}`);
    //         const inputs = document.querySelectorAll('.searchable-input')

    //         if (inputs.length > 0) {
    //             inputs[inputs.length - 1].focus();
    //         }

    //         console.log('Triggering for focus:', inputs?.length);
    //     } else {
    //         console.log('Nothing is found!')
    //     }
    // }, [invoiceDataMaster?.productData?.length])



    if (activeStore === null) {
        return (
            <div>
                Please select a store first.
            </div>
        )
    }

    if (!sessionIdentifier || typeof sessionIdentifier !== 'string' || sessionIdentifier?.trim() === '') {
        return (
            <div className={styles.container}>
                <GIAlphaTabWrapper invoiceDataMaster={invoiceDataMaster} />
                <div className={styles.bodyWrapper}>
                    <div className={styles.firstRowWrapper}>
                        <div className={styles.rowContainer}>
                            Please select a booking First!
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    return (
        <div className={styles.container}>
            <GIAlphaTabWrapper invoiceDataMaster={invoiceDataMaster} />
            <div className={styles.bodyWrapper}>
                <div className={styles.firstRowWrapper}>
                    <div className={styles.rowContainer}>
                        <div className={styles.frLeftWrapper}>
                            <div className={styles.frLeftContainer}>
                                <div className={styles.frleftUpper}>
                                    <div className={styles.inputFieldWrapper} >
                                        <div className={styles.fieldTitleWrapper}>
                                            <div className={styles.fieldTitleTxt}>
                                                Customer Name
                                            </div>
                                        </div>
                                        <div className={styles.fieldBodyWrapper}>
                                            <ClientSearchDropdown
                                                invoiceDataMaster={invoiceDataMaster}
                                                setInvoiceDataMaster={setInvoiceDataMaster}
                                            />
                                        </div>
                                    </div>
                                    <div className={`${styles.inputFieldWrapper} ${styles.small}`} >
                                        <div className={styles.fieldTitleWrapper}>
                                            <div className={styles.fieldTitleTxt}>
                                                Phone
                                            </div>
                                        </div>
                                        <div className={styles.fieldBodyWrapper}>
                                            <input value={invoiceDataMaster?.clientData?.mobile} />
                                        </div>
                                    </div>
                                </div>

                                {/* <div>
                                    <div className={`${styles.inputFieldWrapper} ${styles.textarea}`} >
                                        <div className={styles.fieldTitleWrapper}>
                                            <div className={styles.fieldTitleTxt}>
                                                Billing Address
                                            </div>
                                        </div>
                                        <div className={styles.fieldBodyWrapper}>
                                            <textarea onChange={(e) => updateClientData('billingAddress', e.target.value)}
                                                value={invoiceDataMaster?.clientData?.billingAddress}
                                            >

                                            </textarea>
                                        </div>
                                    </div>
                                </div> */}

                                {isMembershipVisible === true ?
                                    <motion.div
                                        className={styles.membershipWrapper}
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 0.5 }}
                                    >
                                        {clientMembershipData?.map((item, index) => (
                                            <motion.div
                                                key={index}
                                                className={`${styles.card}`}
                                                initial={{ scale: 0.8, opacity: 0 }}
                                                animate={{ scale: 1, opacity: 1 }}
                                                transition={{ delay: index * 0.2, duration: 0.5 }}
                                            >
                                                <div className={styles.cardHeader}>
                                                    <MdCardMembership className={styles.cardIcon} />
                                                    <span className={styles.cardIdentifier}>{item?.cardIdentifier}</span>
                                                </div>
                                                <div className={styles.cardDetails}>
                                                    <div>
                                                        <FaRegCreditCard className={styles.icon} />
                                                        <strong>Issued Card Number:</strong> {item?.issuedCardNumber}
                                                    </div>
                                                    <div>
                                                        <strong>Amount Paid:</strong> ₹{item?.amountPaid}
                                                    </div>
                                                    <div>
                                                        <strong>Issued On:</strong>{" "}
                                                        {new Date(item?.issuedOn).toLocaleDateString()}
                                                    </div>
                                                    <div>
                                                        <strong>Expiry Date:</strong>{" "}
                                                        {new Date(item?.expiryDate).toLocaleDateString()}
                                                    </div>
                                                </div>
                                            </motion.div>
                                        ))}
                                    </motion.div>
                                    :
                                    <div className={styles.membershipWrapperSummary} >
                                        <div className={styles.mwsTitle}>
                                            {clientMembershipData?.length} Membership found(s)
                                        </div>
                                        <div className={styles.mswData}>
                                            <table >
                                                <thead>
                                                    <tr>
                                                        <th>Card Title</th>
                                                        <th>Amount Paid</th>
                                                        <th>Issued On</th>
                                                        <th>Expiring In</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {clientMembershipData?.map((item, index) => (
                                                        <tr key={item?.ID || index}>
                                                            <td>{item?.cardIdentifier || "N/A"}</td>
                                                            <td>₹{item?.amountPaid || "0.00"}</td>
                                                            <td>
                                                                {item?.issuedOn ? item?.issuedOn.substring(0, 10) : "Not Available"}
                                                            </td>
                                                            <td>
                                                                {item?.expiryDate
                                                                    ? `${timeLeftInFuture(item?.expiryDate)} days`
                                                                    : "No Expiry"}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                }
                            </div>
                        </div>
                        <div className={styles.frRightWrapper}>
                            <div className={styles.frRightContainer}>
                                <div className={styles.frEachRow}>
                                    <div className={styles.frTitle}>
                                        Invoice #
                                    </div>
                                    <div className={styles.frInput}>
                                        <input readOnly defaultValue={invoiceDataMaster?.invoiceNumber} />
                                    </div>
                                </div>
                                <div className={styles.frEachRow}>
                                    <div className={styles.frTitle}>
                                        Invoice Date
                                    </div>
                                    <div className={styles.frInput}>
                                        <input placeholder="Please enter invoice date"
                                            type="date"
                                            value={invoiceDataMaster?.invoiceDate}
                                            onChange={(e) => updateInvoiceFields('invoiceDate', e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className={styles.frEachRow}>
                                    <div className={styles.frTitle}>
                                        State of Supply
                                    </div>
                                    <div className={styles.frInput}>
                                        <select
                                            onChange={(e) => updateInvoiceFields('shipmentPlaceOfSupply', e.target.value)}
                                            value={invoiceDataMaster?.shipmentPlaceOfSupply}
                                        >
                                            <option>Select State</option>
                                            {statesInIndia?.map((itemState, indexState) => (
                                                <option key={indexState}>{itemState?.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                <div className={styles.secondRowWrapper}>
                    <div className={styles.secondRow}>
                        <motion.div className={styles.productsPanel}
                            initial="hidden"
                            animate="visible"
                            variants={sectionVariants}
                            transition={{ duration: 0.5, delay: 0.4, ease: "easeOut" }}
                        >
                            <div className={styles.productsHeader}>
                                <div className={styles.sn}>#</div>
                                <div className={styles.productItem}>Product / Item / Other Charges</div>
                                <div className={styles.cal}>Consulted By</div>
                                <div className={styles.cal}>Served By</div>
                                <div className={styles.hsnSac}>HSN / SAC</div>
                                <div className={styles.qty}>Qty</div>
                                {/* <div className={styles.uom}>UOM</div> */}
                                <div className={styles.price}>Price(₹)</div>
                                <div className={styles.discount}>Discount
                                    <span onClick={() => updateInvoiceFields('discountType', invoiceDataMaster?.discountType === 'value' ? 'percentage' : 'value')}>
                                        {invoiceDataMaster?.discountType === 'value' ? <div className={styles.discountIcon}>₹</div> : <div className={styles.discountIcon}>%</div>}
                                    </span>
                                </div>
                                <div className={styles.gst}>
                                    {invoiceDataMaster?.shipmentPlaceOfSupply === clientGstState ?
                                        "CGST + SGST"
                                        :
                                        "IGST"
                                    }
                                </div>
                                <div className={styles.total}>Total</div>
                            </div>
                            {invoiceDataMaster?.productData?.map((product, index) => (
                                <motion.div className={styles.productRow} key={index}
                                    initial={{ x: -100, opacity: 0 }} // Initial state (off-screen and transparent)
                                    animate={{ x: 0, opacity: 1 }}   // Final state (in-place and fully visible)
                                    exit={{ x: 100, opacity: 0 }}    // Exit state (slide out and transparent)
                                    transition={{ duration: 0.3, ease: "easeOut" }}
                                >
                                    <div className={styles.sn}>{index + 1}</div>
                                    <div className={styles.productItem}>
                                        <ProductSearch
                                            allRecords={allProducts}
                                            onSelectRecords={handleProductSelection(product?.productIdentifier)}
                                            productTitle={product?.productTitle}
                                            invoiceDataMaster={invoiceDataMaster}
                                        />
                                        {/* <textarea placeholder="Item notes..." >

                                        </textarea> */}
                                    </div>
                                    <div className={styles.cal}>
                                        <StaffSearch
                                            staffList={staffData}
                                            onSelectRecords={(staff) =>
                                                onSelectStaff(staff, product?.productIdentifier, "consultedBy")
                                            }
                                        />
                                        <div className={styles.staffItemWrapper}>
                                            {product?.consultedBy?.map((itemConsultedBy, indexConsultedBy) => (
                                                <motion.div
                                                    key={indexConsultedBy}
                                                    className={styles.staffItem}
                                                    initial={{ opacity: 0, x: -10 }}
                                                    animate={{ opacity: 1, x: 0 }}
                                                    exit={{ opacity: 0, x: 10 }}
                                                >
                                                    <div className={styles.consultedByName} >{itemConsultedBy.staffName || "Select staff"}</div>
                                                    <div className={styles.consultedByValue}>
                                                        <input
                                                            type="number"
                                                            placeholder="Contribution %"
                                                            value={itemConsultedBy.contribution}
                                                            onChange={(e) => handleContributionChange(product?.productIdentifier, itemConsultedBy?.id, e.target.value, "consultedBy")}
                                                            className={styles.contributionInput}
                                                        />
                                                    </div>
                                                    <div className={styles.deleteStaffFromContri}
                                                        onClick={() => deleteStaffContribution(product?.productIdentifier, itemConsultedBy?.id, "consultedBy")}
                                                    >
                                                        <MdDelete size={16} />
                                                    </div>
                                                </motion.div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className={styles.cal}>
                                        <StaffSearch
                                            staffList={staffData}
                                            onSelectRecords={(staff) =>
                                                onSelectStaff(staff, product?.productIdentifier, "servedBy")
                                            }
                                        />
                                        <div className={styles.staffItemWrapper}>
                                            {product?.servedBy?.map((itemServedBy, indexServedBy) => (
                                                <motion.div
                                                    key={indexServedBy}
                                                    className={styles.staffItem}
                                                    initial={{ opacity: 0, x: -10 }}
                                                    animate={{ opacity: 1, x: 0 }}
                                                    exit={{ opacity: 0, x: 10 }}
                                                >
                                                    <div className={styles.consultedByName} >{itemServedBy.staffName || "Select staff"}</div>
                                                    <div className={styles.consultedByValue}>
                                                        <input
                                                            type="number"
                                                            placeholder="Contribution %"
                                                            value={itemServedBy.contribution}
                                                            onChange={(e) => handleContributionChange(product?.productIdentifier, itemServedBy?.id, e.target.value, "servedBy")}
                                                            className={styles.contributionInput}
                                                        />
                                                    </div>
                                                    <div className={styles.deleteStaffFromContri}
                                                        onClick={() => deleteStaffContribution(product?.productIdentifier, itemServedBy?.id, "servedBy")}
                                                    >
                                                        <MdDelete size={16} />
                                                    </div>
                                                </motion.div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className={styles.hsnSac}>
                                        <input
                                            type="number"
                                            value={product.hsn}
                                            onChange={(e) =>
                                                updateProductRow(product?.productIdentifier, 'hsn', parseInt(e.target.value))
                                            }
                                        />
                                    </div>
                                    <div className={styles.qty}>
                                        <input
                                            type="number"
                                            value={product.qty}
                                            onChange={(e) =>
                                                updateProductRow(product?.productIdentifier, 'qty', parseInt(e.target.value))
                                            }
                                        />
                                    </div>
                                    {/* <div className={styles.uom}>
                                        <input
                                            type="number"
                                            value={product.uom}
                                            onChange={(e) =>
                                                updateProductRow(product?.productIdentifier, 'uom', parseFloat(e.target.value))
                                            }
                                        />
                                    </div> */}
                                    <div className={styles.price}>
                                        <input
                                            type="number"
                                            value={product.price}
                                            onChange={(e) =>
                                                updateProductRow(product?.productIdentifier, 'price', parseFloat(e.target.value))
                                            }
                                        />
                                    </div>
                                    <div className={styles.discount}>
                                        <input
                                            type="number"
                                            value={product.discount}
                                            onChange={(e) =>
                                                updateProductRow(product?.productIdentifier, 'discount', parseFloat(e.target.value))
                                            }
                                        />
                                    </div>
                                    <div className={styles.gst}>
                                        <select
                                            value={product.gst}
                                            onChange={(e) =>
                                                updateProductRow(product?.productIdentifier, 'gst', e.target.value)
                                            }
                                        >
                                            {gstSlabs?.map((itemGst, indexGst) => (
                                                <option key={indexGst}
                                                    value={itemGst}
                                                >
                                                    {invoiceDataMaster?.shipmentPlaceOfSupply === clientGstState ?
                                                        `${itemGst / 2} + ${itemGst / 2}`
                                                        :
                                                        `${itemGst}`
                                                    }
                                                </option>
                                            ))}
                                        </select>
                                        <div className={styles.gstValueTxt} >₹{product?.gstValue}</div>
                                        {/* <textarea value={product?.gstValue}
                                            onChange={(e) =>
                                                updateProductRow(product?.productIdentifier, 'gstValue', parseFloat(e.target.value))
                                            }
                                        >
                                        </textarea> */}
                                    </div>

                                    <div className={styles.total}>
                                        {product?.total?.toFixed(2)}
                                    </div>
                                    <div className={styles.deleteRow}>
                                        <button onClick={() => removeProductRow(product?.productIdentifier)} >
                                            <MdDelete size={20} />
                                        </button>
                                    </div>
                                </motion.div>
                            ))}
                            <div className={styles.itemsTotalWrapper}>
                                <div className={styles.totalInvoiceValue}>
                                    Total Invoice Value
                                </div>
                                <div className={styles.hsnSac}></div>
                                <div className={styles.cal}></div>
                                <div className={styles.cal}></div>
                                <div className={styles.qty}>
                                    {/* show here sum of all quantities... */}
                                    {getProductItemsTotal("qty")}
                                </div>
                                <div className={styles.price}>
                                    {/* show here sum of all prices... */}
                                    {getProductItemsTotal("price")?.toFixed(2)}
                                </div>
                                <div className={styles.discount}>
                                    {/* show here sum of all discounts... */}
                                    {getProductItemsTotal("discount")?.toFixed(2)}
                                </div>
                                <div className={styles.gst}>
                                    {/* show here sum of all gstValues... */}
                                    {getProductItemsTotal("gstValue")?.toFixed(2)}
                                </div>

                                <div className={styles.total}>
                                    {/* show here sum of all total... */}
                                    {getProductItemsTotal("total")?.toFixed(2)}
                                </div>
                            </div>
                            <div className={styles.addNewRow}>
                                <button onClick={addProductRow} className={styles.addRowButton}>
                                    Add Product
                                </button>
                            </div>
                        </motion.div>
                    </div>
                </div>
                {/* Billing Information */}
                <div className={styles.thirdRow}>
                    <motion.div className={styles.billingPanel}
                        initial="hidden"
                        animate="visible"
                        variants={sectionVariants}
                        transition={{ duration: 0.5, delay: 0.5, ease: "easeOut" }}
                    >
                        <div className={styles.bpContainer} >
                            <div className={styles.bpLeft}>
                                <div className={styles.eachBpField} >
                                    <div className={styles.twoInARow}>
                                        <div className={styles.eachInputWrapper}>
                                            <div className={styles.inputTitleWrapper}>
                                                <div className={styles.inputTitle}>Due Date</div>
                                            </div>
                                            <div className={styles.inputFieldHolder}>
                                                <input
                                                    type="date"
                                                    value={invoiceDataMaster.dueDate}
                                                    onChange={(e) =>
                                                        updateInvoiceFields("dueDate", e.target.value)
                                                    }
                                                    placeholder="Please enter due date..."
                                                />
                                            </div>
                                        </div>

                                        <div className={styles.eachInputWrapper}>
                                            <div className={styles.inputTitleWrapper}>
                                                <div className={styles.inputTitle}>Bank Details</div>
                                            </div>
                                            <div className={styles.inputFieldHolder}>
                                                <select
                                                    onChange={(e) => updateInvoiceFields("invoiceType", e.target.value)}
                                                    value={invoiceDataMaster?.invoiceType}
                                                >
                                                    <option>Hide Bank Details</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.tncWrapper}>
                                    <div className={styles.tncHeader} >
                                        <div>
                                            Terms & Conditions / Additional Notes
                                        </div>
                                        <div className={styles.addNewBtn} >
                                            <button onClick={addNewTncRow}>Add New</button>
                                        </div>
                                    </div>
                                    <div className={styles.tncBody}>
                                        {invoiceDataMaster?.tncData?.map((itemTnc, indexTnc) => (
                                            <div className={styles.eachTncWrapper} key={indexTnc}>
                                                <div className={styles.eachInputWrapper}>
                                                    <div className={styles.inputTitleWrapper}>
                                                        <div className={styles.inputTitle}>Title</div>
                                                    </div>
                                                    <div className={`${styles.inputFieldWrapper} ${styles.noBorderWidthFull}`}>
                                                        <input
                                                            // autoFocus
                                                            value={itemTnc.title}
                                                            onChange={(e) => updateTncRow(itemTnc.id, "title", e.target.value)}
                                                            placeholder="Please enter title..."
                                                        />
                                                    </div>
                                                </div>
                                                <div className={styles.eachInputWrapper}>
                                                    <div className={styles.inputTitleWrapper}>
                                                        <div className={styles.inputTitle}>Detail</div>
                                                    </div>
                                                    <div className={`${styles.inputFieldWrapper} ${styles.noBorderWidthFull}`}>
                                                        <textarea
                                                            value={itemTnc.detail}
                                                            onChange={(e) => updateTncRow(itemTnc.id, "detail", e.target.value)}
                                                            placeholder="Please enter detail..."
                                                        />
                                                    </div>
                                                </div>
                                                <div className={styles.rowAction}>
                                                    <button onClick={addNewTncRow}><FaPlus size={15} /></button>
                                                    <button onClick={() => deleteTncRow(itemTnc.id)}>
                                                        <MdDelete size={20} />
                                                    </button>
                                                </div>
                                            </div>
                                        ))}

                                    </div>
                                </div>

                                <div className={styles.documentNotesWrapper}>
                                    <div className={styles.eachInputWrapper}>
                                        <div className={styles.inputTitleWrapper}>
                                            <div className={styles.inputTitle}>Document Notes / Remarks</div>
                                        </div>
                                        <div className={`${styles.inputFieldWrapper} ${styles.noBorderWidthFull}`}>
                                            <textarea
                                                value={invoiceDataMaster.documentNotesRemarks}
                                                onChange={(e) => updateInvoiceFields("documentNotesRemarks", e.target.value)}
                                                placeholder="Please enter document notes and remarks..."
                                            >
                                            </textarea>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className={styles.bpRight}>
                                <div className={styles.bpRightContainer}>
                                    {/* Taxable and Tax Rows */}
                                    <div className={styles.rightTwoFields}>
                                        <div className={styles.eachbpRightRow}>
                                            <div>Total Taxable</div>
                                            <div>{calculateTotalTaxable()?.toFixed(2)}</div>
                                        </div>
                                        <div className={styles.eachbpRightRow}>
                                            <div>Total Tax</div>
                                            <div>{calculateTotalTax()?.toFixed(2)}</div>
                                        </div>
                                    </div>

                                    {/* Custom Fields Section */}
                                    <div className={styles.customizedFields}>
                                        {invoiceDataMaster?.customFields?.map((itemCustom, indexCustom) => (
                                            <div className={styles.eachPaymentRow} key={indexCustom}>
                                                <div className={styles.eprLeft}>
                                                    {/* Field Title Input */}
                                                    <div className={styles.customFieldInput}>
                                                        <input
                                                            placeholder="Enter name of field"
                                                            value={itemCustom?.fieldTitle}
                                                            onChange={(e) => updateCustomField(itemCustom.id, "fieldTitle", e.target.value)}
                                                        />
                                                    </div>

                                                    {/* Toggle for Calculation Type (Value/Percentage) */}
                                                    <div className={styles.percentRupeeToggle} onClick={() => toggleCalculationType(itemCustom.id)}>
                                                        <div className={itemCustom?.calculationType === "value" ? styles.activeToggle : styles.defToggle}>₹</div>
                                                        <div className={itemCustom?.calculationType !== "value" ? styles.activeToggle : styles.defToggle}>%</div>
                                                    </div>

                                                    {/* Toggle for Calculation Logic (Add/Subtract) */}
                                                    <div className={styles.percentRupeeToggle} onClick={() => toggleCalculationLogic(itemCustom.id)}>
                                                        <div className={itemCustom?.calculationLogic === "subtract" ? styles.activeToggle : styles.defToggle}><FaMinus size={11} /></div>
                                                        <div className={itemCustom?.calculationLogic !== "subtract" ? styles.activeToggle : styles.defToggle}><FaPlus size={11} /></div>
                                                    </div>

                                                    {/* Remove Field Button */}
                                                    <button onClick={() => removeCustomField(itemCustom.id)} className={styles.deleteIcon} >
                                                        <MdDelete size={20} color="purple" />
                                                    </button>
                                                </div>

                                                <div>
                                                    {/* Custom Value Input */}
                                                    <div className={styles.customFieldValue}>
                                                        <input
                                                            type="number"
                                                            placeholder="Enter value"
                                                            value={itemCustom?.value || ""}
                                                            onChange={(e) => updateCustomField(itemCustom.id, "value", Number(e.target.value))}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                        <div className={styles.addBtnWrapper}>
                                            {/* Add New Field Button */}
                                            <button onClick={addCustomField}>Add New Field</button>
                                        </div>
                                    </div>

                                    {/* Round Off Section */}
                                    <div className={styles.roundOffWrapper}>
                                        <div className={styles.roundOffContainer}>

                                            <div className={styles.roundOffTxt} >
                                                <div className={styles.roundOffTitle} >Round Off</div>
                                                <div className={styles.roundOffToggle} onClick={roundOffHandler}>
                                                    <div className={invoiceDataMaster?.isRoundingOff === true ? styles.activeToggle : styles.defToggle}>Yes</div>
                                                    <div className={invoiceDataMaster?.isRoundingOff === false ? styles.activeToggle : styles.defToggle}>No</div>
                                                </div>
                                            </div>
                                            <div>{invoiceDataMaster?.roundOffValue || 0}</div>
                                        </div>
                                    </div>

                                    {/* Grand Total Section */}
                                    <div className={styles.grandTotalWrapper}>
                                        <div className={styles.grandTotalContainer}>
                                            <div>Grand Total</div>
                                            {/* <div>₹{calculateGrandTotal()?.toFixed(2)}</div> */}
                                            <div>₹{invoiceDataMaster?.grandTotal?.toFixed(2)}</div>
                                        </div>
                                    </div>

                                    {/* Total in words  */}
                                    <div className={styles.totalsInWrapper}>
                                        <div className={styles.totalsInContainer}>
                                            <div className={styles.totalInWordsTitle} >Total In Words</div>
                                            <div className={styles.totalInWrodsValue}>
                                                {convertNumberToText(invoiceDataMaster?.grandTotal)}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.paymentModeWrapper}>
                                        {/* <div className={styles.paymentModeContainer}>
                                            <div>Payment Type</div>
                                            <div className={styles.paymentModes}>
                                                {['Credit', 'Cash', 'Cheque', 'Online'].map((mode) => (
                                                    <div
                                                        key={mode}
                                                        className={
                                                            invoiceDataMaster?.paymentMode === mode
                                                                ? styles.eachPModeActive
                                                                : styles.eachPMode
                                                        }
                                                        onClick={() => updatePaymentMode(mode)}
                                                    >
                                                        {mode}
                                                    </div>
                                                ))}
                                            </div>
                                        </div> */}
                                        <CollectionUpdater invoiceDataMaster={invoiceDataMaster} setInvoiceDataMaster={setInvoiceDataMaster} />

                                        <div className={`${styles.paymentStatus} ${invoiceDataMaster?.remainingAmount < 0
                                            ? styles.advance
                                            : invoiceDataMaster?.remainingAmount === 0
                                                ? styles.neutral
                                                : styles.remaining
                                            }`}
                                        >
                                            <div>
                                                {invoiceDataMaster?.remainingAmount < 0
                                                    ? "Advance Payment"
                                                    : invoiceDataMaster?.remainingAmount === 0
                                                        ? "No Due"
                                                        : "Remaining Payment"}
                                            </div>
                                            <div>
                                                ₹
                                                {Math.abs(invoiceDataMaster?.remainingAmount).toLocaleString("en-IN", {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })}
                                            </div>
                                        </div>



                                    </div>


                                </div>
                            </div>
                        </div>
                        <div className={styles.thatsitRow}>
                            That's it!
                        </div>
                    </motion.div>
                </div>
            </div>
            <div className={styles.actionRowWrapper}>
                {isSavingInvoice === true ?
                    <button className={styles.saveBtn} >
                        <FaSpinner className={styles.spinner} /> Saving
                    </button>
                    :
                    <button onClick={() => saveInvoiceHandler()} className={styles.saveBtn} >
                        Save
                    </button>
                }
                {isSavingInvoice === true ?
                    <button className={styles.saveAndPreviewBtn} >
                        <FaSpinner className={styles.spinner} /> Saving
                    </button>
                    :
                    <button onClick={() => saveAndPreviewInvoiceHandler()} className={styles.saveAndPreviewBtn} >
                        Save & Preview
                    </button>
                }
            </div>
        </div>
    );
};

export default GIInterfaceAlpha;
