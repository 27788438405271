import React, { useState, useCallback, useEffect, useContext } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import { motion } from 'framer-motion';
import { FaFilter, FaDownload, FaTrash } from 'react-icons/fa';
import { CSVLink } from 'react-csv';
import styles from "./StaffAppointmentCalendar.module.css";
import { BASE_URL_API } from '../../References/URLs';
import AuthContext from '../../store/auth-context';
import ActiveStoreContext from '../../store/ActiveStoreContext';
import StoresHeader from '../../Components/Stores/StoresHeader';
import NewFutureBooking from './NewFutureBooking';
import { toast } from 'react-toastify';

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);


const initialAppointments = [
    { id: 1, title: 'Haircut', start: new Date(2025, 2, 7, 10, 0), end: new Date(2025, 2, 7, 11, 0), resourceId: "17371232932C513B74CBAF6FF4", status: 'active' },
    { id: 2, title: 'Facial', start: new Date(2025, 2, 7, 12, 0), end: new Date(2025, 2, 7, 13, 0), resourceId: '2', status: 'upcoming' },
    { id: 3, title: 'Massage', start: new Date(2025, 2, 8, 14, 0), end: new Date(2025, 2, 8, 15, 0), resourceId: '3', status: 'cancelled' },
];

const eventColors = {
    active: '#4CAF50',
    upcoming: '#FFC107',
    cancelled: '#F44336',
};

const StaffAppointmentCalendar = () => {
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();
    const [isLoadingEvent, setIsLoadingEvent] = useState(false)
    const [isNewBookingOpen, setIsNewBookingOpen] = useState(false)
    const staffMembers = [
        { resourceId: '1', title: 'John Doe', color: '#FFDDC1' },
        { resourceId: '2', title: 'Jane Smith', color: '#D1C4E9' },
        { resourceId: '3', title: 'Navneet Chandan', color: '#BBDEFB' },
        { resourceId: '4', title: 'Somya Chandan', color: '#C8E6C9' },
        { resourceId: '5', title: 'Tony Chandan', color: '#C8E6C9' },
    ];

    const [workers, setWorkers] = useState([])
    const [appointments, setAppointments] = useState(initialAppointments);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [filterStatus, setFilterStatus] = useState('all');
    const [selectedStaff, setSelectedStaff] = useState('all');
    const [activeSlotInfo, setActiveSlotInfo] = useState('')
    const [isDeleting, setIsDeleting] = useState(false)

    // const handleSelectSlot = ({ start, end, resourceId }) => {
    const handleSelectSlot = (slotInfo) => {
        setIsNewBookingOpen(true)
        const { start, end, resourceId } = slotInfo
        console.log(start, end, resourceId)
        // const title = prompt("Enter appointment title:");
        // if (title) {
        //     setAppointments([...appointments, {
        //         id: appointments.length + 1,
        //         title,
        //         start,
        //         end,
        //         resourceId,
        //         status: 'upcoming'
        //     }]);
        // }
        setActiveSlotInfo(slotInfo)
    };
    console.log(appointments)

    const handleSelectEvent = (event) => {
        setSelectedEvent(event);
    };

    const handleEventDrop = useCallback(({ event, start, end, resourceId }) => {
        setAppointments(prevAppointments =>
            prevAppointments.map(appt =>
                appt.id === event.id ? { ...appt, start, end, resourceId } : appt
            )
        );
    }, []);

    const handleEventResize = useCallback(({ event, start, end }) => {
        setAppointments(prevAppointments =>
            prevAppointments.map(appt =>
                appt.id === event.id ? { ...appt, start, end } : appt
            )
        );
    }, []);

    const checkConflict = (newStart, newEnd, resourceId) => {
        return appointments.some(event =>
            event.resourceId === resourceId &&
            ((newStart >= event.start && newStart < event.end) ||
                (newEnd > event.start && newEnd <= event.end))
        );
    };
    const handleDeleteEvent = async () => {
        // console.log(selectedEvent)
        // setAppointments(appointments.filter(appt => appt.id !== selectedEvent.id));
        setIsDeleting(true)
        const deleteReservationsResponse = await fetch(BASE_URL_API + "deleteReservation",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx?.token,
                    eventIdentifier: selectedEvent?.eventIdentifier
                })
            });

        if (!deleteReservationsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const deleteReservationsRespo = await deleteReservationsResponse.json()
            if (deleteReservationsRespo?.status === "success") {
                getReservations()
                toast(`Successfully deleted the event!`)
                setSelectedEvent(null);
            } else {
                if (deleteReservationsRespo.message === "tokenExpired") {
                    authCtx.logout()
                } else {
                    console.log(deleteReservationsRespo)
                }
            }
        }
        setIsDeleting(false)
    };

    const eventStyleGetter = (event) => {
        const backgroundColor = eventColors[event.status] || '#2196F3';
        return {
            style: {
                backgroundColor,
                borderRadius: '5px',
                color: 'white',
                padding: '5px',
            },
        };
    };

    const filteredAppointments = appointments.filter(appt =>
        (filterStatus === 'all' || appt.status === filterStatus) &&
        (selectedStaff === 'all' || appt.resourceId === selectedStaff)
    );

    const postEventAddition = () => {
        toast(`Event created Successfully!`)
        setIsNewBookingOpen(false)
        getReservations()
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === "Escape") {
                setSelectedEvent(null);
            }
        };

        document.addEventListener("keydown", handleKeyDown);
        return () => document.removeEventListener("keydown", handleKeyDown);
    }, []);

    const handleSaveChanges = (updatedEvent) => {
        // Assuming `events` is your state storing all appointments
        setAppointments((prevEvents) =>
            prevEvents.map((event) =>
                event.id === updatedEvent.id ? { ...event, ...updatedEvent } : event
            )
        );

        // Close the modal after saving
        setSelectedEvent(null);

        // If you are saving this data to an API, use a fetch/axios call here
        // Example:
        // fetch(`/api/update-event/${updatedEvent.id}`, {
        //     method: "POST",
        //     headers: { "Content-Type": "application/json" },
        //     body: JSON.stringify(updatedEvent),
        // }).then(() => console.log("Event updated successfully"));
    };

    const getWorkersData = async () => {
        const getWorkersDataResponse = await fetch(BASE_URL_API + "getWorkersData",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore?.storeIdentifier
                })
            });

        if (!getWorkersDataResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getWorkersDataRespo = await getWorkersDataResponse.json();
            if (getWorkersDataRespo.status === 'success') {
                setWorkers(getWorkersDataRespo.response);
            } else {
                if (getWorkersDataRespo.message === 'tokenExpired') {
                    authCtx.logout()
                } else {

                }
            }
            console.log(getWorkersDataRespo)
        }
    }

    useEffect(() => {
        getWorkersData()
    }, [activeStore?.storeIdentifier])


    const getReservations = async () => {
        setIsLoadingEvent(true)
        const getReservationsResponse = await fetch(BASE_URL_API + "getReservations",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx?.token,
                    storeIdentifier: activeStore?.storeIdentifier
                })
            });

        if (!getReservationsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getReservationsRespo = await getReservationsResponse.json()
            if (getReservationsRespo?.status === "success") {
                setAppointments(transformEvents(getReservationsRespo?.response))
            } else {
                if (getReservationsRespo.message === "tokenExpired") {
                    authCtx.logout()
                } else {
                    console.log(getReservationsRespo)
                }
            }
        }
        setIsLoadingEvent(false)
    }

    useEffect(() => {
        getReservations()
    }, [activeStore?.storeIdentifier])

    const transformEvents = (inputArray) => {
        return inputArray.map((event, index) => {
            const startDate = new Date(event.start.replace(' ', 'T'));
            const endDate = event.end === "0000-00-00 00:00:00"
                ? new Date(startDate.getTime() + 23 * 60 * 60 * 1000)
                : new Date(event.end.replace(' ', 'T'));

            const allDay = event.end === "0000-00-00 00:00:00";

            // Dynamically assigning colors based on client name or event details
            let color = '#FF5733'; // Default color
            if (event.clientName) {
                // Example: Assign different colors based on the client
                switch (event.clientName.toLowerCase()) {
                    case 'john':
                        color = '#FF5733';
                        break;
                    case 'sarah':
                        color = '#33FF57';
                        break;
                    case 'alex':
                        color = '#3357FF';
                        break;
                    default:
                        color = '#FF6347'; // Default color for others
                }
            }

            return {
                id: index,
                eventIdentifier: event?.eventIdentifier,
                title: event.title || 'Untitled Event',
                allDay: allDay,
                start: startDate,
                end: endDate,
                servicesNotes: event.servicesNotes,
                remarks: event.remarks,
                clientName: event.clientName,
                color: color, // Add the color property
                resourceId: event?.worker_id
            };
        });
    }

    const formatResourceName = (name) => {
        if (!name) return '';
        const firstName = name.split(' ')[0];
        return firstName.length >= 2 ? firstName : name.length > 10 ? name.substring(0, 10) + '...' : name;
    };

    console.log(selectedEvent)

    if (activeStore === null) {
        return (
            <div className={styles.mainWrapper}>
                <StoresHeader />
                <div className={styles.mainContainer}>
                    <div className={styles.mainContent}>
                        <div className={styles.mainHeader}>
                            Please select a store!
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    return (
        <div className={styles.calendarContainer}>
            <div className={styles.controls}>
                <div className={styles.controlsLeft}>
                    <select onChange={(e) => setFilterStatus(e.target.value)}>
                        <option value="all">All</option>
                        <option value="active">Active</option>
                        <option value="upcoming">Upcoming</option>
                        <option value="cancelled">Cancelled</option>
                    </select>
                    <select onChange={(e) => setSelectedStaff(e.target.value)}>
                        <option value="all">All Staff</option>
                        {workers.map(staff => (
                            <option key={staff.id} value={staff.id}>{staff.name}</option>
                        ))}
                    </select>
                </div>
                <div className={styles.controlsRight}>
                    <CSVLink data={appointments} filename="appointments.csv">
                        <FaDownload /> Export CSV
                    </CSVLink>
                </div>
            </div>
            <div className={styles.calendarWrapper}>
                <DnDCalendar
                    localizer={localizer}
                    events={filteredAppointments}
                    startAccessor="start"
                    endAccessor="end"
                    resourceAccessor="resourceId"
                    resources={workers}
                    resourceIdAccessor="id"
                    views={{ day: true, week: false, month: true, agenda: true }}
                    defaultView={Views.DAY}
                    defaultDate={new Date()}
                    style={{ height: 650 }}
                    selectable
                    onSelectSlot={handleSelectSlot}
                    onSelectEvent={handleSelectEvent}
                    onEventDrop={handleEventDrop}
                    onEventResize={handleEventResize}
                    resizable
                    eventPropGetter={eventStyleGetter}
                    components={{
                        resourceHeader: ({ resource }) => {
                            // Count total appointments for this resource
                            const totalAppointments = appointments.filter(appt => appt.resourceId === resource.id).length;

                            return (
                                <div className={styles.resourceHeader}>
                                    <div>
                                        <span>
                                            {formatResourceName(resource?.name)}
                                        </span>
                                        <span className={styles.appointmentCount}>({totalAppointments})</span>
                                    </div>
                                    <button onClick={() => alert(`Clicked ${resource.name}, ${resource?.id}`)}>+</button>
                                </div>
                            );
                        },
                    }}
                />


            </div>
            {selectedEvent && (
                <motion.div
                    className={styles.modalBackdrop}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    onClick={(e) => {
                        if (e.target.classList.contains(styles.modalBackdrop)) {
                            setSelectedEvent(null);
                        }
                    }}
                >
                    <motion.div
                        className={styles.modalContent}
                        initial={{ y: -50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -50, opacity: 0 }}
                    >
                        <h2>Appointment Details</h2>

                        <label><strong>Title:</strong></label>
                        <input
                            type="text"
                            value={selectedEvent.title}
                            onChange={(e) => setSelectedEvent({ ...selectedEvent, title: e.target.value })}
                        />

                        <div className={styles.timeWrapper}>
                            <div className={styles.eachDate}>
                                <div className={styles.caption}>Start</div>
                                <div className={styles.dateValue}>
                                    {moment(selectedEvent.start).format('LLL')}
                                </div>
                            </div>
                            <div className={styles.eachDate}>
                                <div className={styles.caption}>End</div>
                                <div className={styles.dateValue}>
                                    {moment(selectedEvent.end).format('LLL')}
                                </div>
                            </div>
                        </div>

                        <label><strong>Status:</strong></label>
                        <select value={selectedEvent.status} onChange={(e) => setSelectedEvent({ ...selectedEvent, status: e.target.value })}>
                            <option value="active">Active</option>
                            <option value="upcoming">Upcoming</option>
                            <option value="cancelled">Cancelled</option>
                        </select>

                        <div className={styles.servicesNotes}>
                            {selectedEvent?.servicesNotes}
                        </div>


                        <div className={styles.modalActions}>
                            <button className={styles.cancelBtn} onClick={() => setSelectedEvent(null)}>Close</button>
                            <button className={styles.saveBtn} onClick={() => handleSaveChanges(selectedEvent)}>Save</button>
                            <button className={styles.deleteBtn} onClick={handleDeleteEvent}><FaTrash /> Delete</button>
                        </div>

                        {selectedEvent.resource_id && (
                            <>
                                <p><strong>Resource:</strong> {selectedEvent?.resource_id}</p>
                                <p><strong>Contact:</strong> {selectedEvent.resource.contact}</p>
                            </>
                        )}
                    </motion.div>
                </motion.div>
            )}

            {isNewBookingOpen && <NewFutureBooking isOpen={isNewBookingOpen} onClose={() => setIsNewBookingOpen(false)} onSuccess={() => postEventAddition()} slotInfo={activeSlotInfo} workers={workers} />}

        </div>
    );
};

export default StaffAppointmentCalendar;
