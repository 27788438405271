export const indianBankNameList = [
    {
        "bankName": "AHMEDABAD MERCANTILE COOPERATIVE BANK",
        "bankShortName": "AMCOBANK"
    },
    {
        "bankName": "AIRTEL PAYMENTS BANK LIMITED",
        "bankShortName": "AIRTEL"
    },
    {
        "bankName": "AKOLA JANATA COMMERCIAL COOPERATIVE BANK",
        "bankShortName": "AKOLAJANATABANK"
    },
    {
        "bankName": "ANDHRA PRAGATHI GRAMEENA BANK",
        "bankShortName": "APGB"
    },
    {
        "bankName": "APNA SAHAKARI BANK LIMITED",
        "bankShortName": "APNABANK"
    },
    {
        "bankName": "AUSTRALIA AND NEW ZEALAND BANKING GROUP LIMITED",
        "bankShortName": "ANZ"
    },
    {
        "bankName": "AXIS BANK",
        "bankShortName": "AXIS"
    },
    {
        "bankName": "BANK INTERNASIONAL INDONESIA",
        "bankShortName": "MAYBANK"
    },
    {
        "bankName": "BANK OF AMERICA",
        "bankShortName": "BOA"
    },
    {
        "bankName": "BANK OF BARODA",
        "bankShortName": "BOB"
    },
    {
        "bankName": "BANK OF INDIA",
        "bankShortName": "BOI"
    },
    {
        "bankName": "BANK OF MAHARASHTRA",
        "bankShortName": "BOM"
    },
    {
        "bankName": "BANK OF TOKYO MITSUBISHI LIMITED",
        "bankShortName": "MUFG"
    },
    {
        "bankName": "BARCLAYS BANK",
        "bankShortName": "BARCLAYS"
    },
    {
        "bankName": "BASSEIN CATHOLIC COOPERATIVE BANK LIMITED",
        "bankShortName": "BCCB"
    },
    {
        "bankName": "BHARAT COOPERATIVE BANK MUMBAI LIMITED",
        "bankShortName": "BHARATBANK"
    },
    {
        "bankName": "BHARATIYA MAHILA BANK LIMITED",
        "bankShortName": "BMB"
    },
    {
        "bankName": "CANARA BANK",
        "bankShortName": "CANARABANK"
    },
    {
        "bankName": "CAPITAL SMALL FINANCE BANK LIMITED",
        "bankShortName": "CAPITALBANK"
    },
    {
        "bankName": "CATHOLIC SYRIAN BANK LIMITED",
        "bankShortName": "CSB"
    },
    {
        "bankName": "CENTRAL BANK OF INDIA",
        "bankShortName": "CENTRALBANK"
    },
    {
        "bankName": "CHINATRUST COMMERCIAL BANK LIMITED",
        "bankShortName": "CHINATRUST"
    },
    {
        "bankName": "CITI BANK",
        "bankShortName": "CITIBANK"
    },
    {
        "bankName": "CITIZEN CREDIT COOPERATIVE BANK LIMITED",
        "bankShortName": "CITIZENCREDITBANK"
    },
    {
        "bankName": "CITY UNION BANK LIMITED",
        "bankShortName": "CUB"
    },
    {
        "bankName": "COMMONWEALTH BANK OF AUSTRALIA",
        "bankShortName": "COMMBANK"
    },
    {
        "bankName": "CORPORATION BANK",
        "bankShortName": "CORPBANK"
    },
    {
        "bankName": "CREDIT AGRICOLE CORPORATE AND INVESTMENT BANK CALYON BANK",
        "bankShortName": "CACIB"
    },
    {
        "bankName": "DCB BANK LIMITED",
        "bankShortName": "DCB"
    },
    {
        "bankName": "DENA BANK",
        "bankShortName": "DENABANK"
    },
    {
        "bankName": "DEOGIRI NAGARI SAHAKARI BANK LTD. AURANGABAD",
        "bankShortName": "DEOGIRIBANK"
    },
    {
        "bankName": "DEPOSIT INSURANCE AND CREDIT GUARANTEE CORPORATION",
        "bankShortName": "DICGC"
    },
    {
        "bankName": "DEUSTCHE BANK",
        "bankShortName": "DB"
    },
    {
        "bankName": "DEVELOPMENT BANK OF SINGAPORE",
        "bankShortName": "DBS"
    },
    {
        "bankName": "DHANALAKSHMI BANK",
        "bankShortName": "DHANBANK"
    },
    {
        "bankName": "DOHA BANK",
        "bankShortName": "DOHABANK"
    },
    {
        "bankName": "DOHA BANK QSC",
        "bankShortName": "DOHABANK"
    },
    {
        "bankName": "DOMBIVLI NAGARI SAHAKARI BANK LIMITED",
        "bankShortName": "DNSBANK"
    },
    {
        "bankName": "EQUITAS SMALL FINANCE BANK LIMITED",
        "bankShortName": "EQUITAS"
    },
    {
        "bankName": "EXPORT IMPORT BANK OF INDIA",
        "bankShortName": "EXIMBANK"
    },
    {
        "bankName": "FEDERAL BANK",
        "bankShortName": "FEDERALBANK"
    },
    {
        "bankName": "FIRSTRAND BANK LIMITED",
        "bankShortName": "FIRSTRAND"
    },
    {
        "bankName": "GURGAON GRAMIN BANK",
        "bankShortName": "SHGB"
    },
    {
        "bankName": "HDFC BANK",
        "bankShortName": "HDFC"
    },
    {
        "bankName": "HIMACHAL PRADESH STATE COOPERATIVE BANK LTD",
        "bankShortName": "HPSCB"
    },
    {
        "bankName": "HSBC BANK",
        "bankShortName": "HSBC"
    },
    {
        "bankName": "HSBC BANK OMAN SAOG",
        "bankShortName": "HSBC"
    },
    {
        "bankName": "ICICI BANK LIMITED",
        "bankShortName": "ICICI"
    },
    {
        "bankName": "IDBI BANK",
        "bankShortName": "IDBI"
    },
    {
        "bankName": "IDFC BANK LIMITED",
        "bankShortName": "IDFC"
    },
    {
        "bankName": "INDIAN BANK",
        "bankShortName": "INDIANBANK"
    },
    {
        "bankName": "INDIAN OVERSEAS BANK",
        "bankShortName": "IOB"
    },
    {
        "bankName": "INDUSIND BANK",
        "bankShortName": "INDUSIND"
    },
    {
        "bankName": "INDUSTRIAL AND COMMERCIAL BANK OF CHINA LIMITED",
        "bankShortName": "ICBC"
    },
    {
        "bankName": "INDUSTRIAL BANK OF KOREA",
        "bankShortName": "IBK"
    },
    {
        "bankName": "JALGAON JANATA SAHAKARI BANK LIMITED",
        "bankShortName": "JJSBL"
    },
    {
        "bankName": "JAMMU AND KASHMIR BANK LIMITED",
        "bankShortName": "JKBANK"
    },
    {
        "bankName": "JANAKALYAN SAHAKARI BANK LIMITED",
        "bankShortName": "JKSBL"
    },
    {
        "bankName": "JANASEVA SAHAKARI BANK BORIVLI LIMITED",
        "bankShortName": "JANASEVABANK"
    },
    {
        "bankName": "JANASEVA SAHAKARI BANK LIMITED",
        "bankShortName": "JANASEVABANK"
    },
    {
        "bankName": "JANATA SAHAKARI BANK LIMITED",
        "bankShortName": "JANATABANK"
    },
    {
        "bankName": "JP MORGAN BANK",
        "bankShortName": "JPMORGAN"
    },
    {
        "bankName": "KALLAPPANNA AWADE ICHALKARANJI JANATA SAHAKARI BANK LIMITED",
        "bankShortName": "IJSBANK"
    },
    {
        "bankName": "KALUPUR COMMERCIAL COOPERATIVE BANK",
        "bankShortName": "KALUPURBANK"
    },
    {
        "bankName": "KALYAN JANATA SAHAKARI BANK",
        "bankShortName": "KALYANJANATA"
    },
    {
        "bankName": "KAPOL COOPERATIVE BANK LIMITED",
        "bankShortName": "KAPOLBANK"
    },
    {
        "bankName": "KARNATAKA BANK LIMITED",
        "bankShortName": "KARNATAKABANK"
    },
    {
        "bankName": "KARNATAKA VIKAS GRAMEENA BANK",
        "bankShortName": "KVGBANK"
    },
    {
        "bankName": "KARUR VYSYA BANK",
        "bankShortName": "KVB"
    },
    {
        "bankName": "KEB HANA BANK",
        "bankShortName": "KEBHANA"
    },
    {
        "bankName": "KERALA GRAMIN BANK",
        "bankShortName": "KERALAGBANK"
    },
    {
        "bankName": "KOTAK MAHINDRA BANK LIMITED",
        "bankShortName": "KOTAK"
    },
    {
        "bankName": "LAXMI VILAS BANK",
        "bankShortName": "LVB"
    },
    {
        "bankName": "MAHANAGAR COOPERATIVE BANK",
        "bankShortName": "MAHANAGARBANK"
    },
    {
        "bankName": "MAHARASHTRA GRAMIN BANK",
        "bankShortName": "MAHAGRAMIN"
    },
    {
        "bankName": "MAHARASHTRA STATE COOPERATIVE BANK",
        "bankShortName": "MSCBANK"
    },
    {
        "bankName": "MASHREQBANK PSC",
        "bankShortName": "MASHREQBANK"
    },
    {
        "bankName": "MIZUHO BANK LTD",
        "bankShortName": "MIZUHOBANK"
    },
    {
        "bankName": "NAGAR URBAN CO OPERATIVE BANK",
        "bankShortName": "NUCB"
    },
    {
        "bankName": "NAGPUR NAGARIK SAHAKARI BANK LIMITED",
        "bankShortName": "NNSBANK"
    },
    {
        "bankName": "NATIONAL AUSTRALIA BANK LIMITED",
        "bankShortName": "NAB"
    },
    {
        "bankName": "NATIONAL BANK OF ABU DHABI PJSC",
        "bankShortName": "NBAD"
    },
    {
        "bankName": "NEW INDIA COOPERATIVE BANK LIMITED",
        "bankShortName": "NEWINDIABANK"
    },
    {
        "bankName": "NKGSB COOPERATIVE BANK LIMITED",
        "bankShortName": "NKGSB"
    },
    {
        "bankName": "NORTH MALABAR GRAMIN BANK",
        "bankShortName": "KERALAGBANK"
    },
    {
        "bankName": "NUTAN NAGARIK SAHAKARI BANK LIMITED",
        "bankShortName": "NUTANBANK"
    },
    {
        "bankName": "ORIENTAL BANK OF COMMERCE",
        "bankShortName": "OBC"
    },
    {
        "bankName": "PARIBAS",
        "bankShortName": "BNPPARIBAS"
    },
    {
        "bankName": "PARSIK BANK",
        "bankShortName": "GPPARSIKBANK"
    },
    {
        "bankName": "PRAGATHI KRISHNA GRAMIN BANK",
        "bankShortName": "PRAGATHIKRISHNABANK"
    },
    {
        "bankName": "PRATHAMA BANK",
        "bankShortName": "PRATHAMABANK"
    },
    {
        "bankName": "PRIME COOPERATIVE BANK LIMITED",
        "bankShortName": "PRIMEBANK"
    },
    {
        "bankName": "PT BANK MAYBANK INDONESIA TBK",
        "bankShortName": "MAYBANK"
    },
    {
        "bankName": "PUNJAB AND MAHARSHTRA COOPERATIVE BANK",
        "bankShortName": "PMC"
    },
    {
        "bankName": "PUNJAB AND SIND BANK",
        "bankShortName": "PSB"
    },
    {
        "bankName": "PUNJAB NATIONAL BANK",
        "bankShortName": "PNB"
    },
    {
        "bankName": "RAJGURUNAGAR SAHAKARI BANK LIMITED",
        "bankShortName": "RAJGURUNAGARBANK"
    },
    {
        "bankName": "RAJKOT NAGRIK SAHAKARI BANK LIMITED",
        "bankShortName": "RNSB"
    },
    {
        "bankName": "RBL BANK LIMITED",
        "bankShortName": "RBL"
    },
    {
        "bankName": "RESERVE BANK OF INDIA",
        "bankShortName": "RBI"
    },
    {
        "bankName": "SAHEBRAO DESHMUKH COOPERATIVE BANK LIMITED",
        "bankShortName": "SDCBANK"
    },
    {
        "bankName": "SAMARTH SAHAKARI BANK LTD",
        "bankShortName": "SAMARTHBANK"
    },
    {
        "bankName": "SARASWAT COOPERATIVE BANK LIMITED",
        "bankShortName": "SARASWATBANK"
    },
    {
        "bankName": "SBER BANK",
        "bankShortName": "SBERBANK"
    },
    {
        "bankName": "SBM BANK MAURITIUS LIMITED",
        "bankShortName": "SBM"
    },
    {
        "bankName": "SHIKSHAK SAHAKARI BANK LIMITED",
        "bankShortName": "SHIKSHAKBANK"
    },
    {
        "bankName": "SHINHAN BANK",
        "bankShortName": "SHINHAN"
    },
    {
        "bankName": "SHIVALIK MERCANTILE CO OPERATIVE BANK LTD",
        "bankShortName": "SHIVALIKBANK"
    },
    {
        "bankName": "SOCIETE GENERALE",
        "bankShortName": "SOCIETEGENERALE"
    },
    {
        "bankName": "SOLAPUR JANATA SAHAKARI BANK LIMITED",
        "bankShortName": "SJSBBANK"
    },
    {
        "bankName": "SOUTH INDIAN BANK",
        "bankShortName": "SOUTHINDIANBANK"
    },
    {
        "bankName": "STANDARD CHARTERED BANK",
        "bankShortName": "SC"
    },
    {
        "bankName": "STATE BANK OF BIKANER AND JAIPUR",
        "bankShortName": "SBBJ"
    },
    {
        "bankName": "STATE BANK OF HYDERABAD",
        "bankShortName": "SBHYD"
    },
    {
        "bankName": "STATE BANK OF INDIA",
        "bankShortName": "SBI"
    },
    {
        "bankName": "STATE BANK OF MAURITIUS LIMITED",
        "bankShortName": "SBMGROUP"
    },
    {
        "bankName": "STATE BANK OF MYSORE",
        "bankShortName": "SBM"
    },
    {
        "bankName": "STATE BANK OF PATIALA",
        "bankShortName": "SBP"
    },
    {
        "bankName": "STATE BANK OF TRAVANCORE",
        "bankShortName": "SBT"
    },
    {
        "bankName": "SUMITOMO MITSUI BANKING CORPORATION",
        "bankShortName": "SMBC"
    },
    {
        "bankName": "SURAT NATIONAL COOPERATIVE BANK LIMITED",
        "bankShortName": "SURATNATIONALBANK"
    },
    {
        "bankName": "SUTEX COOPERATIVE BANK LIMITED",
        "bankShortName": "SUTEXBANK"
    },
    {
        "bankName": "SYNDICATE BANK",
        "bankShortName": "SYNDICATEBANK"
    },
    {
        "bankName": "TAMILNAD MERCANTILE BANK LIMITED",
        "bankShortName": "TMBL"
    },
    {
        "bankName": "THE A.P. MAHESH COOPERATIVE URBAN BANK LIMITED",
        "bankShortName": "APMAHESHBANK"
    },
    {
        "bankName": "THE AKOLA DISTRICT CENTRAL COOPERATIVE BANK",
        "bankShortName": "AKOLADCC"
    },
    {
        "bankName": "THE ANDHRA PRADESH STATE COOPERATIVE BANK LIMITED",
        "bankShortName": "APCOB"
    },
    {
        "bankName": "THE BANK OF NOVA SCOTIA",
        "bankShortName": "SCOTIABANK"
    },
    {
        "bankName": "THE COSMOS CO OPERATIVE BANK LIMITED",
        "bankShortName": "COSMOSBANK"
    },
    {
        "bankName": "THE GADCHIROLI DISTRICT CENTRAL COOPERATIVE BANK LIMITED",
        "bankShortName": "GDCCBANK"
    },
    {
        "bankName": "THE GREATER BOMBAY COOPERATIVE BANK LIMITED",
        "bankShortName": "GREATERBANK"
    },
    {
        "bankName": "THE GUJARAT STATE COOPERATIVE BANK LIMITED",
        "bankShortName": "GSCBANK"
    },
    {
        "bankName": "THE HASTI COOP BANK LTD",
        "bankShortName": "HASTIBANK"
    },
    {
        "bankName": "THE JALGAON PEOPELS COOPERATIVE BANK LIMITED",
        "bankShortName": "JPCBANK"
    },
    {
        "bankName": "THE KANGRA CENTRAL COOPERATIVE BANK LIMITED",
        "bankShortName": "KCCB"
    },
    {
        "bankName": "THE KANGRA COOPERATIVE BANK LIMITED",
        "bankShortName": "KANGRABANK"
    },
    {
        "bankName": "THE KARANATAKA STATE COOPERATIVE APEX BANK LIMITED",
        "bankShortName": "KARNATAKAAPEX"
    },
    {
        "bankName": "THE KURMANCHAL NAGAR SAHAKARI BANK LIMITED",
        "bankShortName": "KURMANCHALBANK"
    },
    {
        "bankName": "THE MEHSANA URBAN COOPERATIVE BANK",
        "bankShortName": "MUCBANK"
    },
    {
        "bankName": "THE MUMBAI DISTRICT CENTRAL COOPERATIVE BANK LIMITED",
        "bankShortName": "MDCCBANK"
    },
    {
        "bankName": "THE MUNICIPAL COOPERATIVE BANK LIMITED",
        "bankShortName": "MUNICIPALBANK"
    },
    {
        "bankName": "THE NAINITAL BANK LIMITED",
        "bankShortName": "NAINITALBANK"
    },
    {
        "bankName": "THE NASIK MERCHANTS COOPERATIVE BANK LIMITED",
        "bankShortName": "NAMCOBANK"
    },
    {
        "bankName": "THE PANDHARPUR URBAN CO OP. BANK LTD. PANDHARPUR",
        "bankShortName": "PANDHARPURBANK"
    },
    {
        "bankName": "THE RAJASTHAN STATE COOPERATIVE BANK LIMITED",
        "bankShortName": "RSCB"
    },
    {
        "bankName": "THE ROYAL BANK OF SCOTLAND",
        "bankShortName": "RBS"
    },
    {
        "bankName": "THE SEVA VIKAS COOPERATIVE BANK LIMITED",
        "bankShortName": "SEVAVIKASBANK"
    },
    {
        "bankName": "THE SHAMRAO VITHAL COOPERATIVE BANK",
        "bankShortName": "SVCBANK"
    },
    {
        "bankName": "THE SURAT DISTRICT COOPERATIVE BANK LIMITED",
        "bankShortName": "SUDICOBANK"
    },
    {
        "bankName": "THE SURATH PEOPLES COOPERATIVE BANK LIMITED",
        "bankShortName": "SPCBL"
    },
    {
        "bankName": "THE TAMIL NADU STATE APEX COOPERATIVE BANK",
        "bankShortName": "TNSCBANK"
    },
    {
        "bankName": "THE THANE BHARAT SAHAKARI BANK LIMITED",
        "bankShortName": "THANEBHARATBANK"
    },
    {
        "bankName": "THE THANE DISTRICT CENTRAL COOPERATIVE BANK LIMITED",
        "bankShortName": "THANEDISTRICTBANK"
    },
    {
        "bankName": "THE VARACHHA COOPERATIVE BANK LIMITED",
        "bankShortName": "VARACHHABANK"
    },
    {
        "bankName": "THE VISHWESHWAR SAHAKARI BANK LIMITED",
        "bankShortName": "VISHWESHWARBANK"
    },
    {
        "bankName": "THE WEST BENGAL STATE COOPERATIVE BANK",
        "bankShortName": "WBSCB"
    },
    {
        "bankName": "THE ZOROASTRIAN COOPERATIVE BANK LIMITED",
        "bankShortName": "ZOROASTRIANBANK"
    },
    {
        "bankName": "TJSB SAHAKARI BANK LIMITED",
        "bankShortName": "TJSBBANK"
    },
    {
        "bankName": "TJSB SAHAKARI BANK LTD",
        "bankShortName": "TJSBBANK"
    },
    {
        "bankName": "TUMKUR GRAIN MERCHANTS COOPERATIVE BANK LIMITED",
        "bankShortName": "TGMCBANK"
    },
    {
        "bankName": "UCO BANK",
        "bankShortName": "UCOBANK"
    },
    {
        "bankName": "UNION BANK OF INDIA",
        "bankShortName": "UNIONBANK"
    },
    {
        "bankName": "UNITED BANK OF INDIA",
        "bankShortName": "UNITEDBANK"
    },
    {
        "bankName": "UNITED OVERSEAS BANK LIMITED",
        "bankShortName": "UOB"
    },
    {
        "bankName": "VASAI VIKAS SAHAKARI BANK LIMITED",
        "bankShortName": "VASAIVIKASBANK"
    },
    {
        "bankName": "VASAI VIKAS SAHAKARI BANK LTD",
        "bankShortName": "VASAIVIKASBANK"
    },
    {
        "bankName": "VIJAYA BANK",
        "bankShortName": "VIJAYABANK"
    },
    {
        "bankName": "WESTPAC BANKING CORPORATION",
        "bankShortName": "WESTPAC"
    },
    {
        "bankName": "WOORI BANK",
        "bankShortName": "WOORIBANK"
    },
    {
        "bankName": "YES BANK",
        "bankShortName": "YESBANK"
    },
    {
        "bankName": "ZILA SAHAKRI BANK LIMITED GHAZIABAD",
        "bankShortName": "ZSBL"
    }
]