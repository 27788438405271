import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router';
import { BASE_URL_API } from '../../../References/URLs';
import ActiveStoreContext from '../../../store/ActiveStoreContext';
import AuthContext from '../../../store/auth-context';
import styles from './CampaignAudience.module.css';

const CampaignAudience = ({ data, setData }) => {
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext);
    const activeStore = (() => {
        try {
            return asCtx?.activeStore && asCtx.activeStore.trim() !== ""
                ? JSON.parse(asCtx.activeStore)
                : null;
        } catch {
            return null;
        }
    })();
    const [audience, setAudience] = useState(data.audience);
    const [searchQuery, setSearchQuery] = useState(''); // State for search filter
    const [selectedAudience, setSelectedAudience] = useState(null); // Track selected audience

    // Example audience data, ideally it would come from an external source or prop
    const [audienceData, setAudienceData] = useState([
        {
            audienceIdentifier: 'sdfkh23fd',
            audienceName: 'All male users',
            audienceDescription: 'All registered male users',
            audienceData: [
                { phoneNumber: '919999648406', clientName: 'Navneet Chandan', gender: 'Male' },
                { phoneNumber: '919999648407', clientName: 'John Doe', gender: 'Male' },
                // Add more users here...
            ],
            isSelected: false,
        },
        {
            audienceIdentifier: 'adfwe56df',
            audienceName: 'All female users',
            audienceDescription: 'All registered female users',
            audienceData: [
                { phoneNumber: '919999648408', clientName: 'Jane Doe', gender: 'Female' },
                { phoneNumber: '919999648409', clientName: 'Sarah Smith', gender: 'Female' },
                // Add more users here...
            ],
            isSelected: false,
        },
        // Add more audience objects here...
    ]);

    const handleAudienceChange = (e) => {
        setAudience(e.target.value);
        setData({ ...data, audience: e.target.value });
    };

    const handleSelectAudience = (audienceItem) => {
        // Toggle audience selection
        const updatedAudienceData = audienceData.map((aud) =>
            aud?.audienceIdentifier === audienceItem?.audienceIdentifier
                ? { ...aud, isSelected: !aud?.isSelected }
                : aud
        );
        // Set selected audience in state
        setSelectedAudience(updatedAudienceData?.find((aud) => aud?.isSelected));
        // Update data with selected audience
        setData({ ...data, audience: audienceItem });
    };

    const filteredAudienceData = audienceData?.filter((audienceItem) =>
        audienceItem.audienceName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        audienceItem.audienceDescription.toLowerCase().includes(searchQuery.toLowerCase())
    );



    const getAllAudiences = async () => {
        const getAllAudiencesResponse = await fetch(BASE_URL_API + "getAllAudiences",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore?.storeIdentifier,
                }),
            });

        if (!getAllAudiencesResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const getAllAudiencesRespo = await getAllAudiencesResponse.json();
            if (getAllAudiencesRespo.status === "success") {
                setAudienceData(getAllAudiencesRespo?.response);
            } else if (getAllAudiencesRespo.message === "tokenExpired") {
                authCtx.logout();
            } else {
                setAudienceData([])
            }
            console.log(getAllAudiencesRespo);
        }
    };

    useEffect(() => {
        getAllAudiences()
    }, [])


    return (
        <div className={styles.container}>
            <div className={styles.headerWrapper}>
                <div>Select Audience</div>
                <div className={styles.controlsWrapper}>
                    <div className={styles.searchAudienceWrapper}>
                        <input
                            type="text"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            placeholder="Search by audience name or description"
                            className={styles.searchInput}
                        />
                    </div>
                    <div className={styles.controlsBtn}>
                        <Link to={`/communications/audience/create-new`}>Create New Audience</Link>
                    </div>
                </div>
            </div>

            <div className={styles.audienceList}>
                {filteredAudienceData?.map((audienceItem) => (
                    <div
                        key={audienceItem.audienceIdentifier}
                        className={`${styles.audienceCard} ${audienceItem.isSelected ? styles.selected : ''
                            }`}
                        onClick={() => handleSelectAudience(audienceItem)}
                    >
                        <h3>{audienceItem.audienceName}</h3>
                        <p>{audienceItem.audienceDescription}</p>
                        <div className={styles.preview}>
                            <strong>Preview:</strong>
                            {audienceItem?.audienceData?.slice(0, 2).map((user, index) => (
                                <div key={index}>
                                    <p>{user.clientName} - {user.phoneNumber}</p>
                                </div>
                            ))}
                            {audienceItem?.audienceData?.length > 2 && <p>+ {audienceItem?.audienceData?.length - 2} more</p>}
                        </div>
                    </div>
                ))}
            </div>

            <div className={styles.selectedAudience}>
                {selectedAudience && (
                    <>
                        <h3>Selected Audience: {selectedAudience.audienceName}</h3>
                        <p>{selectedAudience.audienceDescription}</p>
                        <div className={styles.selectedPreview}>
                            <strong>Preview of Selected Audience Data:</strong>
                            {selectedAudience?.audienceData?.map((user, index) => (
                                <div key={index}>
                                    <p>{user?.clientName} - {user?.phoneNumber} - {user?.gender}</p>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>

            <button
                className={styles.nextBtn}
                onClick={() => {
                    // Proceed to next step after audience selection
                    if (selectedAudience) {
                        // Handle next step, like navigating to the next page
                        console.log('Audience selected:', selectedAudience);
                    } else {
                        console.log('No audience selected');
                    }
                }}
            >
                Next
            </button>
        </div>
    );
};

export default CampaignAudience;
