import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { FaCalendar, FaChevronDown } from 'react-icons/fa'
import NavAtHome from '../../Components/Navigators/NavAtHome'
import DateRangeSelector from '../../Components/UI/DateRangeSelector'
import { BASE_URL_API } from '../../References/URLs'
import AuthContext from '../../store/auth-context'
import styles from '../StaffManagement/ManageStaff.module.css'
import { Link } from 'react-router-dom'
import StarPerformers from '../../Components/StaffManagement/StarPerformers'
import ManageEachStaffWrapper from '../../Components/StaffManagement/ManageEachStaffWrapper'
import ActiveStoreContext from '../../store/ActiveStoreContext'
import StaffManagementMainHeader from '../../Components/StaffManagement/StaffManagementMainHeader'
import ManageStaffSummary from './ManageStaffSummary'

const ManageStaff = () => {
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();
    const { staffIdentifier, activeTab } = useParams()


    const [isStaffOpen, setIsStaffOpen] = useState(true)
    const closeStaffPopup = () => setIsStaffOpen(false)
    const [dates, setDates] = useState({
        "startDate": "",
        "endDate": ""
    })

    const changeDateHandler = (s, e) => {
        setDates({
            "startDate": s,
            "endDate": e
        })
    }


    const [workers, setWorkers] = useState([])

    const getWorkersData = async () => {
        const getWorkersDataResponse = await fetch(BASE_URL_API + "getWorkersData",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore?.storeIdentifier
                })
            });

        if (!getWorkersDataResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getWorkersDataRespo = await getWorkersDataResponse.json();
            if (getWorkersDataRespo.status === 'success') {
                setWorkers(getWorkersDataRespo.response);
            } else {
                if (getWorkersDataRespo.message === 'tokenExpired') {
                    authCtx.logout()
                } else {

                }
            }
            console.log(getWorkersDataRespo)
        }
    }

    useEffect(() => {
        getWorkersData()
    }, [authCtx?.storeIdentifier])



    if (activeStore === null) {
        return (
            <div>
                Please select a store.
            </div>
        )
    }



    return (
        <div className={styles.mainWrapper}>
            <StaffManagementMainHeader workers={workers} dates={dates} changeDateHandler={changeDateHandler} />
            {staffIdentifier !== undefined ?
                <div className={styles.mainBody}>
                    {staffIdentifier === 'summary' && <ManageStaffSummary dates={dates} />}
                    {staffIdentifier !== 'summary' && <ManageEachStaffWrapper dates={dates} activeTab={activeTab} />}
                </div>
                :
                <div className={styles.mainBody}>
                    <div className={styles.selectAStaff}>
                        <div className={styles.selectIcon}>
                            <img src="https://static.vecteezy.com/system/resources/thumbnails/048/400/696/small_2x/employee-selection-3d-illustration-pointing-finger-select-person-icon-png.png" />
                        </div>
                        <div className={styles.selectTitle}>
                            Please select a staff
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default ManageStaff