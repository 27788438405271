import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { FaFilter, FaSort, FaChartLine } from "react-icons/fa";
import styles from "./ManageEachStaffWorkBookingData.module.css";

const ManageEachStaffWorkBookingData = ({ workDetails }) => {
    const [filteredData, setFilteredData] = useState([]);
    const [sortOrder, setSortOrder] = useState("asc");
    const [selectedService, setSelectedService] = useState("All");
    const [selectedDate, setSelectedDate] = useState("");

    console.log(workDetails?.dailyTransData[0]?.allServices)
    useEffect(() => {
        if (Array.isArray(workDetails?.dailyTransData[0]?.allServices)) {
            setFilteredData(workDetails?.dailyTransData[0]?.allServices);
        } else {
            setFilteredData([]);
        }
    }, [workDetails]);

    const handleSort = () => {
        const sortedData = [...filteredData].sort((a, b) => {
            return sortOrder === "asc"
                ? a.totalDiscPrice - b.totalDiscPrice
                : b.totalDiscPrice - a.totalDiscPrice;
        });
        setFilteredData(sortedData);
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    };

    const handleFilter = () => {
        let newData = workDetails;

        if (selectedService !== "All") {
            newData = newData.filter((item) => item.serviceType === selectedService);
        }

        if (selectedDate) {
            newData = newData.filter((item) => item.date === selectedDate);
        }

        setFilteredData(newData);
    };

    return (
        <div className={styles.container}>
            {/* Filters */}
            <div className={styles.filters}>
                <select onChange={(e) => setSelectedService(e.target.value)}>
                    <option value="All">All Services</option>
                    <option value="Haircut">Haircut</option>
                    <option value="Makeup">Makeup</option>
                    <option value="Spa">Spa</option>
                </select>

                <input
                    type="date"
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(e.target.value)}
                />

                <motion.button
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    className={styles.filterBtn}
                    onClick={handleFilter}
                >
                    <FaFilter /> Apply Filters
                </motion.button>
            </div>

            {/* Sorting */}
            <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className={styles.sortBtn}
                onClick={handleSort}
            >
                <FaSort /> Sort by Work Done ({sortOrder === "asc" ? "🔼" : "🔽"})
            </motion.button>

            {/* Data Table */}
            <div className={styles.table}>
                {filteredData.length > 0 ? (
                    filteredData.map((staff, index) => (
                        <motion.div
                            key={index}
                            className={styles.staffCard}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.4 }}
                        >
                            <h3>₹{staff.totalDiscPrice}</h3>
                            <p>Service: {staff.serviceType}</p>
                            <p>Completed Work: {staff.completedWork}</p>
                            <p>Date: {staff.date}</p>
                        </motion.div>
                    ))
                ) : (
                    <p className={styles.noData}>No Data Available</p>
                )}
            </div>
        </div>
    );
};

export default ManageEachStaffWorkBookingData;
