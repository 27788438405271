import React, { useContext, useEffect, useState } from 'react'
import PlotMonthlyRevenue from '../../Components/Analytics/PlotMonthlyRevenue'
import { BASE_URL_API } from '../../References/URLs'
import ActiveStoreContext from '../../store/ActiveStoreContext'
import AuthContext from '../../store/auth-context'
import styles from '../Analytics/MonthlyRevenue.module.css'

const MonthlyRevenue = props => {
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();

    const [salesData, setSalesData] = useState([])

    const getMonthlySales = async () => {
        const getMonthlySalesResponse = await fetch(BASE_URL_API + "getMonthlySales",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore?.storeIdentifier
                })
            });

        if (!getMonthlySalesResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getMonthlySalesRespo = await getMonthlySalesResponse.json();
            if (getMonthlySalesRespo.status === 'success') {
                setSalesData(getMonthlySalesRespo.response)
            } else {
                if (getMonthlySalesRespo.message === 'tokenExpired') {
                    authCtx.logout()
                } else {
                    setSalesData([])
                }
            }

            console.log(getMonthlySalesRespo)
        }
    }

    useEffect(() => {
        getMonthlySales()
    }, [])


    return (
        <div>
            <div>
                MonthlyRevenue
            </div>
            <div>
                <div className={styles.eachMonth}>
                    <div className={styles.eachCol}>
                        Month
                    </div>
                    <div className={styles.eachCol}>
                        Total Visits
                    </div>
                    <div className={styles.eachCol}>
                        Days
                    </div>
                    <div className={styles.eachCol}>
                        Avg. visits/day
                    </div>
                    <div className={styles.eachCol}>
                        Avg. daily sale
                    </div>
                    <div className={styles.eachCol}>
                        Avg. expenditure / visit
                    </div>
                    <div className={styles.eachCol}>
                        Total Monthly Sale
                    </div>
                    <div className={styles.eachCol}>
                        Total Sundays
                    </div>
                    {/* <div>
                        Visits in Saturdays
                    </div>
                    <div>
                        Visits in Sundays
                    </div> */}
                </div>
                {salesData.map((item, index) => (
                    <div key={index} className={styles.eachMonth}>
                        <div className={styles.eachCol}>
                            {item.month}
                        </div>
                        <div className={styles.eachCol}>
                            {item.total_sessions}
                        </div>
                        <div className={styles.eachCol}>
                            {item.days_in_month}
                        </div>
                        <div className={styles.eachCol}>
                            {parseFloat(item.total_sessions / item.days_in_month).toFixed(1)}
                        </div>
                        <div className={styles.eachCol}>
                            {parseFloat(item.total_amount / item.days_in_month).toFixed(0)}
                        </div>
                        <div className={styles.eachCol}>
                            {parseFloat(item.total_amount / item.total_sessions).toFixed(1)}
                        </div>
                        <div className={styles.eachCol}>
                            {parseInt(item.total_amount)}
                        </div>
                        <div className={styles.eachCol}>
                            {item.sundays_in_month}
                        </div>
                        {/* <div>
                            {item.visits_in_saturdays}
                        </div>
                        <div>
                            {item.visits_in_sundays}
                        </div> */}

                    </div>
                ))}
            </div>


<div>

    Plot graph

    <PlotMonthlyRevenue salesData={salesData} />
</div>


            <div>
                <div>
                    Day wise breakup
                </div>

                <div>
                    <div className={styles.eachMonth}>
                        <div className={styles.eachCol}>Month</div>
                        <div className={styles.eachCol}>Total Mondays</div>
                        <div className={styles.eachCol}>Total Tuesdays</div>
                        <div className={styles.eachCol}>Total Wednesdays</div>
                        <div className={styles.eachCol}>Total Thursdays</div>
                        <div className={styles.eachCol}>Total Fridays</div>
                        <div className={styles.eachCol}>Total Saturdays</div>
                        <div className={styles.eachCol}>Total Sundays</div>

                    </div>
                    <div>
                        {salesData.map((item, index) => (
                            <div className={styles.eachMonth} key={index + "D"}>
                                <div>
                                    {item.month}
                                </div>
                                
                                <div>
                                    {item.visits_in_mondays} /
                                    {item.mondays_in_month}

                                    <div>
                                        {parseFloat(item.visits_in_mondays / item.mondays_in_month).toFixed(1)}
                                    </div>
                                </div>
                                <div>
                                    {item.visits_in_tuesdays} /
                                    {item.tuesdays_in_month}

                                    <div>
                                        {parseFloat(item.visits_in_tuesdays / item.tuesdays_in_month).toFixed(1)}
                                    </div>
                                </div>
                                <div>
                                    {item.visits_in_wednesdays} /
                                    {item.wednesdays_in_month}
                                    <div>
                                        {parseFloat(item.visits_in_wednesdays / item.wednesdays_in_month).toFixed(1)}
                                    </div>
                                </div>
                                <div>
                                    {item.visits_in_thursdays} /
                                    {item.thursdays_in_month}
                                    <div>
                                        {parseFloat(item.visits_in_thursdays / item.thursdays_in_month).toFixed(1)}
                                    </div>
                                </div>
                                <div>
                                    {item.visits_in_fridays} /
                                    {item.fridays_in_month}
                                    <div>
                                        {parseFloat(item.visits_in_fridays / item.fridays_in_month).toFixed(1)}
                                    </div>
                                </div>
                                <div>
                                    {item.visits_in_saturdays} /
                                    {item.saturdays_in_month}
                                    <div>
                                        {parseFloat(item.visits_in_saturdays / item.saturdays_in_month).toFixed(1)}
                                    </div>
                                </div>
                                <div>
                                    {item.visits_in_sundays} /
                                    {item.sundays_in_month}

                                    <div>
                                        {parseFloat(item.visits_in_sundays / item.sundays_in_month).toFixed(1)}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

            </div>



        </div>
    )
}

export default MonthlyRevenue