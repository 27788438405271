import React, { useState } from 'react'
import { Link, useParams } from 'react-router';
import styles from './ManageEachStaffHeader.module.css'

const ManageEachStaffHeader = props => {
    const { staffIdentifier, activeTab } = useParams()

    return (
        <div className={styles.mainWrapper} >
            <Link
                className={activeTab !== "profile" ? styles.tab : styles.activeTab}
                to={`/staff/${staffIdentifier}/profile`}
            >
                Profile
            </Link>
            <Link className={activeTab !== "attendance" ? styles.tab : styles.activeTab}
                to={`/staff/${staffIdentifier}/attendance`}
            >
                Attendance
            </Link>
            <Link className={activeTab !== "work" ? styles.tab : styles.activeTab}
                to={`/staff/${staffIdentifier}/work`}
            >
                Work
            </Link>
            <Link className={activeTab !== "incentive" ? styles.tab : styles.activeTab}
                to={`/staff/${staffIdentifier}/incentive`}
            >
                Incentive
            </Link>
            <Link className={activeTab !== "performance" ? styles.tab : styles.activeTab}
                to={`/staff/${staffIdentifier}/performance`}
            >
                Performance
            </Link>
        </div>

    )
}

export default ManageEachStaffHeader