import React, { useState, useRef, useEffect } from 'react';
import { FaCalendar, FaList } from 'react-icons/fa';
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { CiGrid41 } from 'react-icons/ci';
import styles from './ManageEachStaffAttendance.module.css';
import MESATabular from './MESATabular';
import MESAList from './MESAList';
import MESACalendar from './MESACalendar';
import MESAEntryPopUp from './MESAEntryPopUp';

const ManageEachStaffAttendance = () => {
    const [activeView, setActiveView] = useState('List');
    const [currentMonthYear, setCurrentMonthYear] = useState({
        month: new Date().toLocaleString('en-US', { month: 'long' }), // Full month name
        year: new Date().getFullYear()
    })
    const [days, setDays] = useState([])
    const [showDatePicker, setShowDatePicker] = useState(false);
    const datePickerRef = useRef(null);

    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const [startYear, setStartYear] = useState(new Date().getFullYear() - 5);
    const [isMarkingAttendance, setIsMarkingAttendance] = useState(false)

    const toggleDatePicker = () => {
        setShowDatePicker(prev => !prev);
    };

    const selectMonthYear = (month, year) => {
        setCurrentMonthYear({ month, year });
        setShowDatePicker(false);
    };

    const handleClickOutside = (event) => {
        if (
            datePickerRef.current &&
            !datePickerRef.current.contains(event.target)
        ) {
            setShowDatePicker(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    // Function to handle left and right arrow navigation
    const handleMonthChange = (direction) => {
        let currentIndex = months.indexOf(currentMonthYear.month);
        let newYear = currentMonthYear.year;
        let newIndex = currentIndex + direction;

        if (newIndex < 0) {
            newYear -= 1;
            newIndex = 11; // December
        } else if (newIndex > 11) {
            newYear += 1;
            newIndex = 0; // January
        }

        setCurrentMonthYear({ month: months[newIndex], year: newYear });
    };


    const getDaysInMonth = (month, year) => {
        const monthIndex = new Date(`${month} 1, ${year}`).getMonth(); // Convert month name to index
        const totalDays = new Date(year, monthIndex + 1, 0).getDate(); // Get total days in month

        return Array.from({ length: totalDays }, (_, i) => {
            const date = i + 1;
            const dayName = new Date(year, monthIndex, date).toLocaleDateString('en-US', { weekday: 'long' });

            return { date, day: dayName };
        });
    };


    useEffect(() => {
        setDays(getDaysInMonth(currentMonthYear.month, currentMonthYear.year));
    }, [currentMonthYear]);




    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.headerWrapper}>
                    <div className={styles.dateChangeWrapper}>
                        <FiChevronLeft size={20} onClick={() => handleMonthChange(-1)} />
                        <div className={styles.currentDate} onClick={toggleDatePicker}>
                            {currentMonthYear?.month?.substr(0, 3)} {currentMonthYear?.year}
                        </div>
                        <FiChevronRight size={20} onClick={() => handleMonthChange(1)} />
                        {showDatePicker && (
                            <div ref={datePickerRef} className={styles.datePicker}>
                                <div className={styles.datePickerHeader}>Select Month & Year</div>

                                <div className={styles.monthGrid}>
                                    {months.map(month => (
                                        <div key={month} className={styles.monthItem}
                                            onClick={() => selectMonthYear(month, currentMonthYear.year)}
                                        >
                                            {month.substr(0, 3)}
                                        </div>
                                    ))}
                                </div>

                                <div className={styles.yearSelector}>
                                    <button className={styles.yearButton}
                                        onClick={() => setStartYear(prev => prev - 2)}
                                    >‹</button>

                                    <div className={styles.yearGrid}>
                                        {Array.from({ length: 2 }, (_, i) => startYear + i).map(year => (
                                            <div key={year} className={styles.yearItem}
                                                onClick={() => selectMonthYear(currentMonthYear.month, year)}
                                            >
                                                {year}
                                            </div>
                                        ))}
                                    </div>

                                    <button className={styles.yearButton}
                                        onClick={() => setStartYear(prev => prev + 2)}
                                    >›</button>
                                </div>
                            </div>
                        )}
                    </div>



                    <div className={styles.iconsWrapper}>
                        <div className={activeView === 'List' ? styles.calendarIconActive : styles.calendarIcon} onClick={() => setActiveView('List')}>
                            <FaList color={activeView === 'List' ? '#ffffff' : '#858585'} />
                        </div>
                        <div className={activeView === 'Tabular' ? styles.calendarIconActive : styles.calendarIcon} onClick={() => setActiveView('Tabular')}>
                            <CiGrid41 color={activeView === 'Tabular' ? '#ffffff' : '#858585'} />
                        </div>
                        <div className={activeView === 'Calendar' ? styles.calendarIconActive : styles.calendarIcon} onClick={() => setActiveView('Calendar')}>
                            <FaCalendar color={activeView === 'Calendar' ? '#ffffff' : '#858585'} />
                        </div>
                    </div>
                </div>

                <div className={styles.mainBody}>
                    {activeView === "List" && <MESAList days={days} selectedMonth={currentMonthYear?.month} selectedYear={currentMonthYear?.year} />}
                    {activeView === "Tabular" && <MESATabular />}
                    {activeView === "Calendar" && <MESACalendar />}
                </div>
                {isMarkingAttendance && <MESAEntryPopUp isOpen={isMarkingAttendance} />}

                <div className={styles.mainFooter}></div>
            </div>
        </div>
    );
};

export default ManageEachStaffAttendance;
