import React from 'react'
import StarPerformers from '../../Components/StaffManagement/StarPerformers'
import styles from './ManageStaffSummary.module.css'

const ManageStaffSummary = (props) => {
    const { dates } = props
    return (
        <div>
            <StarPerformers
                dates={dates}
            />
        </div>
    )
}

export default ManageStaffSummary