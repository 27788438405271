import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../References/URLs'
import AuthContext from '../../store/auth-context'
import styles from './ManageEachEmployee.module.css'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { MdDeleteOutline, MdEdit, MdOutlineMailOutline, MdOutlinePhone } from "react-icons/md";
import CreateAuthCredentials from '../../Components/StaffManagement/CreateAuthCredentials'
import { toast, ToastContainer } from 'react-toastify'



const ManageEachEmployee = () => {
    const authCtx = useContext(AuthContext)
    const { employeeIdentifier } = useParams()
    const navigate = useNavigate()

    const [staffData, setStaffData] = useState(null)
    const [isAuthCreationOpen, setIsAuthCreationOpen] = useState(false)
    const [hasCopiedToClipboard, setHasCopiedToClipboard] = useState(false)
    const [togglingStatus, setTogglingStatus] = useState(false)
    const [deletingEmployee, setDeletingEmployee] = useState(false)
    const authCreationHandler = () => {
        setIsAuthCreationOpen(false)
    }
    const afterAuthCreation = () => {
        setIsAuthCreationOpen(false)
        toast("Successfully created the credentials!")
        getStaffDetails()
    }

    const getStaffDetails = async () => {
        const getStaffDetailsResponse = await fetch(BASE_URL_API + "getStaffDetails",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx?.token,
                    staffIdentifier: employeeIdentifier
                })
            });

        if (!getStaffDetailsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getStaffDetailsRespo = await getStaffDetailsResponse.json()
            if (getStaffDetailsRespo.status === "success") {
                setStaffData(getStaffDetailsRespo.response)
            } else {
                if (getStaffDetailsRespo.message === "tokenExpired") {
                    authCtx.logout()
                } else {
                    setStaffData(null)
                }
            }
            console.log(getStaffDetailsRespo)
        }
    }

    useEffect(() => {
        getStaffDetails()
    }, [employeeIdentifier])


    const copyToClipboard = () => {
        const textToCopy = `Dear ${staffData?.displayName}, 
                            I'm sharing you the login credentials for our CRM.
                            Use the details below to login into it.
                            URL : https://sm.lokaci.com
                            Username : ${staffData?.username}
                            Password : ${staffData?.password}
                            
                            Thanks`
        navigator.clipboard.writeText(textToCopy);
        setHasCopiedToClipboard(true)
        setTimeout(() => {
            setHasCopiedToClipboard(false)
        }, 4000);
    }

    const toggleStaffLogin = async () => {
        setTogglingStatus(true)
        const toggleStaffLoginResponse = await fetch(BASE_URL_API + "toggleStaffLogin",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    authIdentifier: staffData?.authIdentifier
                })
            });

        if (!toggleStaffLoginResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const toggleStaffLoginRespo = await toggleStaffLoginResponse.json()
            if (toggleStaffLoginRespo.status === "success") {
                toast(`Successfully updated the status`)
                getStaffDetails()
            } else {
                if (toggleStaffLoginRespo.message === "tokenExpired") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(toggleStaffLoginRespo)
        }
        setTogglingStatus(false)
    }

    const deleteStaff = async () => {
        setDeletingEmployee(true)
        const deleteStaffResponse = await fetch(BASE_URL_API + "deleteStaff",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    staffIdentifier: employeeIdentifier
                })
            });

        if (!deleteStaffResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const deleteStaffRespo = await deleteStaffResponse.json()
            if (deleteStaffRespo.status === "success") {
                navigate('/manage/employees', {replace: true})
            } else {
                if (deleteStaffRespo.message === "tokenExpired") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(deleteStaffRespo)
        }
        setDeletingEmployee(false)
    }


    return (
        <div className={styles.mainWrapper}>
            <ToastContainer />
            {isAuthCreationOpen && <CreateAuthCredentials isOpen={isAuthCreationOpen} onClose={authCreationHandler} onSuccess={afterAuthCreation} />}
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <div className={styles.firstWrapper}>
                        <div className={styles.detailsWrapper}>
                            <div className={styles.detailsContainer}>
                                <div className={styles.name}>
                                    <div>
                                        {staffData?.name}
                                    </div>
                                    <div className={styles.editIcon}>
                                        <MdEdit color="#818181" />
                                    </div>
                                </div>
                                <div className={styles.role}>
                                    {staffData?.role}
                                </div>
                                <div className={styles.mobile}>
                                    <MdOutlinePhone size={20} />
                                    {staffData?.mobile}
                                </div>
                                <div className={styles.email}>
                                    <MdOutlineMailOutline size={20} />
                                    {staffData?.email}
                                </div>
                            </div>
                        </div>

                        <div className={styles.authWrapper}>
                            {staffData?.hasAuthCreated === "false" ?
                                <div className={styles.authContainer}>
                                    <div className={styles.notCreatedTitle}>
                                        <h2>
                                            You havn't created Login credentials for this user.
                                        </h2>
                                    </div>
                                    <div className={styles.createBtnWrapper}>
                                        <button className={styles.createBtn} onClick={() => setIsAuthCreationOpen(true)} >Create Auth Credentials</button>
                                    </div>
                                </div>
                                :
                                <div className={styles.authContainer}>
                                    <div className={styles.createdTitle}>
                                        <h2>
                                            You have already created Login credentials for this user.
                                        </h2>
                                    </div>
                                    <div className={styles.tagWrapper}>
                                        {staffData?.isLoginEnabled === "1" ?
                                            <div className={styles.activeTag}>Active</div>
                                            :
                                            <div className={styles.disableTag}>Login Disabled</div>
                                        }
                                    </div>
                                    <div className={styles.credentailsWrapper}>
                                        <div className={styles.displayName}>
                                            <div className={styles.caption}>
                                                Display Name
                                            </div>
                                            <div className={styles.value}>
                                                {staffData?.displayName}
                                            </div>
                                        </div>
                                        <div className={styles.displayName}>
                                            <div className={styles.caption}>
                                                username
                                            </div>
                                            <div className={styles.value}>
                                                {staffData?.username}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.authActionWrapper}>
                                        <div className={styles.authActionContainer}>
                                            <Link to={`/manage-access/${staffData?.authIdentifier}`} className={styles.deactivateBtn} >Manage Access</Link>
                                            {togglingStatus === true ?
                                                <div className={styles.deactivateBtn} >Updating Status...</div>
                                                :
                                                <div className={styles.deactivateBtn} onClick={() => toggleStaffLogin()} >
                                                    {staffData?.isLoginEnabled === "1" ?
                                                        `Disable Login`
                                                        :
                                                        `Enable Login`
                                                    }
                                                </div>
                                            }
                                            {hasCopiedToClipboard === true ?
                                                <div className={styles.credentialsCopied} onClick={() => copyToClipboard()} >Copied To Share</div>
                                                :
                                                <div className={styles.copyCredentials} onClick={() => copyToClipboard()} >Copy To Share</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>

                    </div>

                    <div>
                        <div className={styles.actionWrapper}>
                            {deletingEmployee === false ?
                                <button className={styles.deleteBtn}
                                    onClick={() => deleteStaff()}
                                    title="This will erase all the records related to this worker ever created. This action can't be undone."
                                >
                                    <MdDeleteOutline size={18} />
                                    Delete Employee
                                </button>
                                :
                                <button className={styles.deleteBtn}>
                                    <MdDeleteOutline size={18} />
                                    Deleting All Records...
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManageEachEmployee