import { useState, useEffect, useRef } from "react";
import styles from './StaffSearchableDropDownInline.module.css'

const StaffSearchableDropDownInline = ({ workers, selectedWorker, selectWorkerHandler, placeholder = "Search for the staff name..." }) => {
    console.log("this is at dropdown page : ", selectedWorker?.name)
    const [searchTerm, setSearchTerm] = useState(selectedWorker?.name);
    const [filteredWorkers, setFilteredWorkers] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [highlightIndex, setHighlightIndex] = useState(-1);
    const dropdownRef = useRef(null);

    useEffect(() => {
        setSearchTerm(selectedWorker?.name)
    }, [selectedWorker])
    

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowSuggestions(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    useEffect(() => {
        const debounce = setTimeout(() => {
            if (searchTerm) {
                const filtered = workers.filter((worker) =>
                    worker.name.toLowerCase().includes(searchTerm.toLowerCase())
                ).sort((a, b) => a.name.toLowerCase().indexOf(searchTerm.toLowerCase()) - b.name.toLowerCase().indexOf(searchTerm.toLowerCase()));
                setFilteredWorkers(filtered);
                setShowSuggestions(true);
            } else {
                setShowSuggestions(false);
            }
        }, 300);

        return () => clearTimeout(debounce);
    }, [searchTerm, workers]);

    const handleChange = (event) => {
        setSearchTerm(event.target.value);
        setHighlightIndex(-1);
    };

    const handleSelect = (name) => {
        setSearchTerm(name?.name);
        setShowSuggestions(false);
        selectWorkerHandler(name)
        console.log(name)
    };

    const handleKeyDown = (event) => {
        if (event.key === "ArrowDown") {
            setHighlightIndex((prev) => (prev < filteredWorkers.length - 1 ? prev + 1 : prev));
        } else if (event.key === "ArrowUp") {
            setHighlightIndex((prev) => (prev > 0 ? prev - 1 : prev));
        } else if (event.key === "Enter" && highlightIndex >= 0) {
            // handleSelect(filteredWorkers[highlightIndex].name);
            handleSelect(filteredWorkers[highlightIndex])
        }
    };

    const highlightMatch = (text) => {
        const index = text.toLowerCase().indexOf(searchTerm.toLowerCase());
        if (index !== -1) {
            return (
                <>
                    {text.substring(0, index)}
                    <strong>{text.substring(index, index + searchTerm.length)}</strong>
                    {text.substring(index + searchTerm.length)}
                </>
            );
        }
        return text;
    };


    return (
        <div
            className={styles.mainWrapper} 
            // style={{ position: "relative", width: "200px" }} 
            ref={dropdownRef}
        >
            <input
                type="text"
                value={searchTerm}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                placeholder={placeholder}
                className={styles.inputBox}
            />
            {showSuggestions && (
                <div className={styles.suggestionListWrapper} >
                    {filteredWorkers.length > 0 ? (
                        filteredWorkers.map((item, index) => (
                            <div
                                key={index}
                                onClick={() => handleSelect(item)}
                                style={{
                                    padding: "8px",
                                    cursor: "pointer",
                                    backgroundColor: highlightIndex === index ? "#f0f0f0" : "white",
                                }}
                            >
                                {highlightMatch(item.name)}
                            </div>
                        ))
                    ) : (
                        <div style={{ padding: "8px" }}>No results found</div>
                    )}
                </div>
            )}
        </div>
    );
};

export default StaffSearchableDropDownInline;
