import React, { useEffect, useRef } from 'react';
import styles from './MESAList.module.css';

const MESAList = ({ days, selectedMonth, selectedYear }) => {
    const today = new Date();
    const currentDay = today.getDate();
    const currentMonth = today.toLocaleString('en-US', { month: 'long' }); // 'January', 'February', etc.
    const currentYear = today.getFullYear();
    const currentDayName = today.toLocaleString('en-US', { weekday: 'long' });

    const currentDayRef = useRef(null);
    const listWrapperRef = useRef(null);

    // Check if the selected month & year match today's month & year
    const isCurrentMonthAndYear = selectedMonth === currentMonth && Number(selectedYear) === currentYear;

    useEffect(() => {
        if (isCurrentMonthAndYear && currentDayRef.current && listWrapperRef.current) {
            setTimeout(() => {
                currentDayRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'nearest',
                });
            }, 300);
        }
    }, [days, selectedMonth, selectedYear]); // Ensure effect runs on month/year change

    return (
        <div ref={listWrapperRef} className={styles.listWrapper}>
            {days?.map((item, index) => {
                const isToday = isCurrentMonthAndYear && item.date === currentDay && item.day === currentDayName;

                return (
                    <div
                        key={index}
                        ref={isToday ? currentDayRef : null}
                        className={`${styles.eachDayWrapper} ${isToday ? styles.highlight : ''}`}
                    >
                        <div className={styles.titleWrapper}>
                            <div className={styles.day}>
                                {item?.day?.substr(0, 3)}
                            </div>
                            <div className={styles.date}>
                                {item?.date}
                            </div>
                        </div>
                        <div className={styles.detailWrapper}>
                            <div className={styles.firstInWrapper}>

                            </div>
                            <div className={styles.timelineWrapper}>
                            </div>
                            <div className={styles.lastOutWrapper}>
                            
                            </div>
                            <div className={styles.hoursWorkedWrapper}>
                                <div>00:00</div>
                                <div>Hours Worked</div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default MESAList;
