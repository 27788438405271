import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'lucide-react';
import styles from './CreateCampaignPopup.module.css';

const CreateCampaignPopup = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [campaignName, setCampaignName] = useState('');
    const [error, setError] = useState('');
    const popupRef = useRef(null);

    const openPopup = () => setIsOpen(true);
    const closePopup = () => {
        setIsOpen(false);
        setCampaignName('');
        setError('');
    };

    const handleEscapeKey = (e) => {
        if (e.key === 'Escape') {
            closePopup();
        }
    };

    const handleOutsideClick = (e) => {
        if (popupRef.current && !popupRef.current.contains(e.target)) {
            closePopup();
        }
    };

    const handleSubmit = () => {
        if (!campaignName.trim()) {
            setError('Campaign name cannot be empty.');
        } else if (campaignName.length > 60) {
            setError('Campaign name cannot exceed 60 characters.');
        } else {
            alert(`Campaign "${campaignName}" created successfully!`);
            closePopup();
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('keydown', handleEscapeKey);
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('keydown', handleEscapeKey);
            document.removeEventListener('mousedown', handleOutsideClick);
        }
        return () => {
            document.removeEventListener('keydown', handleEscapeKey);
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isOpen]);

    return (
        <div>
            <button className={styles.createNewCampaign} onClick={openPopup}>
                +
            </button>

            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className={styles.popupOverlay}
                    >
                        <motion.div
                            ref={popupRef}
                            initial={{ scale: 0.8, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            exit={{ scale: 0.8, opacity: 0 }}
                            transition={{ duration: 0.3, type: 'spring' }}
                            className={styles.popupContainer}
                        >
                            <button
                                onClick={closePopup}
                                className={styles.closeButton}
                            >
                                <X size={24} />
                            </button>

                            <h2 className="text-lg font-semibold mb-4">Create New Campaign</h2>

                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    Campaign Name
                                </label>
                                <input
                                    type="text"
                                    value={campaignName}
                                    onChange={(e) => {
                                        setCampaignName(e.target.value);
                                        setError('');
                                    }}
                                    className={styles.inputField}
                                    placeholder="Enter campaign name"
                                />
                                {error && (
                                    <p className={styles.errorMessage}>{error}</p>
                                )}
                            </div>

                            <button
                                onClick={handleSubmit}
                                className={styles.submitButton}
                            >
                                Create Campaign
                            </button>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default CreateCampaignPopup;
