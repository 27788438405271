import React, { useContext, useEffect, useState } from "react";
import { urlToBase64 } from "../../../Helper/utility";
import ActiveStoreContext from "../../../store/ActiveStoreContext";
import styles from "./TallyTheme.module.css";

const TallyTheme = ({ selectedPalette, invoiceDataMaster, themeData }) => {
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return asCtx?.activeStore && asCtx.activeStore.trim() !== "" ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();
    const { meta } = themeData;
    const {
        clientData,
        productData,
        totalAmount,
        tncData,
        customFields,
        invoiceDate,
        dueDate,
    } = invoiceDataMaster;


    ///// final calculations.. 
    const calculateTotalTaxable = () => {
        return invoiceDataMaster?.productData?.reduce((acc, product) => {
            const price = Number(product?.price) || 0; // Ensure price is a number
            const qty = Number(product?.qty) || 1; // Ensure quantity is a number
            const discount = Number(product?.discount) || 0; // Ensure discount is a number
            const discountType = invoiceDataMaster?.discountType || 'value'; // Default to 'value'

            let finalDiscount = 0;

            // Calculate discount based on type
            if (discountType === "percentage") {
                // Calculate percentage discount per unit
                finalDiscount = (price * discount) / 100;
            } else if (discountType === "value") {
                // Fixed discount per unit (not multiplied by quantity)
                finalDiscount = discount;
            } else {
                console.log('error in discount type')
            }

            // Calculate the price after discount for one unit
            const priceAfterDiscount = price - finalDiscount;

            // Ensure priceAfterDiscount is not negative (could be 0 or positive)
            const taxablePrice = priceAfterDiscount * qty;

            // Accumulate the total taxable price
            return acc + Math.max(0, taxablePrice);
        }, 0);
    };

    const calculateTotalTax = () => {
        if (!invoiceDataMaster?.productData?.length) return 0;

        return invoiceDataMaster.productData.reduce((acc, product) => {
            const price = product?.price || 0;
            const qty = product?.qty || 1;
            const discount = product?.discount || 0;
            const discountType = invoiceDataMaster?.discountType || 'value';
            const gstRate = product?.gst || 0;

            // Calculate discounted price based on type
            let discountedPrice = price;
            if (discountType === 'percentage') {
                discountedPrice = price - ((price * discount) / 100);
            } else {
                discountedPrice = price - discount;
            }

            // Ensure discounted price is not negative
            discountedPrice = Math.max(0, discountedPrice);

            // Calculate total taxable amount for this product
            const taxablePrice = discountedPrice * qty;

            // Calculate tax for the product
            const tax = (parseFloat(gstRate) / 100) * taxablePrice;

            return acc + tax;
        }, 0);
    };

    const calculateGrandTotal = () => {
        let grandTotal = calculateTotalTaxable() + calculateTotalTax();

        invoiceDataMaster?.customFields?.forEach((field) => {
            const fieldValue =
                field.calculationType === "percentage"
                    ? (grandTotal * (field.value || 0)) / 100
                    : field.value || 0;
            grandTotal += field.calculationLogic === "add" ? fieldValue : -fieldValue;
        });

        if (invoiceDataMaster?.isRoundingOff) {
            grandTotal = Math.round(grandTotal);
        }

        return grandTotal;
    };

    const convertNumberToText = (amount) => {
        if (isNaN(amount) || amount < 0) return "Invalid amount";

        const units = [
            "", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"
        ];
        const teens = [
            "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen",
            "Seventeen", "Eighteen", "Nineteen"
        ];
        const tens = [
            "", "Ten", "Twenty", "Thirty", "Forty", "Fifty",
            "Sixty", "Seventy", "Eighty", "Ninety"
        ];
        const thousands = [
            "", "Thousand", "Lakh", "Crore"
        ];

        // Split rupees and paise
        const [rupeesPart, paisePart] = amount
            .toFixed(2) // Ensure two decimal places
            .split(".") // Split into rupees and paise
            .map(Number); // Convert strings to numbers

        const convertChunk = (num) => {
            let result = "";

            if (num > 99) {
                result += `${units[Math.floor(num / 100)]} Hundred `;
                num %= 100;
            }

            if (num > 10 && num < 20) {
                result += `${teens[num - 11]} `;
                num = 0;
            } else if (num >= 10) {
                result += `${tens[Math.floor(num / 10)]} `;
                num %= 10;
            }

            if (num > 0) {
                result += `${units[num]} `;
            }

            return result.trim();
        };

        const convertRupees = (num) => {
            let result = "";
            let level = 0;

            while (num > 0) {
                const chunk = num % 1000;

                if (chunk > 0) {
                    result = `${convertChunk(chunk)} ${thousands[level]} ${result}`.trim();
                }

                num = Math.floor(num / 1000);
                level++;
            }

            return result.trim();
        };

        const rupeesText = convertRupees(rupeesPart) || "Zero";
        const paiseText = convertChunk(paisePart);

        if (paisePart > 0) {
            return `${rupeesText} Rupees and ${paiseText} Paise Only`.trim();
        }

        return `${rupeesText} Rupees Only`.trim();
    };

    const [base64Logo, setBase64Logo] = useState(null);
    const fetchBase64Logo = async () => {
        try {
            const base64 = await urlToBase64(
                activeStore?.invoiceLogo
            );
            setBase64Logo(base64);
        } catch (error) {
            console.error("Error converting image to base64:", error);
        }
    };

    useEffect(() => {
        fetchBase64Logo();
    }, []);


    return (
        <div
            className={styles.tallyWrapper}
            style={{
                backgroundColor: selectedPalette?.backgroundColor || "#ffffff",
                color: selectedPalette?.textColor || "#000000",
            }}
        >
            {/* Header */}
            <header className={styles.header}>
                {base64Logo ? (
                    <img
                        src={base64Logo}
                        alt="Salon Logo"
                        className={styles.logo}
                        id="logo"
                    />
                ) : (
                    <p>Loading logo...</p>
                )}
                <div className={styles.companyDetails}>
                    <div className={styles.invoiceStoreMeta}>
                        <h2>{activeStore?.storeName || "Company Name"}</h2>
                        <div className={styles.storeAddress}>
                            {activeStore?.storeAddress || "123 Business Street"}
                        </div>
                    </div>
                    <div className={styles.invoiceMetaData}>
                        <div className={styles.invoiceNumber}>#{invoiceDataMaster?.invoiceNumber || "12345"}</div>
                        <div>
                            Date: {invoiceDate || "N/A"}<br />
                            Due: {dueDate || "N/A"}
                        </div>
                    </div>
                </div>
            </header>

            {/* Customer Info */}
            <section className={styles.customerInfo}>
                <h3>Bill To:</h3>
                <p>
                    {clientData?.client_name || "Customer Name"}<br />
                    {clientData?.mobile || "Phone Number"}<br />
                </p>
            </section>

            {/* Line Items */}
            <section className={styles.lineItems}>
                <table>
                    <thead
                        style={{
                            backgroundColor: selectedPalette.tableHeaderColor,
                            color: selectedPalette.tableHeaderTextColor,
                        }}
                    >
                        <tr>
                            <th>#</th>
                            <th>Item</th>
                            <th>Qty.</th>
                            <th>Price</th>
                            <th>Dis.({invoiceDataMaster?.discountType === "percentage" ? `%` : `₹`}) </th>
                            <th>GST</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody
                        style={{
                            backgroundColor: selectedPalette.tableBodyBackgroundColor,
                            color: selectedPalette.tableBodyTextColor,
                        }}
                    >
                        {productData?.length > 0 ? (
                            productData.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item?.productTitle}</td>
                                    <td>{item?.qty}</td>
                                    <td>₹{typeof item?.price === "number" ? item.price.toFixed(2) : "0.00"}</td>
                                    <td>{item?.discount}</td>
                                    <td>{item?.gst}</td>
                                    <td>₹{(item?.total)?.toFixed(2)}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="4" style={{ textAlign: "center" }}>
                                    No items to display.
                                </td>
                            </tr>
                        )}
                        <tr>
                            <td colSpan="6">Total Taxable</td>
                            <td>₹{calculateTotalTaxable()?.toFixed(2) || "0.00"}</td>
                        </tr>
                        <tr>
                            <td colSpan="6">Total Tax</td>
                            <td>₹{calculateTotalTax()?.toFixed(2) || "0.00"}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="6">Grand Total</td>
                            <td>₹{calculateGrandTotal()?.toFixed(2) || "0.00"}</td>
                        </tr>
                    </tfoot>
                </table>
            </section>

            <section className={styles.paymentCollectionWrapper}>
                <div className={styles.pcwSpace}>

                </div>
                <div className={styles.pcwContent}>
                    <table>
                        <tbody>
                            {invoiceDataMaster?.collectionData?.map((itemCollection, indexCollection) => (
                                <tr key={indexCollection} className={styles.eachCollection} >
                                    <td>
                                        {itemCollection?.mode?.replace("collectionVia", 'Collection Via ')}
                                    </td>
                                    <td className={styles.pcwAmount}>
                                        ₹{itemCollection?.amount}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    
                    <div
                        className={`${styles.paymentStatus} ${invoiceDataMaster?.remainingAmount < 0
                                ? styles.advance
                                : invoiceDataMaster?.remainingAmount === 0
                                    ? styles.neutral
                                    : styles.remaining
                            }`}
                    >
                        <div>
                            {invoiceDataMaster?.remainingAmount < 0
                                ? "Advance Payment"
                                : invoiceDataMaster?.remainingAmount === 0
                                    ? "No Due"
                                    : "Remaining Payment"}
                        </div>
                        <div>
                            ₹
                            {Math.abs(invoiceDataMaster?.remainingAmount).toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </div>
                    </div>

                </div>
            </section>

            {/* Footer */}
            <footer className={styles.footer}>
                <p>Thank you for your visit!</p>
                {/* {tncData && <p>Terms & Conditions: {tncData}</p>} */}
                {customFields?.paymentMethods && (
                    <p>
                        Payment Methods:{" "}
                        {customFields?.paymentMethods?.join(", ") || "N/A"}
                    </p>
                )}
            </footer>
        </div>
    );
};

export default TallyTheme;
