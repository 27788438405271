import React, { useState, useEffect, useContext } from 'react';
import styles from './ComAllAudiences.module.css';
import { motion } from 'framer-motion';
import { FaFilter, FaSort, FaSearch, FaSync, FaChevronDown } from 'react-icons/fa';
import CampaignPreviewModal from '../../Components/Communications/Campaigns/CampaignPreviewModal';
import { Link } from 'react-router-dom'
import { BASE_URL_API } from '../../References/URLs';
import AuthContext from '../../store/auth-context';
import ActiveStoreContext from '../../store/ActiveStoreContext';
import AudiencePreviewModal from '../../Components/Communications/Campaigns/AudiencePreviewModal';

// const campaignsData = [
//     { id: 1, name: 'New Year Campaign', date: '2025-01-01', status: 'Active', type: 'WhatsApp', reach: 10000, clicks: 1200, budget: 500, performance: 'Good' },
//     { id: 2, name: 'Summer Sale', date: '2025-06-15', status: 'Completed', type: 'SMS', reach: 5000, clicks: 800, budget: 300, performance: 'Excellent' },
//     { id: 3, name: 'Black Friday', date: '2024-11-25', status: 'Active', type: 'WhatsApp', reach: 20000, clicks: 1500, budget: 800, performance: 'Fair' },
//     { id: 3, name: 'Black Friday', date: '2024-11-25', status: 'Active', type: 'WhatsApp', reach: 20000, clicks: 1500, budget: 800, performance: 'Fair' },
//     { id: 3, name: 'Black Friday', date: '2024-11-25', status: 'Active', type: 'WhatsApp', reach: 20000, clicks: 1500, budget: 800, performance: 'Fair' },
//     { id: 3, name: 'Black Friday', date: '2024-11-25', status: 'Active', type: 'WhatsApp', reach: 20000, clicks: 1500, budget: 800, performance: 'Fair' },
//     { id: 3, name: 'Black Friday', date: '2024-11-25', status: 'Active', type: 'WhatsApp', reach: 20000, clicks: 1500, budget: 800, performance: 'Fair' },
//     { id: 3, name: 'Black Friday', date: '2024-11-25', status: 'Active', type: 'WhatsApp', reach: 20000, clicks: 1500, budget: 800, performance: 'Fair' },
//     { id: 3, name: 'Black Friday', date: '2024-11-25', status: 'Active', type: 'WhatsApp', reach: 20000, clicks: 1500, budget: 800, performance: 'Fair' },
//     { id: 3, name: 'Black Friday', date: '2024-11-25', status: 'Active', type: 'WhatsApp', reach: 20000, clicks: 1500, budget: 800, performance: 'Fair' },
//     { id: 3, name: 'Black Friday', date: '2024-11-25', status: 'Active', type: 'WhatsApp', reach: 20000, clicks: 1500, budget: 800, performance: 'Fair' },
//     { id: 3, name: 'Black Friday', date: '2024-11-25', status: 'Active', type: 'WhatsApp', reach: 20000, clicks: 1500, budget: 800, performance: 'Fair' },
//     { id: 3, name: 'Black Friday', date: '2024-11-25', status: 'Active', type: 'WhatsApp', reach: 20000, clicks: 1500, budget: 800, performance: 'Fair' },
//     { id: 3, name: 'Black Friday', date: '2024-11-25', status: 'Active', type: 'WhatsApp', reach: 20000, clicks: 1500, budget: 800, performance: 'Fair' },
//     { id: 3, name: 'Black Friday', date: '2024-11-25', status: 'Active', type: 'WhatsApp', reach: 20000, clicks: 1500, budget: 800, performance: 'Fair' },
//     // Add more sample campaigns
// ];

const ComAllAudiences = () => {
    const [audienceData, setAudienceData] = useState([])
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext);
    const activeStore = (() => { try { return asCtx?.activeStore && asCtx.activeStore.trim() !== "" ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [sortBy, setSortBy] = useState('');
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [filters, setFilters] = useState({ status: '', type: '', date: '' });

    useEffect(() => {
        setLoading(true);
        const timeout = setTimeout(() => setLoading(false), 100);
        return () => clearTimeout(timeout);
    }, [searchQuery, sortBy, sortOrder, filters, currentPage, rowsPerPage]);

    const [selectedCampaign, setSelectedCampaign] = useState(null)
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenPreview = (campaign) => {
        setSelectedCampaign(campaign)
        
        setIsModalOpen(true)
    };

    const handleClosePreview = () => {
        setSelectedCampaign(null);
        setIsModalOpen(false);
    };

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
        const query = e.target.value.toLowerCase();
        const filtered = audienceData?.filter((campaign) =>
            campaign.name.toLowerCase().includes(query)
        );
        setFilteredData(filtered);
    };

    const handleSort = (field) => {
        const newSortOrder = sortBy === field && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortBy(field);
        setSortOrder(newSortOrder);

        const sorted = [...filteredData].sort((a, b) =>
            newSortOrder === 'asc' ? (a[field] > b[field] ? 1 : -1) : (a[field] < b[field] ? 1 : -1)
        );
        setFilteredData(sorted);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (e) => {
        setRowsPerPage(Number(e.target.value));
    };

    const handleFilterChange = (key, value) => {
        setFilters({ ...filters, [key]: value });
    };

    const paginatedData = filteredData.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    const exportToCSV = () => {
        const headers = ['Name', 'Date', 'Status', 'Type', 'Reach', 'Clicks', 'Budget', 'Performance'];
        const rows = audienceData.map((audience) => [
            audience.audienceName,
            audience.date,
            audience.status,
            audience.type,
            audience.reach,
            audience.clicks,
            `$${audience.budget}`,
            audience.performance,
        ]);

        let csvContent = "data:text/csv;charset=utf-8," + [headers, ...rows].map(e => e.join(",")).join("\n");
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "campaigns.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const toggleStatus = (id) => {
        setFilteredData((prevData) =>
            prevData.map((campaign) =>
                campaign.id === id
                    ? { ...campaign, status: campaign.status === 'Active' ? 'Inactive' : 'Active' }
                    : campaign
            )
        );
    };


    const getAllAudiences = async () => {
        const getAllAudiencesResponse = await fetch(BASE_URL_API + "getAllAudiences", 
        {
            method: "POST",
            body: JSON.stringify({
                token: authCtx.token,
                storeIdentifier: activeStore?.storeIdentifier,
            }),
        });

        if (!getAllAudiencesResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const getAllAudiencesRespo = await getAllAudiencesResponse.json();
            if (getAllAudiencesRespo.status === "success") {
                setAudienceData(getAllAudiencesRespo?.response)
                setFilteredData(getAllAudiencesRespo?.response)
            } else if (getAllAudiencesRespo.message === "tokenExpired") {
                authCtx.logout();
            } else {
                setAudienceData([])
                setFilteredData([])
            }
            console.log(getAllAudiencesRespo);
        }
    };

    useEffect(() => {
        getAllAudiences()
    }, [])
    

    return (
        <motion.div
            className={styles.container}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
        >

            {/* Audience Preview Modal */}
            <AudiencePreviewModal
                isOpen={isModalOpen}
                onClose={handleClosePreview}
                campaign={selectedCampaign}
            />
            <header className={styles.header}>
                <div>All Audiences</div>
                <div className={styles.searchFilter}>
                    <div className={styles.searchBar}>
                        <FaSearch className={styles.icon} />
                        <input
                            type="text"
                            placeholder="Search campaigns..."
                            value={searchQuery}
                            onChange={handleSearch}
                        />
                    </div>
                    <div className={styles.filters}>
                        <select onChange={(e) => handleFilterChange('status', e.target.value)}>
                            <option value="">Status</option>
                            <option value="Active">Active</option>
                            <option value="Completed">Completed</option>
                        </select>
                        <select onChange={(e) => handleFilterChange('type', e.target.value)}>
                            <option value="">Type</option>
                            <option value="WhatsApp">WhatsApp</option>
                            <option value="SMS">SMS</option>
                        </select>
                        <select onChange={(e) => handleFilterChange('date', e.target.value)}>
                            <option value="">Date</option>
                            <option value="Last 7 Days">Last 7 Days</option>
                            <option value="Last 30 Days">Last 30 Days</option>
                        </select>
                        <button className={styles.exportBtn} onClick={exportToCSV}>Export to CSV</button>
                        <Link className={styles.createNewCampaign} to={`/communications/audience/create-new`}>+</Link>
                    </div>
                </div>
            </header>
            {loading ? (
                <div className={styles.loader}>
                    <FaSync className={styles.loaderIcon} />
                    <p>Loading campaigns...</p>
                </div>
            ) : (
                <>
                    <div className={styles.table}>
                        <div className={styles.tableHeader}>
                            <div onClick={() => handleSort('name')}>
                                Name <FaSort className={styles.sortIcon} />
                            </div>
                            <div onClick={() => handleSort('date')}>
                                Date <FaSort className={styles.sortIcon} />
                            </div>
                            <div>Status</div>
                            <div>Type</div>
                            <div>Reach</div>
                            <div>Clicks</div>
                            <div>Budget</div>
                            <div>Performance</div>
                            <div>Action</div>
                        </div>
                        <div className={styles.tableBody}>
                            {paginatedData.map((audience) => (
                                <motion.div
                                    className={styles.tableRow}
                                    key={audience.audienceIdentifier}
                                    whileHover={{ scale: 1.006 }}
                                >
                                    <div className={styles.campaignName}>{audience.audienceName}</div>
                                    <div>{audience.createdOn}</div>
                                    <div>{audience.status}
                                        <button
                                            className={styles.toggleBtn}
                                            onClick={() => toggleStatus(audience?.audienceIdentifier)}
                                        >
                                            {audience.status === 'Active' ? 'Deactivate' : 'Activate'}
                                        </button>
                                    </div>
                                    <div>{audience.audienceDescription}</div>
                                    <div>{audience?.audienceData?.length}</div>
                                    <div>{audience.clicks}</div>
                                    <div>${audience.budget}</div>
                                    <div>{audience.performance}</div>
                                    <div className={styles.cell}>
                                        <button
                                            className={styles.previewBtn}
                                            onClick={() => handleOpenPreview(audience)}
                                        >
                                            Preview
                                        </button>
                                        <Link to={`/communications/campaign/${audience?.audienceIdentifier}`}>
                                            View
                                        </Link>
                                        
                                    </div>
                                </motion.div>
                            ))}
                        </div>
                    </div>
                    <div className={styles.paginationControls}>
                        <select value={rowsPerPage} onChange={handleRowsPerPageChange}>
                            <option value="5">5 rows per page</option>
                            <option value="10">10 rows per page</option>
                            <option value="15">15 rows per page</option>
                            <option value="50">50 rows per page</option>
                        </select>
                        <div className={styles.pagination}>
                            {Array.from(
                                { length: Math.ceil(filteredData.length / rowsPerPage) },
                                (_, i) => (
                                    <button
                                        key={i}
                                        className={`${styles.pageButton} ${currentPage === i + 1 ? styles.activePage : ''
                                            }`}
                                        onClick={() => handlePageChange(i + 1)}
                                    >
                                        {i + 1}
                                    </button>
                                )
                            )}
                        </div>
                    </div>
                </>
            )}
        </motion.div>
    );
};

export default ComAllAudiences;
