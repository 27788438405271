import React, { useContext, useEffect, useState } from 'react'
import styles from './EventCalendar.module.css'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment'
import ActiveStoreContext from '../../store/ActiveStoreContext';
import StoresHeader from '../../Components/Stores/StoresHeader';
import NewFutureBooking from './NewFutureBooking';
import { toast, ToastContainer } from 'react-toastify';
import { BASE_URL_API } from '../../References/URLs';
import AuthContext from '../../store/auth-context';
import ViewFutureBooking from './ViewFutureBooking';

const localizer = momentLocalizer(moment)

const EventCalendar = () => {
    const authCtx = useContext(AuthContext);
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();

    const [activeSlotInfo, setActiveSlotInfo] = useState('')
    const [isNewBookingOpen, setIsNewBookingOpen] = useState(false)
    const [isViewBookingOpen, setIsViewBookingOpen] = useState(false)
    const [isLoadingEvent, setIsLoadingEvent] = useState(false)
    const [activeViewBooking, setActiveViewBooking] = useState(null)
    const [myEventsList, setMyEventsList] = useState([])

    const handleEventClick = (event) => {
        // Add your custom logic here
        setIsViewBookingOpen(true)
        setActiveViewBooking(event)
    };

    const handleSlotClick = (slotInfo) => {
        console.log(slotInfo)
        if (moment(slotInfo?.start).isBefore(moment().startOf('day'))) {
            alert('You cannot create events in the past!');
            return;
        }
        setIsNewBookingOpen(true)
        setActiveSlotInfo(slotInfo)
    };

    const postEventAddition = () => {
        toast(`Event created Successfully!`)
        setIsNewBookingOpen(false)
        getReservations()
    }

    const onDeletionReservation = () => {
        toast(`Successfully deleted the reservation!`)
        setIsViewBookingOpen(false)
        getReservations()
    }

    const getReservations = async () => {
        setIsLoadingEvent(true)
        const getReservationsResponse = await fetch(BASE_URL_API + "getReservations",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx?.token,
                    storeIdentifier: activeStore?.storeIdentifier
                })
            });

        if (!getReservationsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getReservationsRespo = await getReservationsResponse.json()
            if (getReservationsRespo?.status === "success") {
                setMyEventsList(transformEvents(getReservationsRespo?.response))
            } else {
                if (getReservationsRespo.message === "tokenExpired") {
                    authCtx.logout()
                } else {
                    console.log(getReservationsRespo)
                }
            }
        }
        setIsLoadingEvent(false)
    }

    useEffect(() => {
        getReservations()
    }, [activeStore?.storeIdentifier])

    const transformEvents = (inputArray) => {
        return inputArray.map((event, index) => {
            const startDate = new Date(event.start.replace(' ', 'T'));
            const endDate = event.end === "0000-00-00 00:00:00"
                ? new Date(startDate.getTime() + 23 * 60 * 60 * 1000)
                : new Date(event.end.replace(' ', 'T'));

            const allDay = event.end === "0000-00-00 00:00:00";

            // Dynamically assigning colors based on client name or event details
            let color = '#FF5733'; // Default color
            if (event.clientName) {
                // Example: Assign different colors based on the client
                switch (event.clientName.toLowerCase()) {
                    case 'john':
                        color = '#FF5733';
                        break;
                    case 'sarah':
                        color = '#33FF57';
                        break;
                    case 'alex':
                        color = '#3357FF';
                        break;
                    default:
                        color = '#FF6347'; // Default color for others
                }
            }

            return {
                id: index,
                eventIdentifier: event?.eventIdentifier,
                title: event.title || 'Untitled Event',
                allDay: allDay,
                start: startDate,
                end: endDate,
                servicesNotes: event.servicesNotes,
                remarks: event.remarks,
                clientName: event.clientName,
                color: color // Add the color property
            };
        });
    }

    const eventStyleGetter = (event) => {
        // Apply the color dynamically to each event
        const backgroundColor = event.color || '#000000'; // Default to black if no color is provided
        return {
            style: {
                backgroundColor,
                borderRadius: '5px',
                opacity: 0.8,
                color: 'white',
                border: 'none'
            }
        };
    };

    if (activeStore === null) {
        return (
            <div className={styles.mainWrapper}>
                <StoresHeader />
                <div className={styles.mainContainer}>
                    <div className={styles.mainContent}>
                        <div className={styles.mainHeader}>
                            Please select a store!
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={styles.mainWrapper}>
            <ToastContainer />
            <div className={styles.mainContainer}>
                <div className={styles.actionWrapper}>
                    <button className={styles.newBookingBtn} onClick={() => setIsNewBookingOpen(true)} >New Booking+</button>
                </div>
                <Calendar
                    localizer={localizer}
                    events={myEventsList}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 600 }}
                    onSelectEvent={handleEventClick}
                    selectable
                    onSelectSlot={handleSlotClick}
                    eventPropGetter={eventStyleGetter} // Use eventStyleGetter to apply color
                />
            </div>
            {isNewBookingOpen && <NewFutureBooking isOpen={isNewBookingOpen} onClose={() => setIsNewBookingOpen(false)} onSuccess={() => postEventAddition()} slotInfo={activeSlotInfo} />}
            {isViewBookingOpen && <ViewFutureBooking isOpen={isViewBookingOpen} onClose={() => setIsViewBookingOpen(false)} onDeletionReservation={() => onDeletionReservation()} item={activeViewBooking} />}
        </div>
    )
}

export default EventCalendar
