import React, { useState, useRef } from 'react';
import styles from './MESAEntryPopUp.module.css';

const MESAEntryPopUp = ({ isOpen }) => {
    const [isFormOpen, setIsFormOpen] = useState(false);
    const inputRefs = useRef({});

    const [entry, setEntry] = useState({
        checkInHour: '',
        checkInMinute: '',
        checkInPeriod: 'AM',
        checkOutHour: '',
        checkOutMinute: '',
        checkOutPeriod: 'AM'
    });

    const handleChange = (e) => {
        let { name, value } = e.target;
        value = value.replace(/[^0-9]/g, ''); // Allow only numbers

        if (name.includes('Hour')) {
            if (value !== '' && (parseInt(value, 10) < 1 || parseInt(value, 10) > 12)) return;
        }

        if (name.includes('Minute')) {
            if (value !== '' && (parseInt(value, 10) < 0 || parseInt(value, 10) > 59)) return;
        }

        setEntry((prev) => ({ ...prev, [name]: value }));
    };

    const handleBlur = (e) => {
        let { name, value } = e.target;
        if (value.length === 1) value = `0${value}`;
        setEntry((prev) => ({ ...prev, [name]: value }));
    };

    const handleKeyDown = (e, field) => {
        const fields = ['checkInHour', 'checkInMinute', 'checkOutHour', 'checkOutMinute'];
        const currentIndex = fields.indexOf(field);

        if (e.key === 'ArrowRight' && currentIndex < fields.length - 1) {
            inputRefs.current[fields[currentIndex + 1]].focus();
        }
        if (e.key === 'ArrowLeft' && currentIndex > 0) {
            inputRefs.current[fields[currentIndex - 1]].focus();
        }
        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            setEntry((prev) => {
                let newValue = parseInt(prev[field] || '00', 10) || 0;
                let min = field.includes('Hour') ? 1 : 0;
                let max = field.includes('Hour') ? 12 : 59;

                newValue = e.key === 'ArrowUp' ? (newValue >= max ? min : newValue + 1) : (newValue <= min ? max : newValue - 1);

                return { ...prev, [field]: newValue < 10 ? `0${newValue}` : `${newValue}` };
            });
        }
    };

    if (!isOpen) return null;

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.contentWrapper}>
                    <div className={styles.mainContent}>
                        <div className={styles.headerWrapper}>Sun, 09 Feb 2025</div>
                        <div className={styles.addEntryWrapper}>
                            {isFormOpen ? (
                                <div className={styles.addEntryContainer}>
                                    <div className={styles.entryFormWrapper}>
                                        {['checkIn', 'checkOut'].map((type) => (
                                            <div key={type} className={styles.eachEntryWrapper}>
                                                <input
                                                    type="text"
                                                    name={`${type}Hour`}
                                                    placeholder="HH"
                                                    value={entry[`${type}Hour`]}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    onKeyDown={(e) => handleKeyDown(e, `${type}Hour`)}
                                                    ref={(el) => (inputRefs.current[`${type}Hour`] = el)}
                                                    className={styles.activeInput}
                                                />
                                                :
                                                <input
                                                    type="text"
                                                    name={`${type}Minute`}
                                                    placeholder="MM"
                                                    value={entry[`${type}Minute`]}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    onKeyDown={(e) => handleKeyDown(e, `${type}Minute`)}
                                                    ref={(el) => (inputRefs.current[`${type}Minute`] = el)}
                                                    className={styles.activeInput}
                                                />
                                                <select name={`${type}Period`} value={entry[`${type}Period`]} onChange={handleChange}>
                                                    <option value="AM">AM</option>
                                                    <option value="PM">PM</option>
                                                </select>
                                            </div>
                                        ))}
                                    </div>
                                    <div className={styles.entryActionBtnWrapper}>
                                        <button className={styles.saveBtn}>Save</button>
                                        <button className={styles.cancelBtn} onClick={() => setIsFormOpen(false)}>Cancel</button>
                                    </div>
                                </div>
                            ) : (
                                <div onClick={() => setIsFormOpen(true)}>Add Entry</div>
                            )}
                        </div>
                        <div className={styles.entriesWrapper}>Check-in & Check-out entry</div>
                        <div className={styles.footerWrapper}>
                            <div className={styles.borderWrapper}>
                                {['checkIn', 'checkOut'].map((type) => (
                                    <div key={type} className={styles[`${type}Wrapper`]}>
                                        <div className={styles.titleCaption}>{type === 'checkIn' ? 'First Check-In' : 'Last Check-Out'}</div>
                                        <div className={styles.titleValue}>{entry[`${type}Hour`] || '00'}:{entry[`${type}Minute`] || '00'}</div>
                                    </div>
                                ))}
                            </div>
                            <div className={styles.totalWrapper}>
                                <div className={styles.titleCaption}>Total Hours</div>
                                <div className={styles.titleValue}>00:01</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MESAEntryPopUp;
