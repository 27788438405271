import React, { useState } from 'react';
import { IoChevronDownOutline, IoChevronUpOutline } from 'react-icons/io5';
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md';
import { motion, AnimatePresence } from 'framer-motion';
import styles from './EachAudienceCategory.module.css';

const EachAudienceCategory = (props) => {
    const { item, currentlySelectedSubCategory, subCategorySelectHandler } = props
    const [isOpen, setIsOpen] = useState(false)

    return (
        <div className={styles.mainWrapper}>
            {/* Header with toggle */}
            <div 
                className={styles.headerWrapper} 
                onClick={() => setIsOpen(!isOpen)}
                title={isOpen ? "Collapse" : "Expand"}
            >
                <div className={styles.categoryTitle}>
                    {item?.category}
                </div>
                <motion.div
                    initial={{ rotate: isOpen ? 0 : 180 }}
                    animate={{ rotate: isOpen ? 180 : 0 }}
                    transition={{ duration: 0.3 }}
                >
                    {isOpen ? <IoChevronDownOutline size={20} /> : <IoChevronUpOutline size={20} />}
                </motion.div>
            </div>

            {/* Subcategories */}
            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        className={styles.audienceCategory}
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: "auto", opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.3, ease: "easeInOut" }}
                    >
                        {item?.audiences?.map((itemInner, indexInner) => (
                            <motion.div
                                key={indexInner}
                                className={styles.subCategory}
                                onClick={() => subCategorySelectHandler(itemInner)}
                                whileHover={{ scale: 1.01}}
                                whileTap={{ scale: 0.95 }}
                                transition={{ duration: 0.1 }}
                            >
                                {currentlySelectedSubCategory?.audienceIdentifier === itemInner?.audienceIdentifier ? (
                                    <MdRadioButtonChecked title="Selected" className={styles.radioButtonIcon} />
                                ) : (
                                    <MdRadioButtonUnchecked title="Select Audience" className={styles.radioButtonIcon} />
                                )}
                                <span className={styles.audienceName}>{itemInner?.audienceName}</span>
                            </motion.div>
                        ))}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default EachAudienceCategory;
