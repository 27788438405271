import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaTimes } from 'react-icons/fa';
import styles from './AudiencePreviewModal.module.css';

const AudiencePreviewModal = ({ isOpen, onClose, campaign }) => {
    useEffect(() => {
        const handleEscape = (e) => {
            if (e.key === 'Escape') {
                onClose();
            }
        };
        document.addEventListener('keydown', handleEscape);
        return () => document.removeEventListener('keydown', handleEscape);
    }, [onClose]);

    if (!isOpen) return null;

    return (
        <div className={styles.overlay} onClick={onClose}>
            <motion.div
                className={styles.modal}
                onClick={(e) => e.stopPropagation()}
                initial={{ x: '100%' }}
                animate={{ x: '20%' }}
                exit={{ x: '100%' }}
                transition={{ duration: 0.5 }}
            >
                <div className={styles.header}>
                    <h2>{campaign.name}</h2>
                    <button onClick={onClose} className={styles.closeBtn}>
                        <FaTimes />
                    </button>
                </div>
                <div className={styles.content}>
                    <p><strong>Date:</strong> {campaign.date}</p>
                    <p>
                        <strong>Status:</strong>
                        {/* <span className={`${styles.status} ${styles[campaign?.status?.toLowerCase()]}`}>
                            {campaign.status}
                        </span> */}
                    </p>
                    <p><strong>Type:</strong> {campaign?.type}</p>
                    <p><strong>Reach:</strong> {campaign?.reach}</p>
                    <p><strong>Clicks:</strong> {campaign?.clicks}</p>
                    <p><strong>Budget:</strong> ${campaign?.budget}</p>
                    <p>
                        <strong>Performance:</strong>
                        {/* <span className={`${styles.performance} ${styles[campaign?.performance?.toLowerCase()]}`}>
                            {campaign?.performance}
                        </span> */}
                    </p>
                    <button className={styles.toggleBtn}>
                        {campaign?.status === 'Active' ? 'Deactivate' : 'Activate'}
                    </button>
                </div>
            </motion.div>
        </div>
    );
};

export default AudiencePreviewModal;
