import React, { useState } from 'react'
import ManageEachStaffAttendance from './ManageEachStaffAttendance'
import ManageEachStaffHeader from './ManageEachStaffHeader'
import ManageEachStaffProfile from './ManageEachStaffProfile'
import ManageEachStaffWork from './ManageEachStaffWork'
import styles from './ManageEachStaffWrapper.module.css'

const ManageEachStaffWrapper = props => {
    const { selectedStaff, dates, activeTab } = props

    return (
        <div className={styles.mainWrapper}>
            <ManageEachStaffHeader activeTab={activeTab} />
            {activeTab==='profile' &&  <ManageEachStaffProfile  />}
            {activeTab==='attendance' &&  <ManageEachStaffAttendance dates={dates} selectedStaff={selectedStaff}  />}
            {activeTab==='work' &&  <ManageEachStaffWork dates={dates}  />}
        </div>
    )
}

export default ManageEachStaffWrapper