import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useParams } from "react-router";
import { FaUser, FaSearch, FaListAlt } from "react-icons/fa";
import styles from "./StaffSelectorInManageStaff.module.css";

const StaffSelectorInManageStaff = ({ workers }) => {
    const [searchKey, setSearchKey] = useState("");
    const [filteredWorkers, setFilteredWorkers] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const navigate = useNavigate();
    const inputRef = useRef(null);
    const listRef = useRef(null);
    const { activeTab }  = useParams()

    useEffect(() => {
        const filtered = workers.filter(worker =>
            worker.name.toLowerCase().includes(searchKey.toLowerCase())
        );
        setFilteredWorkers([{ id: "summary", name: "Summary" }, ...filtered]);
    }, [searchKey, workers]);

    const handleKeyDown = (e) => {
        if (!isOpen || filteredWorkers.length === 0) return;

        if (e.key === "ArrowDown") {
            setSelectedIndex((prev) => (prev < filteredWorkers.length - 1 ? prev + 1 : 0));
        } else if (e.key === "ArrowUp") {
            setSelectedIndex((prev) => (prev > 0 ? prev - 1 : filteredWorkers.length - 1));
        } else if (e.key === "Enter" && selectedIndex !== -1) {
            selectStaff(filteredWorkers[selectedIndex]);
        }
    };

    const selectStaff = (staff) => {
        if (staff.id === "summary") {
            navigate("/staff/summary");
        } else {
            // navigate(`/staff/${staff.id}/${activeTab}`)
            navigate(`/staff/${staff.id}${activeTab ? `/${activeTab}` : ""}`)
        }
        setSearchKey(staff.name);
        setIsOpen(false);
    };

    const handleClickOutside = (e) => {
        if (
            inputRef.current && !inputRef.current.contains(e.target) &&
            listRef.current && !listRef.current.contains(e.target)
        ) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.inputContainer}>
                <FaSearch className={styles.inputIcon} />
                <input
                    ref={inputRef}
                    className={styles.inputField}
                    placeholder="Search for a Staff..."
                    value={searchKey}
                    onChange={(e) => {
                        setSearchKey(e.target.value);
                        setIsOpen(true);
                    }}
                    onFocus={() => setIsOpen(true)}
                    onKeyDown={handleKeyDown}
                />
            </div>
            {isOpen && filteredWorkers.length > 0 && (
                <motion.div
                    ref={listRef}
                    className={styles.dropdown}
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.2 }}
                >
                    {filteredWorkers.map((item, index) => (
                        <div
                            key={index}
                            onClick={() => selectStaff(item)}
                            className={`${styles.dropdownItem} ${selectedIndex === index ? styles.selected : ""}`}
                        >
                            {item.id === "summary" ? (
                                <FaListAlt className={styles.staffIcon} />
                            ) : (
                                <FaUser className={styles.staffIcon} />
                            )}
                            {item.name}
                        </div>
                    ))}
                </motion.div>
            )}
        </div>
    );
};

export default StaffSelectorInManageStaff;