import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../References/URLs'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router'
import AuthContext from '../../store/auth-context'
import styles from './ManageEachStaffWork.module.css'
import NumericCounter from '../UI/NumericCounter'
import ManageEachStaffWorkBookingData from './ManageEachStaffWorkBookingData'
import ActiveStoreContext from '../../store/ActiveStoreContext'

const ManageEachStaffWork = (props) => {
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();

    const { dates, selectedStaff } = props
    const { staffIdentifier } = useParams()
    
    const [isFetchingIncentive, setIsFetchingIncentive] = useState(true)

    const [workDetails, setWorkDetails] = useState(null)
    const [salary, setSalary] = useState(31000)
    const [stateValue, setStateValue] = useState(0)

    

    const getStaffIncentive = async () => {
        setIsFetchingIncentive(true)
        const getStaffIncentiveResponse = await fetch(BASE_URL_API + "getStaffIncentive",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore.storeIdentifier,
                    staffIdentifier: staffIdentifier,
                    startDate: dates.startDate,
                    endDate: dates.endDate
                })
            });

        if (!getStaffIncentiveResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getStaffIncentiveRespo = await getStaffIncentiveResponse.json()
            if (getStaffIncentiveRespo.status === 'success') {
                setWorkDetails(getStaffIncentiveRespo.response)
            } else {
                if (getStaffIncentiveRespo.message === 'tokenExpired') {
                    authCtx.logout()
                } else {
                    setWorkDetails(null)
                }
                console.log(getStaffIncentiveRespo)
            }
        }
        setIsFetchingIncentive(false)
    }



    useEffect(() => {
        setStateValue(0)
        if (staffIdentifier !== undefined) {
            getStaffIncentive().then(() => setStateValue(workDetails?.totalAmount))
        }
    }, [staffIdentifier, dates])


    const incentiveCalculator = () => {
        let incentive = 0;
        const factor = (workDetails?.totalAmount) / salary;
        if (parseInt(factor) === 2) {
            incentive = ((workDetails?.totalAmount) * (5 / 100));
        } else if (parseInt(factor) === 3) {
            incentive = ((workDetails?.totalAmount) * (7 / 100));
        } else if (parseInt(factor) === 4) {
            incentive = ((workDetails?.totalAmount) * (10 / 100));
        } else if (parseInt(factor) > 4) {
            incentive = ((workDetails?.totalAmount) * (10 / 100));
        } else {
            incentive = 0;
        }
        console.log(incentive, factor, parseInt(factor))
        // if()
    }
    

    useEffect(() => {
        incentiveCalculator()
    }, [workDetails])



    return (
        <div className={styles.mainWrapper}>
            <div className={styles.summaryWrapper}>
                Work Details
                <div>
                    <div>
                        {workDetails?.transactions}
                    </div>
                    <div>
                        {parseFloat(workDetails?.totalAmount).toFixed(0)}
                        {/* {stateValue !== 0 && <NumericCounter targetValue={stateValue} />} */}
                    </div>
                </div>
            </div>
            {workDetails!==null &&  <ManageEachStaffWorkBookingData workDetails={workDetails} />}
        </div>
    )
}

export default ManageEachStaffWork