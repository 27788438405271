import React, { useContext, useEffect, useState } from 'react'
import OtpInput from 'react-otp-input'
import { toast, ToastContainer } from 'react-toastify'
import { BASE_URL_API } from '../../References/URLs'
import ActiveStoreContext from '../../store/ActiveStoreContext'
import AuthContext from '../../store/auth-context'
import styles from './AddClients.module.css'
import { Link } from 'react-router-dom'
import { countryCodesHolder } from '../../Helper/countryCodes'
import { sanitizeEmail, sanitizeToDigits } from '../../Helper/calculations'

const AddClients = () => {
    const asCtx = useContext(ActiveStoreContext)
    const authCtx = useContext(AuthContext)
    const activeStore = JSON.parse(asCtx?.activeStore)
    const [otp, setOtp] = useState('');
    const [countryCode, setCountryCode] = useState('+91')
    const [salutation, setSalutation] = useState('')
    const [firstName, setFirstName] = useState('')
    const [middleName, setMiddleName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [address, setAddress] = useState('')
    const [doa, setDoa] = useState('')
    const [dob, setDob] = useState('')
    const [isAddingClient, setIsAddingClient] = useState(false)
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [pincode, setPincode] = useState('')
    const [alternateNumber, setAlternateNumber] = useState('')
    const [whatsAppNumber, setWhatsAppNumber] = useState('')
    const [selectedGender, setSelectedGender] = useState('')
    const [clientStoreRelationIdentifier, setClientStoreRelationIdentifier] = useState('')

    const [ageYear, setAgeYear] = useState('')
    const [ageMonth, setAgeMonth] = useState('')

    const [years, setYears] = useState([]);

    useEffect(() => {
        const yearsArray = [];

        for (let year = 120; year >= 0; year--) {
            yearsArray.push(year);
        }

        setYears(yearsArray);
    }, []);


    const addNewClient = async () => {
        setIsAddingClient(true)
        const addNewClientResponse = await fetch(BASE_URL_API + "addNewClient",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore?.storeIdentifier,
                    countryCode: countryCode,
                    mobile: otp,
                    salutation : salutation,
                    firstName: firstName,
                    middleName: middleName,
                    lastName: lastName,
                    address: address,
                    email: email,
                    doa: doa,
                    dob: dob,
                    city: city,
                    pincode: pincode,
                    state: state,
                    alternateNumber: alternateNumber,
                    whatsAppNumber: whatsAppNumber,
                    selectedGender: selectedGender
                })
            });

        if (!addNewClientResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const addNewClientRespo = await addNewClientResponse.json()
            if (addNewClientRespo.status === "success") {
                toast("Successfully added a client!")
                setOtp('')
                setFirstName('')
                setMiddleName('')
                setLastName('')
                setEmail('')
                setAddress('')
                setDoa('')
                setDob('')
                setCity('')
                setState('')
                setPincode('')
                setClientStoreRelationIdentifier('')
            } else {
                if (addNewClientRespo.message === "tokenExpired") {
                    authCtx.logout()
                } else {
                    if (addNewClientRespo?.message === "user already registered") {
                        setClientStoreRelationIdentifier(addNewClientRespo?.response?.clientStoreRelationIdentifier)
                        toast(addNewClientRespo?.message)
                    } else {
                        toast(addNewClientRespo?.message)
                    }
                }
            }
            console.log(addNewClientRespo)
        }
        setIsAddingClient(false)
    }

    const getPastDate = (year, month) => {
        // Get the current date
        const currentDate = new Date();

        // Create a new Date object for the given year and month, setting the day to 1
        let date = new Date(currentDate.getFullYear() - year, month - 1, 1);

        // If the generated date is in the future, adjust the year backward by 1 year
        if (date > currentDate) {
            date.setFullYear(date.getFullYear() - 1);
        }

        // Format the date as "yyyy-MM-dd"
        const formattedDate = date.toISOString().split('T')[0];

        setDob(formattedDate);
    };



    useEffect(() => {
        getPastDate(ageYear, ageMonth)
    }, [ageYear, ageMonth])

    // Debounce function to limit function triggering frequency
    const debounce = (func, delay) => {
        let timeoutId;
        return (...args) => {
            if (timeoutId) clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    useEffect(() => {
        if(window?.location?.pathname==="/add-clients") {
            const handleKeyDown = debounce((event) => {
                if ((event.ctrlKey || event.metaKey) && event.key === 'Enter') {
                    event.preventDefault();
                    addNewClient();
                }
            }, 300);
            
            document.addEventListener('keydown', handleKeyDown);
            
            return () => {
                document.removeEventListener('keydown', handleKeyDown);
            };
        }
    }, [addNewClient]);

    if (activeStore === null) {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer}>
                    Please select a store
                </div>
            </div>
        )
    }

    return (
        <div className={styles.mainWrapper}>
            <ToastContainer />
            <div className={styles.mainContainer}>
                <div className={styles.mainHeader}>
                    <div>
                        <div className={styles.storeName}>
                            Hi {activeStore?.storeName}
                        </div>
                        <div className={styles.storeAddress}>
                            {activeStore?.storeAddress}
                        </div>
                    </div>
                    <div className={styles.addClientBulkRight}>
                        <Link to={`/clients/bulk-upload`} className={styles.addClientsInBulk} >Add Clients In Bulk</Link>
                        <Link to={`/manage/clients`} className={styles.viewClients}>View Clients</Link>
                    </div>
                </div>

                <div className={styles.customerRegisterationForm}>
                    <div className={styles.crfContainer}>
                        <div className={styles.crfTitle}>
                            Customer Registeration
                        </div>
                        <div className={styles.inputWrappers}>
                            <div className={styles.eachColInputWrapper}>
                                <div className={styles.eachInputWrapper}>
                                    <div className={styles.countryCode}>
                                        <select onChange={(e) => setCountryCode(e.target.value)}
                                            defaultValue="+91"
                                        >
                                            {countryCodesHolder?.map((item, index) => (
                                                <option value={item?.code}>{item?.country} - {item?.code}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className={styles.phoneNumber}>
                                        <OtpInput
                                            numInputs={10}
                                            isInputNum={true}
                                            shouldAutoFocus={true}
                                            value={otp}
                                            onChange={setOtp}
                                        />
                                    </div>
                                </div>
                                {clientStoreRelationIdentifier !== '' &&
                                    <div className={styles.alreadyRegistered}>
                                        Already registered. <Link to={`/user/${clientStoreRelationIdentifier}`}>Do you want to add family member / relatives?</Link>
                                    </div>
                                }

                                <div className={styles.contactNumberWrapper}>
                                    <div className={styles.alternateNumber}>
                                        <input placeholder="Enter alternate phone number" value={alternateNumber} onChange={(e) => setAlternateNumber(sanitizeToDigits(e.target.value))} />
                                    </div>
                                    <div className={styles.whatsAppNumber}>
                                        <input placeholder="Enter whatsApp number" value={whatsAppNumber} onChange={(e) => setWhatsAppNumber(sanitizeToDigits(e.target.value))} />
                                    </div>
                                </div>

                                <div className={styles.nameInputWrapper}>
                                    <div>
                                        <select onChange={(e) => setSalutation(e.target.value)}>
                                            <option>Title</option>
                                            <option>Mr.</option>
                                            <option>Ms.</option>
                                            <option>Mrs.</option>
                                            <option>Baby</option>
                                            <option>Dr.</option>
                                        </select>
                                    </div>
                                    <div className={styles.nameInputEach}>
                                        <input placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                    </div>
                                    {/* <div>
                                    <input placeholder="Middle Name" value={middleName} onChange={(e) => setMiddleName(e.target.value)} />
                                </div> */}
                                    <div className={styles.nameInputEach}>
                                        <input placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                    </div>
                                </div>

                                <div className={styles.emailInputWrapper}>
                                    <input placeholder="E-mail Address" value={email} onChange={(e) => setEmail(sanitizeEmail(e.target.value))} />
                                </div>
                                <div className={styles.genderWrapper} >
                                    <button className={selectedGender === "Female" ? styles.genderBtnActive : styles.genderBtn} onClick={() => setSelectedGender('Female')} >Female</button>
                                    <button className={selectedGender === "Male" ? styles.genderBtnActive : styles.genderBtn} onClick={() => setSelectedGender('Male')} >Male</button>
                                    <button className={selectedGender === "LGBTQ" ? styles.genderBtnActive : styles.genderBtn} onClick={() => setSelectedGender('LGBTQ')} >LGBTQ</button>
                                    <button className={selectedGender === "Other" ? styles.genderBtnActive : styles.genderBtn} onClick={() => setSelectedGender('Other')} >Other</button>
                                </div>
                            </div>

                            <div className={styles.eachColInputWrapper}>
                                <div className={styles.addressInputWrapper}>
                                    <textarea placeholder="Please enter address..." value={address} onChange={(e) => setAddress(e.target.value)} ></textarea>
                                </div>

                                <div className={styles.ageInputWrapper}>
                                    <div className={styles.yearsWrapper}>
                                        <select onChange={(e) => setAgeYear(e.target.value)}>
                                            <option value={null}>Select Age (Year)</option>
                                            {years?.map((item, index) => (
                                                <option key={index} value={item}>{item} years</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className={styles.monthsWrapper}>
                                        <select onChange={(e) => setAgeMonth(e.target.value)}>
                                            <option value={null}>Select Age (Month)</option>
                                            <option value={0} >0 Month</option>
                                            <option value={1} >1 Month</option>
                                            <option value={2} >2 Months</option>
                                            <option value={3} >3 Months</option>
                                            <option value={4} >4 Months</option>
                                            <option value={5} >5 Months</option>
                                            <option value={6} >6 Months</option>
                                            <option value={7} >7 Months</option>
                                            <option value={8} >8 Months</option>
                                            <option value={9} >9 Months</option>
                                            <option value={10} >10 Months</option>
                                            <option value={11} >11 Months</option>
                                            <option value={12} >12 Months</option>
                                        </select>
                                    </div>
                                </div>

                                <div className={styles.dateInputWrapper}>
                                    <div className={styles.dobWrapper}>
                                        <input placeholder="DOB" type="date" value={dob} onChange={(e) => setDob(e.target.value)} />
                                    </div>
                                    <div className={styles.dobWrapper}>
                                        <input placeholder="DOA" type="date" value={doa} onChange={(e) => setDoa(e.target.value)} />
                                    </div>
                                </div>

                                {/* <div className={styles.cityStateWrapper}>
                                <div>
                                    <input placeholder="City" onChange={(e) => setCity(e.target.value)} value={city} />
                                </div>
                                <div>
                                    <input placeholder="Pincode" onChange={(e) => setPincode(e.target.value)} value={pincode} />
                                </div>
                                <div>
                                    <input placeholder="State" onChange={(e) => setState(e.target.value)} value={state} />
                                </div>
                            </div> */}
                                <div className={styles.registerWrapper}>
                                    {isAddingClient === true ?
                                        <button>Please wait, registering...</button>
                                        :
                                        <button onClick={() => addNewClient()}>Register</button>
                                    }
                                </div>
                            </div>



                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default AddClients