import React, { useContext, useEffect, useRef, useState } from 'react'
import styles from './ManageEachStaffProfile.module.css'
import { BsImage } from "react-icons/bs"
import { useParams } from 'react-router'
import { BASE_URL_API } from '../../References/URLs'
import AuthContext from '../../store/auth-context'
import ActiveStoreContext from '../../store/ActiveStoreContext'
import { toast } from 'react-toastify'
import { indianBankNameList } from '../../Helper/indianBankNameList'
import { motion } from 'framer-motion'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'

const jobProfiles = [
    {
        "categoryId": "1",
        "label": "Beautician"
    },
    {
        "categoryId": "15",
        "label": "Beauty Advisor / Consultant"
    },
    {
        "categoryId": "13",
        "label": "Beauty Trainer"
    },
    {
        "categoryId": "22",
        "label": "Eye-lash Extension Expert"
    },
    {
        "categoryId": "19",
        "label": "Eyelash Trainer"
    },
    {
        "categoryId": "20",
        "label": "Female Hairdresser / Hairstylist"
    },
    {
        "categoryId": "17",
        "label": "Gents Hairdresser / Hairstylist"
    },
    {
        "categoryId": "14",
        "label": "Hair Trainer"
    },
    {
        "categoryId": "9",
        "label": "Helper"
    },
    {
        "categoryId": "12",
        "label": "Make-up Trainer"
    },
    {
        "categoryId": "2",
        "label": "Makeup Artist"
    },
    {
        "categoryId": "11",
        "label": "Manager"
    },
    {
        "categoryId": "4",
        "label": "Manicurist"
    },
    {
        "categoryId": "16",
        "label": "Massage Therapist"
    },
    {
        "categoryId": "21",
        "label": "Mehandi Artist"
    },
    {
        "categoryId": "6",
        "label": "Nail Art / Technician"
    },
    {
        "categoryId": "18",
        "label": "Nail Art Trainer"
    },
    {
        "categoryId": "5",
        "label": "Pedicurist"
    },
    {
        "categoryId": "10",
        "label": "Receptionist"
    },
    {
        "categoryId": "3",
        "label": "Unisex Hairdresser / Hairstylist"
    }
]

const ManageEachStaffProfile = () => {
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext);
    const activeStore = (() => { try { return asCtx?.activeStore && asCtx.activeStore.trim() !== "" ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();
    const [isUpdating, setIsUpdating] = useState(false)

    const { staffIdentifier } = useParams()
    const [profileDetails, setProfileDetails] = useState(null)

    const getStaffProfileDetails = async () => {
        const getStaffProfileDetailsResponse = await fetch(BASE_URL_API + "getStaffProfileDetails",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore.storeIdentifier,
                    staffIdentifier: staffIdentifier
                })
            });

        if (!getStaffProfileDetailsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getStaffProfileDetailsRespo = await getStaffProfileDetailsResponse.json()
            if (getStaffProfileDetailsRespo.status === 'success') {
                setProfileDetails(getStaffProfileDetailsRespo?.response)
            } else {
                if (getStaffProfileDetailsRespo.message === "tokenExpired") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(getStaffProfileDetailsRespo)
        }
    }

    useEffect(() => {
        getStaffProfileDetails()
    }, [staffIdentifier])

    const updateStaffProfileDetails = async () => {
        setIsUpdating(true)
        const updateStaffProfileDetailsResponse = await fetch(BASE_URL_API + "updateStaffProfileDetails",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore.storeIdentifier,
                    staffIdentifier: staffIdentifier,
                    profileDetails: profileDetails
                })
            });

        if (!updateStaffProfileDetailsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const updateStaffProfileDetailsRespo = await updateStaffProfileDetailsResponse.json()
            if (updateStaffProfileDetailsRespo.status === 'success') {
                toast(`Profile details successfully udpated!`)
            } else {
                if (updateStaffProfileDetailsRespo.message === "tokenExpired") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(updateStaffProfileDetailsRespo)
        }
        setIsUpdating(false)
    }

    const updateDetailsHandler = (f, v) => {
        setProfileDetails(prevState => ({
            ...prevState,
            [f]: v
        }));
    };


    const [searchTerm, setSearchTerm] = useState(profileDetails?.bankName || "");
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [highlightIndex, setHighlightIndex] = useState(-1);
    const suggestionsRef = useRef(null)

    // Filter bank names based on user input
    const filteredBanks = indianBankNameList.filter(bank =>
        bank.bankName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    useEffect(() => {
        // Scroll to highlighted item
        if (suggestionsRef.current && highlightIndex >= 0) {
            const activeItem = suggestionsRef.current.children[highlightIndex];
            if (activeItem) {
                activeItem.scrollIntoView({ block: "nearest" });
            }
        }
    }, [highlightIndex]);

    const handleKeyDown = (e) => {
        if (e.key === "ArrowDown") {
            setHighlightIndex((prev) => Math.min(prev + 1, filteredBanks.length - 1));
        } else if (e.key === "ArrowUp") {
            setHighlightIndex((prev) => Math.max(prev - 1, 0));
        } else if (e.key === "Enter") {
            if (highlightIndex >= 0) {
                handleSelect(filteredBanks[highlightIndex]);
            }
        }
    };

    const handleSelect = (bank) => {
        updateDetailsHandler("bankName", bank.bankName);
        setSearchTerm(bank.bankName);
        setIsDropdownOpen(false);
        setHighlightIndex(-1);
    };

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.mainBody}>
                    <div className={styles.leftColWrapper}>
                        <div className={styles.profilePicWrapper}>
                            <div className={styles.sectionTitle}>Profile Pic</div>
                            <div className={styles.profilePicContainer}>
                                <img src="https://thesalonbusiness.com/wp-content/uploads/2020/03/hairdresser-profile-picture.png" />
                            </div>
                            <div className={styles.profileActionBtn}>
                                <BsImage size={16} />
                                Change Profile Pic
                            </div>
                        </div>

                        <div className={styles.employeeDetails} >
                            <div className={styles.sectionTitle}>Employee Details</div>
                            <div className={styles.inputFieldsWrapper}>
                                <div className={styles.eachInputSection}>
                                    <div className={styles.inputTitle} >Full Name</div>
                                    <div className={styles.inputField}>
                                        <input value={profileDetails?.name} />
                                    </div>
                                </div>
                                <div className={styles.eachInputSection}>
                                    <div className={styles.inputTitle} >Phone Number</div>
                                    <div className={styles.inputField}>
                                        <input value={profileDetails?.mobile} onChange={(e) => updateDetailsHandler('mobile', e.target.value)} />
                                    </div>
                                </div>
                                <div className={styles.eachInputSection}>
                                    <div className={styles.inputTitle} >E-mail</div>
                                    <div className={styles.inputField}>
                                        <input value={profileDetails?.email} onChange={(e) => updateDetailsHandler('email', e.target.value)} />
                                    </div>
                                </div>
                                <div className={styles.eachInputSection}>
                                    <div className={styles.inputTitle} >Gender</div>
                                    <div className={styles.inputField}>
                                        <select
                                            onChange={(e) => updateDetailsHandler('gender', e.target.value)}
                                            value={profileDetails?.gender ? String(profileDetails.gender) : "0"}
                                        >
                                            <option value="0">Select Gender</option>
                                            <option value="2">Female</option>
                                            <option value="1">Male</option>
                                            <option value="3">Others</option>
                                        </select>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div className={styles.centerColWrapper}>
                        <div className={styles.addressWrapper}>
                            <div className={styles.sectionTitle}>Description</div>
                            <div className={styles.inputFieldsWrapper}>
                                <div className={styles.eachInputSection}>
                                    <div className={styles.inputTitle} >Profile Description</div>
                                    <div className={styles.inputField}>
                                        <textarea defaultValue={profileDetails?.description} onChange={(e) => updateDetailsHandler('description', e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.addressWrapper}>
                            <div className={styles.sectionTitle}>Address</div>
                            <div className={styles.inputFieldsWrapper}>
                                <div className={styles.eachInputSection}>
                                    <div className={styles.inputTitle} >Current Address</div>
                                    <div className={styles.inputField}>
                                        <textarea defaultValue={profileDetails?.currentAddress} onChange={(e) => updateDetailsHandler('currentAddress', e.target.value)} />
                                    </div>
                                </div>
                                <div className={styles.eachInputSection}>
                                    <div className={styles.inputTitle} >Permanent Address</div>
                                    <div className={styles.inputField}>
                                        <textarea defaultValue={profileDetails?.permanentAddress} onChange={(e) => updateDetailsHandler('permanentAddress', e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className={styles.rightColWrapper}>

                        <div className={styles.rolesWrapper}>
                            <div className={styles.sectionTitle}>Joining</div>
                            <div className={styles.inputFieldsWrapper}>
                                <div className={styles.eachInputSection}>
                                    <div className={styles.inputTitle} >Joined On</div>
                                    <div className={styles.inputField}>
                                        <input
                                            type="date"
                                            value={profileDetails?.joiningDate?.split(" ")[0] || ""} // Extracting only YYYY-MM-DD
                                            onChange={(e) => {
                                                const newDate = e.target.value + " 10:00:01"; // Append time to maintain format
                                                updateDetailsHandler('joiningDate', newDate);
                                            }}
                                        />
                                    </div>

                                </div>
                                <div className={styles.eachInputSection}>
                                    <div className={styles.inputTitle}>Designation</div>
                                    <div className={styles.inputField}>
                                        <select
                                            onChange={(e) => {
                                                const selectedCategoryId = e.target.value;
                                                const selectedItem = jobProfiles?.find(item => item.categoryId === selectedCategoryId);

                                                setProfileDetails(prevState => ({
                                                    ...prevState,
                                                    designationIdentifier: selectedItem?.categoryId || "",
                                                    designationLabel: selectedItem?.label || ""
                                                }));
                                            }}
                                            value={profileDetails?.designationIdentifier || ""}
                                        >
                                            <option value="">Select Designation</option>
                                            {jobProfiles?.map((item, index) => (
                                                <option key={item?.categoryId || index} value={item.categoryId}>
                                                    {item?.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>



                            </div>
                        </div>

                        <div className={styles.rolesWrapper}>
                            <div className={styles.sectionTitle}>Salary</div>
                            <div className={styles.inputFieldsWrapper}>
                                <div className={styles.eachInputSection}>
                                    <div className={styles.inputTitle} >Current Monthly Salary</div>
                                    <div className={styles.inputField}>
                                        <input value={profileDetails?.currentMonthlySalary} onChange={(e) => updateDetailsHandler('currentMonthlySalary', e.target.value)} />
                                    </div>
                                </div>
                            </div>

                            <div className={styles.sectionTitle}>Payment Details</div>
                            <div className={styles.inputFieldsWrapper}>
                                <div className={styles.eachInputSection}>
                                    <div className={styles.inputTitle} >UPI ID</div>
                                    <div className={styles.inputField}>
                                        <input value={profileDetails?.upiId} onChange={(e) => updateDetailsHandler('upiId', e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.sectionTitle}>Bank Details</div>
                            <div className={styles.inputFieldsWrapper}>
                                <div className={styles.eachInputSection}>
                                    <div className={styles.inputTitle} >BANK Name</div>
                                    <div className={styles.inputField} onKeyDown={handleKeyDown}>
                                        <input
                                            type="text"
                                            value={searchTerm || profileDetails?.bankName}
                                            onChange={(e) => {
                                                setSearchTerm(e.target.value);
                                                setIsDropdownOpen(true);
                                                setHighlightIndex(-1);
                                            }}
                                            onFocus={() => setIsDropdownOpen(true)}
                                            onBlur={() => setTimeout(() => setIsDropdownOpen(false), 200)} // Delay to allow click selection
                                            placeholder="Search and select a bank"
                                            className={styles.inputBox}
                                        />

                                        {/* Suggestion List */}
                                        {isDropdownOpen && filteredBanks.length > 0 && (
                                            <ul ref={suggestionsRef} className={styles.suggestionList}>
                                                {filteredBanks.map((item, index) => (
                                                    <li
                                                        key={index}
                                                        className={highlightIndex === index ? styles.highlighted : ""}
                                                        onMouseDown={() => handleSelect(item)}
                                                        onMouseEnter={() => setHighlightIndex(index)}
                                                    >
                                                        {item.bankName}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>

                                </div>
                                <div className={styles.eachInputSection}>
                                    <div className={styles.inputTitle} >Account Number</div>
                                    <div className={styles.inputField}>
                                        <input value={profileDetails?.bankAccountNumber} onChange={(e) => updateDetailsHandler('bankAccountNumber', e.target.value)} />
                                    </div>
                                </div>
                                <div className={styles.eachInputSection}>
                                    <div className={styles.inputTitle} >IFSC</div>
                                    <div className={styles.inputField}>
                                        <input value={profileDetails?.ifsc} onChange={(e) => updateDetailsHandler('ifsc', e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.mainFooter}>
                    <motion.div
                        className={styles.buttonContainer}
                        initial={{ opacity: 0, scale: 0.8 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.3 }}
                    >
                        {isUpdating ? (
                            <motion.button
                                className={styles.saveChangesBtn}
                                disabled
                                whileTap={{ scale: 0.95 }}
                            >
                                <motion.div
                                    animate={{ rotate: 360 }}
                                    transition={{ repeat: Infinity, duration: 0.3, ease: "linear" }}
                                    className={styles.spinnerWrapper}
                                >
                                    <AiOutlineLoading3Quarters className={styles.spinnerIcon} />
                                </motion.div>
                                Saving Changes...
                            </motion.button>
                        ) : (
                            <motion.button
                                className={styles.saveChangesBtn}
                                onClick={updateStaffProfileDetails}
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                Save Changes
                            </motion.button>
                        )}
                    </motion.div>
                </div>
            </div>
        </div>
    )
}

export default ManageEachStaffProfile