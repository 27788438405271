import React, { useMemo, useState } from "react";
import { Bar, Pie, Line, Scatter, Doughnut, Bubble } from "react-chartjs-2";
import { motion } from "framer-motion";
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    TimeScale,
} from "chart.js";
import styles from './MembershipAnalytics.module.css';
import PropTypes from 'prop-types';
import { FaClock, FaPercentage, FaRegFrown, FaRegSmile, FaRupeeSign, FaUsers } from "react-icons/fa";
import { GrUpgrade } from "react-icons/gr";


ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, PointElement, LineElement, TimeScale);

const MembershipAnalytics = ({ data }) => {
    const [filter, setFilter] = useState("all"); // Dynamic filtering

    const analytics = useMemo(() => {
        if (!data || !Array.isArray(data)) return {};

        const totalMemberships = data.length;
        const totalRevenue = data.reduce((sum, item) => sum + parseFloat(item.amountPaid || 0), 0);

        const activeMemberships = data.filter((item) => item.isActive === "1").length;
        const inactiveMemberships = totalMemberships - activeMemberships;
        const expiredMemberships = data.filter((item) => new Date(item.expiryDate) < new Date()).length;

        const paymentModeCounts = data.reduce((acc, item) => {
            const mode = item.payMode || "Unknown";
            acc[mode] = (acc[mode] || 0) + 1;
            return acc;
        }, {});

        const cardTypeCounts = data.reduce((acc, item) => {
            const type = item.cardType || "Unknown";
            acc[type] = (acc[type] || 0) + 1;
            return acc;
        }, {});

        const cardLabelRevenue = data.reduce((acc, item) => {
            const label = item.cardLabel || "Unknown";
            acc[label] = (acc[label] || 0) + parseFloat(item.amountPaid || 0);
            return acc;
        }, {});

        const monthlySales = data.reduce((acc, item) => {
            const monthYear = new Date(item.issuedOn).toLocaleDateString('en-US', { year: 'numeric', month: 'short' });
            acc[monthYear] = (acc[monthYear] || 0) + parseFloat(item.amountPaid || 0);
            return acc;
        }, {});

        const mostFrequentCustomers = Object.entries(data.reduce((acc, item) => {
            const customer = item.clientName || "Unknown";
            acc[customer] = (acc[customer] || 0) + 1;
            return acc;
        }, {})).sort(([, a], [, b]) => b - a).slice(0, 5);

        const churnRate = ((expiredMemberships / totalMemberships) * 100).toFixed(2);
        const retentionRate = (100 - churnRate).toFixed(2);

        return {
            totalMemberships,
            totalRevenue,
            activeMemberships,
            inactiveMemberships,
            expiredMemberships,
            paymentModeCounts,
            cardTypeCounts,
            cardLabelRevenue,
            monthlySales,
            mostFrequentCustomers,
            churnRate,
            retentionRate,
        };
    }, [data]);

    const pieData = {
        labels: ["Active", "Inactive", "Expired"],
        datasets: [
            {
                label: "Membership Status",
                data: [analytics.activeMemberships, analytics.inactiveMemberships, analytics.expiredMemberships],
                backgroundColor: ["#4caf50", "#ff9800", "#f44336"],
                hoverOffset: 4,
            },
        ],
    };

    const barData = {
        labels: Object.keys(analytics.paymentModeCounts || {}),
        datasets: [
            {
                label: "Payment Modes",
                data: Object.values(analytics.paymentModeCounts || {}),
                backgroundColor: ["#2196f3", "#e91e63", "#ffc107", "#9c27b0"],
            },
        ],
    };

    const lineData = {
        labels: Object.keys(analytics.monthlySales || {}),
        datasets: [
            {
                label: "Monthly Revenue",
                data: Object.values(analytics.monthlySales || {}),
                borderColor: "#3f51b5",
                backgroundColor: "rgba(63, 81, 181, 0.3)",
                fill: true,
            },
        ],
    };

    const scatterData = {
        datasets: [
            {
                label: "Revenue by Card Label",
                data: Object.entries(analytics.cardLabelRevenue || {}).map(([label, revenue]) => ({ x: label, y: revenue })),
                backgroundColor: "#3f51b5",
            },
        ],
    };

    const doughnutData = {
        labels: Object.keys(analytics.cardTypeCounts || {}),
        datasets: [
            {
                label: "Card Type Distribution",
                data: Object.values(analytics.cardTypeCounts || {}),
                backgroundColor: ["#2196f3", "#e91e63", "#ffc107", "#9c27b0", "#00acc1"],
            },
        ],
    };

    // const benchmarks = {
    //     revenue: 500000,      // Example benchmark revenue
    //     memberships: 1000     // Example benchmark memberships
    // };


    // const Benchmarking = ({ data, benchmarks }) => {
    //     const comparisonData = useMemo(() => {
    //         if (!data || !benchmarks) return null;

    //         const totalRevenue = data.reduce((sum, item) => sum + parseFloat(item.amountPaid || 0), 0);
    //         const totalMemberships = data.length;

    //         const revenueDeviation = ((totalRevenue - benchmarks.revenue) / benchmarks.revenue) * 100;
    //         const membershipDeviation = ((totalMemberships - benchmarks.memberships) / benchmarks.memberships) * 100;

    //         const revenueStatus = revenueDeviation > 10 ? "Below Benchmark" : revenueDeviation < -10 ? "Above Benchmark" : "Within Range";
    //         const membershipStatus = membershipDeviation > 10 ? "Below Benchmark" : membershipDeviation < -10 ? "Above Benchmark" : "Within Range";

    //         return {
    //             revenueDeviation: revenueDeviation.toFixed(2),
    //             membershipDeviation: membershipDeviation.toFixed(2),
    //             revenueStatus,
    //             membershipStatus
    //         };
    //     }, [data, benchmarks]);

    //     return (
    //         <motion.div className={styles.benchmarkingWrapper}>
    //             <h3>Benchmarking</h3>
    //             {comparisonData ? (
    //                 <div>
    //                     <div className={styles.benchmarkSection}>
    //                         <h4>Revenue Analysis</h4>
    //                         <p><strong>Total Revenue:</strong> ₹{data.reduce((sum, item) => sum + parseFloat(item.amountPaid || 0), 0).toFixed(2)}</p>
    //                         <p><strong>Revenue Deviation:</strong> {comparisonData.revenueDeviation}%</p>
    //                         <p><strong>Status:</strong> <span className={comparisonData.revenueStatus === "Below Benchmark" ? styles.alert : styles.normal}>{comparisonData.revenueStatus}</span></p>
    //                     </div>

    //                     <div className={styles.benchmarkSection}>
    //                         <h4>Membership Analysis</h4>
    //                         <p><strong>Total Memberships:</strong> {data.length}</p>
    //                         <p><strong>Membership Deviation:</strong> {comparisonData.membershipDeviation}%</p>
    //                         <p><strong>Status:</strong> <span className={comparisonData.membershipStatus === "Below Benchmark" ? styles.alert : styles.normal}>{comparisonData.membershipStatus}</span></p>
    //                     </div>

    //                     {comparisonData.revenueDeviation > 10 && (
    //                         <p className={styles.alertMessage}>
    //                             <strong>Warning:</strong> Revenue is significantly below the benchmark. Consider analyzing customer preferences or running promotions.
    //                         </p>
    //                     )}
    //                     {comparisonData.membershipDeviation > 10 && (
    //                         <p className={styles.alertMessage}>
    //                             <strong>Warning:</strong> Membership count is below the benchmark. Investigate customer retention strategies.
    //                         </p>
    //                     )}
    //                 </div>
    //             ) : (
    //                 <p>No data available for benchmarking.</p>
    //             )}
    //         </motion.div>
    //     );
    // };


    const Benchmarking = ({ data, benchmarks, performanceRanges }) => {
        const comparisonData = useMemo(() => {
            if (!data || !benchmarks) return null;

            const totalRevenue = data.reduce((sum, item) => sum + parseFloat(item.amountPaid || 0), 0);
            const totalMemberships = data.length;

            const revenueDeviation = ((totalRevenue - benchmarks.revenue) / benchmarks.revenue) * 100;
            const membershipDeviation = ((totalMemberships - benchmarks.memberships) / benchmarks.memberships) * 100;

            const getPerformanceStatus = (deviation, ranges) => {
                for (const [range, status] of Object.entries(ranges)) {
                    const [lowerBound, upperBound] = range.split('-').map(Number);
                    if (deviation >= lowerBound && deviation <= upperBound) {
                        return status;
                    }
                }
                return 'Unknown';
            };

            return {
                revenue: {
                    value: totalRevenue.toFixed(2),
                    deviation: revenueDeviation.toFixed(2),
                    status: getPerformanceStatus(revenueDeviation, performanceRanges.revenue),
                },
                memberships: {
                    value: totalMemberships,
                    deviation: membershipDeviation.toFixed(2),
                    status: getPerformanceStatus(membershipDeviation, performanceRanges.memberships),
                },
            };
        }, [data, benchmarks, performanceRanges]);

        return (
            <div className={styles.benchmarkingWrapper}>
                <h3>Benchmarking</h3>
                {comparisonData ? (
                    <div>
                        <div className={styles.benchmarkSection}>
                            <h4>Revenue Analysis</h4>
                            <p><strong>Total Revenue:</strong> ₹{comparisonData.revenue.value}</p>
                            <p><strong>Deviation:</strong> {comparisonData.revenue.deviation}%</p>
                            <p><strong>Status:</strong> <span className={styles[comparisonData.revenue.status]}>{comparisonData.revenue.status}</span></p>
                        </div>

                        <div className={styles.benchmarkSection}>
                            <h4>Membership Analysis</h4>
                            <p><strong>Total Memberships:</strong> {comparisonData.memberships.value}</p>
                            <p><strong>Deviation:</strong> {comparisonData.memberships.deviation}%</p>
                            <p><strong>Status:</strong> <span className={styles[comparisonData.memberships.status]}>{comparisonData.memberships.status}</span></p>
                        </div>
                    </div>
                ) : (
                    <p>No data available for benchmarking.</p>
                )}
            </div>
        );
    };

    Benchmarking.propTypes = {
        data: PropTypes.array.isRequired,
        benchmarks: PropTypes.shape({
            revenue: PropTypes.number.isRequired,
            memberships: PropTypes.number.isRequired,
        }).isRequired,
        performanceRanges: PropTypes.shape({
            revenue: PropTypes.object.isRequired, // Example: { '-10-10': 'Within Range', '-20--10': 'Below Average', ... }
            memberships: PropTypes.object.isRequired, // Example: { '-10-10': 'Within Range', '-20--10': 'Below Average', ... }
        }).isRequired,
    };




    const BubbleChart = ({ data }) => {
        const bubbleData = useMemo(() => {
            if (!data) return null;

            return {
                datasets: [
                    {
                        label: "Revenue vs Memberships",
                        data: data.map(item => ({
                            x: parseFloat(item.amountPaid || 0),
                            y: parseFloat(item.isActive === "1" ? 1 : 0),
                            r: Math.sqrt(item.amountPaid || 0) * 2,
                        })),
                        backgroundColor: "rgba(75, 192, 192, 0.6)",
                        borderColor: "rgba(75, 192, 192, 1)",
                    },
                ],
            };
        }, [data]);

        return (
            <motion.div className={styles.bubbleChartWrapper}>
                <h3>Bubble Chart: Revenue vs Memberships</h3>
                {bubbleData ? <Bubble data={bubbleData} options={{ responsive: true, plugins: { tooltip: { callbacks: { label: ({ raw }) => `Revenue: ${raw.x}, Membership: ${raw.y}` } } } }} /> : <p>No data available for bubble chart.</p>}
            </motion.div>
        );
    };

    const PredictiveAnalysis = ({ data }) => {
        const predictions = useMemo(() => {
            if (!data) return null;

            const monthlySales = data.reduce((acc, item) => {
                const monthYear = new Date(item.issuedOn).toLocaleDateString('en-US', { year: 'numeric', month: 'short' });
                acc[monthYear] = (acc[monthYear] || 0) + parseFloat(item.amountPaid || 0);
                return acc;
            }, {});

            const months = Object.keys(monthlySales).sort();
            const sales = months.map(month => monthlySales[month]);

            const nextMonthPrediction = sales.reduce((sum, value, index) => (index ? sum + value : sum), 0) / sales.length;
            const membershipGrowth = data.length > 0 ? Math.ceil(data.length / months.length) : 0;

            return {
                nextMonthRevenue: nextMonthPrediction.toFixed(2),
                predictedMembershipGrowth: membershipGrowth,
            };
        }, [data]);

        return (
            <motion.div className={styles.predictiveWrapper}>
                {predictions ? (
                    <div className={styles.predictiveContainer}>
                        <motion.div
                            className={styles.eachSummaryChip}
                            whileHover={{
                                scale: 1.1,
                                backgroundColor: "#f4f4f4",
                                boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.15)"
                            }}
                            transition={{ type: "spring", stiffness: 300 }}
                        >
                            <div className={styles.iconWrapper}>
                                <FaRupeeSign />
                            </div>
                            <div>
                                <strong>Next Month Revenue Prediction:</strong>
                                <div>
                                    ₹{predictions?.nextMonthRevenue}
                                </div>
                            </div>
                        </motion.div>
                        <motion.div
                            className={styles.eachSummaryChip}
                            whileHover={{
                                scale: 1.1,
                                backgroundColor: "#f4f4f4",
                                boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.15)"
                            }}
                            transition={{ type: "spring", stiffness: 300 }}
                        >
                            <div className={styles.iconWrapper}>
                                <GrUpgrade color="#007bff" />
                            </div>
                            <div>
                                <strong>Predicted Membership Growth:</strong>
                                <div>
                                    ₹{predictions?.predictedMembershipGrowth}
                                </div>
                            </div>
                        </motion.div>
                    </div>
                ) : (
                    <p>No data available for predictive analysis.</p>
                )}
            </motion.div>
        );
    };


    return (
        <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className={styles.mainWrapper}
        >
            <div>
                <motion.div
                    initial={{ scale: 0.9 }}
                    animate={{ scale: 1 }}
                    transition={{ duration: 0.5 }}
                    className={styles.title}
                >
                    Membership Analytics
                </motion.div>
            </div>
            <div className={styles.mainContent}>
                <div className={styles.firstRowWrapper}>
                    <motion.div className={styles.summaryWrapper}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6 }}
                    >
                        <div className={styles.chartTitle}>Membership Status</div>
                        <div className={styles.summaryContainer}>
                            {[
                                { label: "Total Memberships", value: analytics.totalMemberships, icon: <FaUsers /> },
                                { label: "Total Revenue", value: `₹${analytics.totalRevenue.toFixed(2)}`, icon: <FaRupeeSign /> },
                                // { label: "Active Memberships", value: analytics.activeMemberships, icon: <FaRegSmile /> },
                                // { label: "Inactive Memberships", value: analytics.inactiveMemberships, icon: <FaRegFrown /> },
                                { label: "Active Memberships", value: analytics.inactiveMemberships, icon: <FaRegSmile /> },
                                { label: "Expired Memberships", value: analytics.expiredMemberships, icon: <FaClock /> },
                                { label: "Retention Rate", value: `${analytics.retentionRate}%`, icon: <FaPercentage /> },
                                { label: "Churn Rate", value: `${analytics.churnRate}%`, icon: <FaPercentage /> },
                            ].map((item, index) => (
                                <motion.div
                                    key={index}
                                    className={styles.eachSummaryChip}
                                    whileHover={{
                                        scale: 1.1,
                                        backgroundColor: "#f4f4f4",
                                        boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.15)"
                                    }}
                                    transition={{ type: "spring", stiffness: 300 }}
                                >
                                    <div className={styles.iconWrapper}>
                                        {item?.icon}
                                    </div>
                                    <div>
                                        <strong>{item.label}:</strong> {item.value}
                                    </div>
                                </motion.div>
                            ))}
                        </div>
                    </motion.div>

                    <div className={styles.membershipCurrentStatusWrapper}>
                        <div className={styles.chartTitle} >Membership Status</div>
                        <div className={styles.mcswWrapper} >
                            <Pie data={pieData} />
                        </div>
                    </div>
                    <div className={styles.predictiveAnlaysisWrapper}>
                        <div className={styles.chartTitle} >Predictive Analysis</div>
                        <PredictiveAnalysis data={data} />
                    </div>
                </div>

                <motion.div className={styles.chartWrapper}>
                    <div className={styles.chartContainer}>
                        <div className={styles.chartTitle} >Payment Modes</div>
                        <div className={styles.chartContent}>
                            <Bar data={barData} options={{ responsive: true }} />
                        </div>
                    </div>
                    <div className={styles.chartContainer}>
                        <div className={styles.chartTitle} >Monthly Revenue Trends</div>
                        <div className={styles.chartContent}>
                            <Line data={lineData} options={{ responsive: true }} />
                        </div>
                    </div>
                    <div className={styles.chartContainer}>
                        <div className={styles.chartTitle} >Revenue by Card Label</div>
                        <div className={styles.chartContent}>
                            <Scatter data={scatterData} options={{ responsive: true, scales: { x: { title: { display: true, text: 'Card Label' } }, y: { title: { display: true, text: 'Revenue' } } } }} />
                        </div>
                    </div>
                </motion.div>

                <div className={styles.twoInARow}>
                    <motion.div className={styles.doughnutWrapper}>
                        <div className={styles.chartContainerDn}>
                            <div className={styles.chartTitle} >Card Type Distribution</div>
                            <Doughnut data={doughnutData} />
                        </div>
                    </motion.div>
                    <div className={styles.bubbleWrapper}>
                        <div className={styles.chartTitle} >Revenue Vs Membership</div>
                        <BubbleChart data={data} />
                    </div>
                </div>

                {/* <Benchmarking data={data} benchmarks={benchmarks} /> */}
                <Benchmarking
                    data={data}
                    benchmarks={{ revenue: 550000, memberships: 1050 }}
                    performanceRanges={{
                        revenue: {
                            '-10-10': 'Within Range',
                            '-20--10': 'Below Average',
                            '-Infinity:-20': 'Poor',
                            '10-20': 'Above Average',
                            '20-Infinity': 'Excellent',
                        },
                        memberships: {
                            '-10-10': 'Within Range',
                            '-20--10': 'Below Average',
                            '-Infinity:-20': 'Poor',
                            '10-20': 'Above Average',
                            '20-Infinity': 'Excellent',
                        },
                    }}
                />


            </div>
        </motion.div>
    );
};

export default MembershipAnalytics;
